import React, { useEffect } from 'react';
import './AuthenticateContainer.css';
import { useNavigate } from 'react-router-dom';
import { getAccessToken, getRefreshToken } from './auth';

const AuthenticateContainer = ({ children }) => {

    const navigate = useNavigate();
    // If already login cannot go to page that havent login
    useEffect(() => {
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();

        if (accessToken || refreshToken) {
            navigate('/');
        } 
    }, [navigate]);

    const logo_url = global.config.base_url + "logo/astramina_logo.png"
    return (
        <div className="auth-main-container d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center auth-logo-container">
                <img src={logo_url} alt="Icon" className="auth-logo" />
                <span className="logo-label">Seasonings Specialities</span>
            </div>
                
            <div className="auth-card">
                {children}
            </div>
        </div>
    );
};

export default AuthenticateContainer;
