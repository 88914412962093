import { Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, IconButton, Box, Menu } from '@mui/material'
import React, { useState } from 'react'
import * as CustomStyle from '../../components/CustomStyle'
import { DeleteOutline, EditOutlined, ExpandMore, MoreVert } from '@mui/icons-material'
import { blue, grey, red } from '@mui/material/colors'
import IOSSwitch from '../../components/IOSSwitch'
import WarningModal from '../../components/WarningModal';
import DeleteModal from '../../components/DeleteModal';
import axiosInstance from '../../components/auth/auth'
import ErrorModal from '../../components/ErrorModal'

const NotificationConfig = ({isDesktop, adminViewCallback, alertsConfig, setAlertsConfig, selectedAlertConfigCallback}) => {
    const [openWarning, setOpenWarning] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteID, setDeleteID] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const handleMenu = (event, alert) => {
        setAnchorEl(event.currentTarget);
        selectedAlertConfigCallback(alert)
        setDeleteID(alert.id)
      };

    const deleteOpen = (id) => {
        setOpenDelete(true);
        setDeleteID(id);
    }

    const deleteAlert = async () => {
        const apiUrl = `alert/api/update/${deleteID}/`;
        try{
            const response =  await axiosInstance.delete(global.config.server_url + apiUrl);
            setAlertsConfig(alertsConfig.filter((alert) => alert.id !== deleteID));
        }catch(error){
            setOpenError(true);
            console.log('Error deleting data', error);
        }finally{
            setOpenDelete(false);
            setDeleteID(false);
            setAnchorEl(null);
        }
    }

    const editRule = async (alert) => {
        const apiUrl = `alert/api/update/${alert.id}/`;
        const data = {'is_enable': !alert.is_enable}
        try{
            const response =  await axiosInstance.patch(global.config.server_url + apiUrl, data);
            alert.is_enable = !alert.is_enable; 
            setAlertsConfig([...alertsConfig]);
        }catch(error){
            setOpenError(true);
            console.log('Error fetching data', error);

        }
        
    }

    const formatAlertType = (alert) => {
        const { alert_type, trigger_by } = alert;

        return `${alert_type.name} - ${trigger_by.name}`;
    }
    

  return (
    <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell  sx={{...CustomStyle.tableHeader}}>{isDesktop? "Type of Alert Notifcation" : "Type"}</TableCell> 
                    <TableCell  sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>{isDesktop? "Level of Alert" : "Level"}</TableCell> 
                    <TableCell  sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>{isDesktop? "Enable Rule Upon Creation" : "Enable Rule"}</TableCell> 
                    <TableCell  sx={{...CustomStyle.tableHeader}}></TableCell> 
                </TableRow>
            </TableHead>
            <TableBody>
                {alertsConfig.map((alert) => (
                    <TableRow>
                        <TableCell sx={{...CustomStyle.tableText, color: grey[600], width: {xs: '20.5rem', lg: 'auto'}}}>
                            {formatAlertType(alert)}
                        </TableCell>
                        <TableCell sx={{...CustomStyle.tableCenteredText, color: grey[600]}}>{(alert.alert_level.name)}</TableCell>
                        <TableCell sx={{}}>
                            <Box sx={{...CustomStyle.rowCentered}}>
                                <IOSSwitch checked={alert.is_enable} onChange={()=>editRule(alert)} sx={{...CustomStyle.iosSwitchTable}}/>    
                            </Box>
                        </TableCell>
                        {isDesktop?
                            <TableCell sx={{...CustomStyle.tableText}}>
                            <Box sx={{...CustomStyle.row, justifyContent:'space-between', width: 0.7}}>
                                <IconButton onClick={()=>{setOpenWarning(true); selectedAlertConfigCallback(alert);}}> 
                                    <EditOutlined sx={{color: blue[400]}}/> 
                                </IconButton>
                                <IconButton onClick={()=>deleteOpen(alert.id)}> {/*  */}
                                    <DeleteOutline sx={{color: red[400]}}/> 
                                </IconButton>
                            </Box>
                        </TableCell>
                        :
                        <TableCell sx={{width: '1rem', p: 0, pr: 3, bgcolor: ''}}>
                            <IconButton onClick={(e)=>handleMenu(e, alert)} size='large'>
                                <MoreVert transform='scale(2)'></MoreVert>
                            </IconButton>
                            <Menu elevation={0} anchorEl={anchorEl} open={openMenu} onClose={()=>setAnchorEl(null)} transformOrigin={{horizontal: 'right'}} PaperProps={{style: {maxHeight: 100, width: '25ch',}}}>
                                <Box sx={{...CustomStyle.row, borderRadius: 2, width: 1, height:75, justifyContent:'space-around', bgcolor: grey[50]}}>
                                <IconButton onClick={()=>{setOpenWarning(true)}} > 
                                    <EditOutlined sx={{color: blue[400], transform: 'scale(2.25)'}}/> 
                                </IconButton>
                                <IconButton onClick={()=>deleteOpen(deleteID)}> 
                                    <DeleteOutline sx={{color: red[400], transform: 'scale(2.25)'}}/> 
                                </IconButton>
                                </Box>
                            </Menu>
                        </TableCell>
                        }
                        <WarningModal open={openWarning} onClick={()=>{adminViewCallback("Edit")}} onClose={()=>{setOpenWarning(false); selectedAlertConfigCallback(null)}} 
                            sx={{ ...CustomStyle.modalMedium}}
                            text={"Are you sure you want to change the settings for this alert?"} 
                        />
                        <DeleteModal open={openDelete} onClick={deleteAlert} onClose={()=>{setOpenDelete(false);}} 
                            sx={{ ...CustomStyle.modalMedium}}
                            text={"Are you sure you want to delete the settings for this alert?"} 
                            buttonText={"Delete Setting"}
                        />
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        <ErrorModal open={openError} onClose={()=>{setOpenError(false)}} sx={{...CustomStyle.modalSmall}}>
        </ErrorModal>
    </TableContainer>
  )
}

export default NotificationConfig