import { Icon, Paper, Typography } from '@mui/material'
import React from 'react'
import Meter from './Meter'
import { WarningAmber } from '@mui/icons-material'
import * as CustomStyle from '../components/CustomStyle'
import { red } from '@mui/material/colors'

const MeterCard = ({title, extras, meterValue, meterMax, meterMin,alert}) => {
  return (
    <Paper elevation={0} sx={{width: {xs: 1, lg: 0.5}, height: 1, p: 2, pr: 1.5, pb: 0, borderRadius: {xs: 3, lg: 1}, border: alert ? {xs: '5px solid red',lg: '3px solid red'}:null}}>
        <Typography sx={{...CustomStyle.subtitleSmall}}>{title}</Typography>
        <Meter value={meterValue} valueMax={meterMax} valueMin={meterMin} alert={alert}/>
        
        {extras}
    </Paper>
  )
}

export default MeterCard