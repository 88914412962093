import React, { useState } from 'react'
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Paper, Avatar, Box, Typography, Link, IconButton, Menu } from '@mui/material'
import { grey,  red, green, deepOrange, blue } from '@mui/material/colors'
import * as CustomStyle from '../../components/CustomStyle'
import { CalendarMonth, CalendarMonthOutlined, DeleteOutline, DeviceThermostat, EditOutlined, MoreVert, Schedule } from '@mui/icons-material'
import { format, parseISO } from 'date-fns';

const DashboardTable = ({datas, isHistory=false}) => {
  
  return (
    <TableContainer component={Box} sx={{border: 1, borderColor: grey[300], borderRadius: 1, mt: 2}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>No.</TableCell>
            {isHistory && (
              <TableCell sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>Cycle No.</TableCell>
            )}
            <TableCell sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>Date & Time</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>Action</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {datas.map((data, i) => (
            <>
            
            <TableRow> {/**/}
              <TableCell sx={{padding: 1.5,  ...CustomStyle.tableCenteredText, fontWeight: 'none', color: grey[600], width: '0.1rem'}}>{i+1}.</TableCell>
              {isHistory && (
                <TableCell sx={{padding: 1.5, ...CustomStyle.tableCenteredText, color: grey[600]}}>{data.cycle_id}</TableCell>
              )}
              
              <TableCell sx={{padding: 1.5, ...CustomStyle.tableCenteredText, color: grey[600]}}>
                <Box sx={{...CustomStyle.columnRow, alignItems: 'center', justifyContent: 'center'}}>
                  <Box sx={{...CustomStyle.rowCentered}}>
                    <CalendarMonthOutlined sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableCenteredText}}>{format(parseISO(data.datetime), 'yyyy-MM-dd')}</Typography>
                  </Box>
                  <Box sx={{...CustomStyle.rowCentered}}>
                    <Schedule sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableCenteredText}}>{format(parseISO(data.datetime), 'HH:mm:ss')}</Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{padding: 1.5, ...CustomStyle.tableCenteredText}}> {data.action} </TableCell>
              
            </TableRow>
            </>
          ))}
          <TableRow>
            
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DashboardTable