import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, Paper, Avatar, Button } from '@mui/material'
import { blue, green, grey } from '@mui/material/colors'
import { PhotoCameraOutlined } from '@mui/icons-material'
import DualText from '../../components/DualText'
import { Spinner } from 'react-bootstrap'
import axiosInstance from '../../components/auth/auth'
import axiosInstanceWithFile from '../../components/auth/auth'
import VisuallyHiddenInput from '../../components/VisuallyHiddenInput'
import useScreenType from 'react-screentype-hook'
import * as CustomStyle from '../../components/CustomStyle'
import TextRow from '../../components/TextRow'
import ScreenLoader from '../../components/ScreenLoader'


const ProfileAccount = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true)
  const [profile_picture_url, setProfilePictureUrl] = useState(null);
  const fileInputRef = useRef(null);
  const screenType = useScreenType();
  const isDesktopScreen = screenType.isDesktop || screenType.isLargeDesktop ? true : false;

  //move formatDate to a util file  later
  const formatDate = (datetime) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const day = date.getDate();
    return `${day}-${month < 10 ? `0${month}` : `${month}`}-${year}`
  }

  useEffect(() => {
      const fetchProfile = async () => {
        try{
            const response = await axiosInstance.get(global.config.server_url + `account/api/profile/`)
            const data = await response.data
            data.last_login = formatDate(data.last_login)
            data.date_joined = formatDate(data.date_joined)
            setProfile(data)
          }catch(error){
            console.log('Error fetching data', error)
          }finally{
            setLoading(false)
          }
      }
      fetchProfile()
  }, [])

    const updateAccount = async () => {
        try {
            if (!isFile(profile.profile_picture)) {
                if (profile.profile_picture != '') {
                    delete profile.profile_picture
                }
            }
            const response = await axiosInstanceWithFile.patch(global.config.server_url + `account/api/update/${profile.id}/`, convertToFormData(profile))
            setProfile(response.data)
            window.location.reload(false)
        } catch (error) {
            console.log("updateAccount Error:", error)
        }
    }
    const isFile = (file) => {
        return file instanceof File;
    }

    const uploadPhoto = async (e) => {
        const file = e.target.files[0]
        if (file) {
            setProfile({ ...profile, profile_picture: file })
            profile.profile_picture = file
            const objectUrl = URL.createObjectURL(file); // Create object URL for the new picture
            setProfilePictureUrl(objectUrl);
        }
        updateAccount();
    }
    const convertToFormData = (data, formData = new FormData(), parentKey = null) => {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                convertToFormData(
                    data[key],
                    formData,
                    parentKey ? `${parentKey}[${key}]` : key
                );
            });
        } else {
            formData.append(parentKey, data);
        }
        return formData;
    };
  return (
    loading? <ScreenLoader /> :
    <>
    <Box sx={{...CustomStyle.container, minHeight: null}}>
      <Typography sx={{...CustomStyle.title}}>Account Profile</Typography>
      <Box sx={{...CustomStyle.columnReverse}}>
        <Paper elevation={0} sx={{...CustomStyle.bodyPadding, pt: 4, width: {xs: 1, lg: '57.5%'}, height: {xs: null, lg:'35.5rem'}}}>
            {isDesktopScreen? <Typography sx={{...CustomStyle.subtitle}}>User Information</Typography> : null}
            <Box display='flex' flexDirection='column' sx={{mt:{xs:0, lg:8}}}>
              <DualText text1={'First Name'} text2={profile?.first_name} textTransform={'capitalize'} colorText1={grey[500]} colorText2={'black'}/>
              <DualText text1={'Last Name'} text2={profile?.last_name} textTransform={'capitalize'} />
              <DualText text1={'Email'} text2={profile?.email}/>
              <DualText text1={'Contact Number'} text2={profile?.phone ?? 'N/A'}/>
              <DualText text1={'User Type'} text2={profile?.is_admin? 'Admin': 'Staff'}/>
              <DualText text1={'Date Joined'} text2={profile?.date_joined}/>
              <DualText text1={'Last Login'} text2={profile?.last_login} />
              <DualText text1={'Activation'} text2={profile?.is_active? 'Active': 'Inactive'} 
                colorText2={green[300]} bgColor2={green[50]} width2={{xs: '30%', lg:'20%'}} textAlign2={'center'}
              />
            </Box>
        </Paper>
        <Paper elevation={0} sx={{...CustomStyle.bodyPadding, pt: 4, width:{xs: 1, lg: '40%'}, height: {xs: null, lg:'35.5rem'}, mb:{xs: 3, lg: 0}}}>
          <Box>
            <Typography sx={{...CustomStyle.subtitle}}>{isDesktopScreen? 'Profile Picture' : "Account Information"}</Typography>
          </Box>
          <Box sx={{...CustomStyle.columnCenter}}>
            <Avatar src={profile?.profile_picture} sx={{...CustomStyle.avatar}}/>
            {isDesktopScreen?
            <TextRow text1="Joined since:" text2={profile?.date_joined}/>
            : null}
            <Button variant="contained" disableElevation sx={{...CustomStyle.containedButton, mt: 5, bgcolor: blue[600], fontWeight: 'normal', p:{xs: 2, lg: 0.75}}}  component="label" role={undefined}
              startIcon={<PhotoCameraOutlined sx={{ ...CustomStyle.buttonIcon }}/>}>
                Change a Photo <VisuallyHiddenInput type="file" ref={fileInputRef} onChange={uploadPhoto}/>
            </Button>
          </Box>
        </Paper>  
      </Box>
    </Box>
    </> 
  )
}

export default ProfileAccount