import React from 'react'
import { Box, Modal, Typography, Button } from '@mui/material'
import { Warning, WarningAmber, WarningOutlined } from '@mui/icons-material'
import { red, grey } from '@mui/material/colors'

const WarningModal = ({open, onClick, onClose, BackdropProps={ style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }, sx, text, buttonText="Yes" }) => {
    
  return (
    <Modal open={open} onClose={onClose} BackdropProps={{ ...BackdropProps }}>
      <Box sx={{ ...sx }}>
        <WarningAmber sx={{color: red['A400'], width: 0.4, height: 0.4, mb: 5}}/>
        <Typography sx={{color: grey[800], fontFamily: 'Segoe UI', fontWeight: 'bold', fontSize: {xs: 36, lg: 16}}}>
          {text}
        </Typography>
        <Box sx={{display:'flex', flexDirection: "column", mt: {xs: 8, lg: 5}}}>
          <Button variant='contained' onClick={onClick} disableElevation
            sx={{bgcolor: red['A400'], ':hover': {bgcolor: red['A700']}, fontFamily: 'Segoe UI', borderRadius: {xs: 2, lg: 1}, textTransform: 'none', fontSize: {xs: 34, lg: 14}}}>
              {buttonText}
          </Button>
          <Button onClick={onClose} 
            sx={{textTransform: 'none', color: grey[800], fontFamily: 'Segoe UI', fontWeight: 'bold', fontSize: {xs: 34, lg: 14}, mt: {xs: 2, lg: 1}}}>
              Cancel
          </Button>  
        </Box>
      </Box>
    </Modal>
  )
}

export default WarningModal