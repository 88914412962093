import * as React from 'react';
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from '@mui/x-charts/Gauge';
import { green, red } from '@mui/material/colors';
import { Typography } from '@mui/material';
import { WarningAmber, WarningAmberRounded } from '@mui/icons-material';
import useScreenType from 'react-screentype-hook';

function GaugePointer({symbol, value, isDesktopScreen, alert}) {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();
  const { valueMax, valueMin } = useGaugeState();
  

  if (valueAngle === null) {
    // No value to display
    return null;
  }


  const arrowWidth = 10;
  const arrowHeight = outerRadius * 0.9; // Adjust the length of the arrow

  // Define points for a triangle arrow
  const points = [
    { x: cx, y: cy - arrowHeight*2 }, // Tip of the arrow
    { x: cx - arrowWidth / 4, y: cy/1.35 }, // Bottom left of the arrow
    { x: cx + arrowWidth / 4, y: cy/1.35}, // Bottom right of the arrow
  ];

  const pointsStr = points.map(p => `${p.x},${p.y}`).join(' ');
  return (
    <g>
      <text x={cx} y={isDesktopScreen? cy-12.5 : cy-17.5} style={{ fill: value===valueMin? 'black' : !alert ? green['A400'] : red['A400'] }} 
        fontSize={isDesktopScreen? 30 : 50} fontWeight={'bold'} textAnchor='middle' dominantBaseline={'middle'} >
          {value.toFixed(1)}
      </text>
      <polygon points={pointsStr} fill="grey" transform={`rotate(${valueAngle * (180 / Math.PI)} ${cx} ${cy})`} />
      <text x={isDesktopScreen? cx/2.5: cx/1.525} y={isDesktopScreen? cy+15 : cy+30} 
        fontSize={isDesktopScreen? 12 : 32} fontWeight={'bold'} textAnchor='middle' dominantBaseline={'middle'}
        // style={{fill:isDesktopScreen? 'black' : 'white'}}
        >
        {valueMin}
      </text>
      <text x={cx} y={isDesktopScreen? cy+15 : cy+30} 
        fontSize={isDesktopScreen? 12 : 32} fontWeight={'bold'} textAnchor='middle' dominantBaseline={'middle'}
        // style={{fill:isDesktopScreen? 'black' : 'white'}}
        >
        {symbol}
      </text>
      <text x={isDesktopScreen? cx*1.6 : cx*1.34} y={isDesktopScreen? cy+15 : cy+30} 
        fontSize={isDesktopScreen? 12 : 32} fontWeight={'bold'} textAnchor='middle' dominantBaseline={'middle'}
        // style={{fill:isDesktopScreen? 'black' : 'white'}}
        >
        {valueMax}
      </text>
    </g>
  );
}

export default function Meter({value=50, valueMax=130, valueMin=0,alert=false}) {
  const screenType = useScreenType();
  const isDesktopScreen = screenType.isDesktop || screenType.isLargeDesktop ? true : false;
  return (
    <>
    {alert ?<WarningAmber sx={{
      mb: {xs: '-18rem', lg: '-0.05rem'}, width: {xs: 0.3, lg: 1},
      color: red['A400'], transform: {xs: 'scale(8)', lg: 'scale(1.5)'}}}/> : null}

    <GaugeContainer
      height={!isDesktopScreen? 250 : null}
      startAngle={-90}
      endAngle={90}
      value={value > valueMax ? valueMax : value}
      innerRadius='80%'
      outerRadius='50%'
      valueMin={valueMin}
      valueMax={valueMax}
      
      sx={{ height: {xs: 0.7, lg: '11.25rem'}, mt: alert? {xs: -8, lg: -5} : {xs: -5, lg: -3},}}
    >
      <GaugeReferenceArc />
      <GaugeValueArc style={!alert ? { stroke: green['A400'], fill: green['A400'] } : { stroke: red['A400'], fill: red['A400'] }}/>
      <GaugePointer symbol={'°C'} value={value} isDesktopScreen={isDesktopScreen} alert={alert}/>
    </GaugeContainer>
    </>
    
  );
}
