import React from 'react';
import AuthenticateContainer from './AuthenticateContainer';
import './ForgotPasswordEmailSent.css';
import './ResetPasswordSuccess.css';
const ResetPasswordSuccess = () => {

    return (
        <AuthenticateContainer>
            <span className="text-center form-title">Password Changed</span>
            <div className="auth-form">
                <span className="material-symbols-rounded email-sent-icon">
                    check_circle
                </span>
                <div className="email-sent-annoucement">
                    <span className="w-100" htmlFor="username">Your password has been successfully changed.</span>
                </div>
                <div className="form-group ">
                    <a href="/login" className="btn btn-primary reset-success-btn">Log In</a>
                </div>
            </div>
        </AuthenticateContainer>
    );
};

export default ResetPasswordSuccess;

