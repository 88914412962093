import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Checkbox,
  TextField,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Select,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { grey } from '@mui/material/colors';

const MultiSelectDropdown = ({ 
  users, 
  selectedUsers,
  onSelectionChange, 
  label, 
  placeholder = "Please Choose",
  bgcolor = grey[100],
  fontSize = { xs: '2.1rem', lg: '0.9rem' },
  sx 
}) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState(selectedUsers);
  const searchInputRef = useRef(null); // Create a ref for the TextField

  const filteredUsers = users.filter(user =>
    `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleToggle = (userId) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    const currentIndex = selected.indexOf(userId);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(userId);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
    if (onSelectionChange) {
      onSelectionChange(newSelected);
    }
  };

  const getSelectedUserNames = () => {
    return selected.map(id => {
      const user = users.find(u => u.id === id);
      return user ? `${user.first_name} ${user.last_name}` : '';
    }).join(', ');
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchTerm, selected]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: 1, ...sx }} pb={{ xs: 2, lg: 0 }}>
      <Typography 
        variant='h6' 
        sx={{ 
          fontWeight: 'bold', 
          pb: 1, 
          fontSize: fontSize, 
          fontFamily: 'Segoe UI'
        }}
      >
        {label}
      </Typography>
      <Select
        multiple
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        displayEmpty
        value={selected}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return placeholder;
          }
          return getSelectedUserNames();
        }}
        sx={{ 
          width: '100%', 
          bgcolor: bgcolor, 
          fontFamily: 'Segoe UI',
          '.MuiInputBase-input': { 
            fontSize: fontSize, 
            fontFamily: 'Segoe UI',
            color: selected.length > 0 ? grey[900] : grey[600],
            height: {xs: null, lg: '0.1rem'},
            alignContent: 'center'
          }
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              width: '300px',
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <MenuItem>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Searching"
            value={searchTerm}
            onChange={handleSearchChange}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={handleKeyDown}
            inputRef={searchInputRef} // Attach the ref to the TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ 
              '.MuiInputBase-input': { 
                fontSize: fontSize, 
                fontFamily: 'Segoe UI',
              }
            }}
          />
        </MenuItem>
        {filteredUsers.map((user) => (
          <MenuItem key={user.id} value={user.id} sx={{ p: 0 }} onClick={handleToggle(user.id)}>
            <ListItem dense>
              <ListItemIcon>
                <Checkbox checked={selected.includes(user.id)} />
              </ListItemIcon>
              <Avatar src={user.profile_picture} alt={`${user.first_name} ${user.last_name}`} />
              <ListItemText
                primary={`${user.first_name} ${user.last_name}`}
                secondary={user.email}
                sx={{ 
                  ml: 2, 
                  '.MuiListItemText-primary': { 
                    fontFamily: 'Segoe UI',
                    fontSize: fontSize,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  },
                  '.MuiListItemText-secondary': { 
                    fontFamily: 'Segoe UI',
                    fontSize: { xs: '1.5rem', lg: '1rem' },
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }
                }}
              />
            </ListItem>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default MultiSelectDropdown;