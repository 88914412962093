import React, { useEffect, useRef } from 'react';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import useScreenType from 'react-screentype-hook';

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const CustomLegend = ({ datasets }) => (
    <div style={{ display: 'flex', justifyContent: 'start', paddingLeft: '1.3rem' }}>
        {datasets.map((dataset) => (
            <div key={dataset.label} style={{ display: 'flex', alignItems: 'center', margin: '0 0.6rem' }}>
                <div style={{ width: '0.7rem', height: '0.7rem', backgroundColor: dataset.borderColor, marginRight: '0.2rem', borderRadius: '100rem' }}></div>
                <Typography fontSize={{xs: '1.25rem', lg: '0.625rem'}} sx={{ paddingTop: '0.2rem' }}>{dataset.label}</Typography>
            </div>
        ))}
    </div>
);

const Chart = ({ title, data }) => {
    const screenType = useScreenType();
    const isDesktopScreen = screenType.isDesktop || screenType.isLargeDesktop ? true : false;
    const scrollableContainerRef = useRef(null);
    let label_num = 0;
    data.datasets.forEach(dataset => {
        if (dataset.labels.length > label_num) {
            label_num = dataset.labels.length;
        }
    });
    const chartData = {
        datasets: data.datasets.map(dataset => ({
            label: dataset.label,
            data: dataset.data.map((value, index) => ({ x: dataset.labels[index], y: value })),
            borderColor: dataset.borderColor,
            backgroundColor: dataset.backgroundColor || dataset.borderColor,
            fill: true,
        })),
    };
    
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    tooltipFormat: 'Pp', // Corrected format string
                    displayFormats: {
                        hour: 'HH:mm',
                    },
                },
                title: {
                    display: true,
                    text: 'Time',
                    font: {
                        size: isDesktopScreen? 12 : 18,
                    }
                },
                ticks: {
                    font:{
                        size: isDesktopScreen? 12 : 18,
                    }
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Value',
                    font: {
                        size: isDesktopScreen? 12 : 18,
                    }
                },
                ticks: {
                    font:{
                        size: isDesktopScreen? 12 : 18,
                    }
                }
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    // Set a minimum width for the chart based on the number of labels
    const chartWidth = Math.max(635, label_num * 30);
    let unit = '';
    if (data.unit && data.unit.includes('u')) {
        unit = data.unit.split('u')[1];
        unit = String.fromCharCode(parseInt(unit, 16));
    }

    return (
        <Card>
            <CardHeader
                title={<span>{title}&nbsp;&nbsp;{unit}</span>}
                titleTypographyProps={{
                    variant: 'subtitle2',
                    align: 'left',
                    fontSize: {xs: '2rem', lg: '1rem'},
                    fontWeight: '700',
                    fontFamily: 'Segoe UI, sans-serif',
                    marginLeft: '1rem'
                }}
                sx={{ paddingBottom: '0' }}
            />
            <CardContent sx={{ paddingTop: '0' }}>
                <div style={{ width: '100%', height: '400px', overflowX: 'auto' }}>
                    <div style={{ width: `${chartWidth}px`, height: '100%' }}>
                        <Line data={chartData} options={options} />
                    </div>
                </div>
                <CustomLegend datasets={data.datasets} />
            </CardContent>
        </Card>
    );
};

Chart.defaultProps = {
    data: {
        labels: [],
        datasets: []
    }
};

export default Chart;
