import React, { useState } from 'react'
import * as CustomStyle from '../../components/CustomStyle'
import LabeledDropdown from '../../components/LabeledDropdown'
import { Box, Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import IOSSwitch from '../../components/IOSSwitch'
import { SaveOutlined } from '@mui/icons-material'
import { blue, grey, red } from '@mui/material/colors'
import SuccessModal from '../../components/SuccessModal'
import MultiSelectDropdown from '../../components/MultiSelectDropdown'
import ErrorModal from '../../components/ErrorModal'
import axiosInstance from '../../components/auth/auth'

const AlertNotificationForm = ({ isDesktop, method, adminViewCallback, selectedAlertConfig, formData }) => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [alertType, setAlertType] = useState(formData.alertType);
  const [selectedAlertType, setSelectedAlertType] = useState(method==='Edit' ? selectedAlertConfig.alert_type : null);
  const [triggerBy, setTriggerBy] = useState(formData.trigger);
  const [selectedTriggerBy, setSelectedTriggerBy] = useState(method==='Edit' ? selectedAlertConfig.trigger_by : null);
  const [enableRuleUponCreation, setEnableRuleUponCreation] = useState(method==='Edit' ? selectedAlertConfig.is_enable : true);
  const [attentionLevel, setAttentionLevel] = useState(formData.level);
  const [selectedAttentionLevel, setSelectedAttentionLevel] = useState(method==='Edit' ? selectedAlertConfig.alert_level : null);
  const [recipients, setRecipients] = useState(formData.recipients);
  const [selectedRecipients, setSelectedRecipients] = useState(method==='Edit' ? selectedAlertConfig.recipients : []);
  const [enableEmail, setEnableEmail] = useState(method==='Edit' ? selectedAlertConfig.is_email : false);
  const [enableTelegram, setEnableTelegram] = useState(method==='Edit' ? selectedAlertConfig.is_telegram : false);

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    adminViewCallback("Notification Configuration");
  };
  const saveSettings = async () => {
    const data = {
      recipients: typeof selectedRecipients[0] === 'object' ? selectedRecipients.map(recipient => recipient?.id) : selectedRecipients,
      alert_level: typeof selectedAttentionLevel === 'object' ? selectedAttentionLevel?.id : selectedAttentionLevel,
      alert_type: typeof selectedAlertType === 'object' ? selectedAlertType?.id : selectedAlertType,
      trigger_by: typeof selectedTriggerBy === 'object' ? selectedTriggerBy?.id : selectedTriggerBy,
      is_enable: enableRuleUponCreation,
      is_email: enableEmail,
      is_telegram: enableTelegram
    }
    
    const apiUrl = method === 'Create' ? 'alert/api/create/' : `alert/api/update/${selectedAlertConfig.id}/`;

    try{
      const response = method === 'Create' ? await axiosInstance.post(global.config.server_url + apiUrl, data) : await axiosInstance.patch(global.config.server_url + apiUrl, data);
      setOpenSuccess(true);
    }catch(error){
      setOpenError(true);
      console.log('Error fetching data', error);
    }
    
  }

  const handleSelectedRecipients = (selectedRecipients) => {
    setSelectedRecipients(selectedRecipients);
  };

  return (
    <Box sx={{...CustomStyle.column, width: 1}}>
      <Box sx={{ ...CustomStyle.columnRow, width: 1, justifyContent: 'space-between' }}>
        <Box sx={{ ...CustomStyle.column, width: {xs: 1, lg: 0.48}, bgcolor: '' }}>
          <LabeledDropdown label='Type of Alert Notification' value={selectedAlertType?.id} dropdownItems={alertType} placeholder={'Please Choose'} sx={{mb: 3}}
            onChange={(e) => {setSelectedAlertType(e.target.value)}}
          />
          <LabeledDropdown label='Trigger By' value={selectedTriggerBy?.id} dropdownItems={triggerBy} placeholder={'Please Choose'} sx={{mb: isDesktop? 3 : 0}}
            onChange={(e) => {setSelectedTriggerBy(e.target.value)}}
          />
          {isDesktop && <Box sx={{...CustomStyle.column}}>
            <Typography variant='h6' sx={{...CustomStyle.label}}>Enable Rule Upon Creation</Typography>
            <IOSSwitch sx={{...CustomStyle.iosSwitch}} defaultChecked={enableRuleUponCreation} 
              onChange={(e) => {setEnableRuleUponCreation(e.target.checked)}}
            />
          </Box>}
        </Box>
        <Box sx={{ ...CustomStyle.column, width: {xs: 1, lg: 0.48} }}>
          <LabeledDropdown label='Level of Attention' value={selectedAttentionLevel?.id} dropdownItems={attentionLevel} placeholder={'Please Choose'} sx={{mb: 3}}
            onChange={(e) => {setSelectedAttentionLevel(e.target.value)}}
          />
          <Box sx={{display: 'flex', flexDirection: 'column', mb: 3}}>
            <Typography variant='h6' sx={{...CustomStyle.label, pb: 0, pt: 0}}>Recipients Involved</Typography>
            <MultiSelectDropdown users={recipients} selectedUsers={selectedRecipients.map(item => item.id)} onSelectionChange={handleSelectedRecipients}/>
          </Box>
          
          {!isDesktop && <Box sx={{...CustomStyle.column, mb: 3}}>
            <Typography variant='h6' sx={{...CustomStyle.label}}>Enable Rule Upon Creation</Typography>
            <IOSSwitch sx={{...CustomStyle.iosSwitch}} defaultChecked={enableRuleUponCreation} 
              onChange={(e) => {setEnableRuleUponCreation(e.target.checked)}}
            />
          </Box>}

          <Box sx={{...CustomStyle.column, width: 1}}>
            <Typography variant='h6' sx={{...CustomStyle.label}}>Setting</Typography>
            <Box sx={{...CustomStyle.row, alignItems: 'center', justifyContent: 'space-between', height: {xs: '4rem', lg: '2rem'}, mb: 1}}>
              <Typography variant='h6' sx={{...CustomStyle.label, color: grey[500]}}>Enable Email Notification</Typography>
              <IOSSwitch sx={{...CustomStyle.iosSwitch}} defaultChecked={enableEmail} 
                onChange={(e) => {setEnableEmail(e.target.checked)}}
              />  
            </Box>
            <Box sx={{...CustomStyle.row, alignItems: 'center', justifyContent: 'space-between', height: {xs: '4rem', lg: '2rem'}}}>
              <Typography variant='h6' sx={{...CustomStyle.label, color: grey[500]}}>Enable Telegram Notification</Typography>
              <IOSSwitch sx={{...CustomStyle.iosSwitch}} defaultChecked={enableTelegram} 
                onChange={(e) => {setEnableTelegram(e.target.checked)}}
              />  
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{...CustomStyle.columnReverse, justifyContent: 'flex-end', gap: 2, mt: 6}}>
        <Button variant='outlined' disableElevation 
          sx={{...CustomStyle.outlinedButton, color: grey[800], height: {xs: '6rem', lg: '3.25rem'},
              width: {xs: 1, lg: 0.15}, p:1.25, mt: {xs: 2, lg: 0}, border: '1px solid', ':hover': {border: '1px solid'}}}  
              onClick={(e) => {adminViewCallback("Notification Configuration")}}>
          Cancel
        </Button>
        <Button variant='contained' disableElevation 
          sx={{...CustomStyle.containedButton, width: {xs: 1, lg: 0.15}, p:1.25, bgcolor: blue[600], height: {xs: '6rem', lg: '3.25rem'}}}
          startIcon={<SaveOutlined sx={{...CustomStyle.buttonIcon}}/>} onClick={saveSettings}>
          Save Alert
        </Button>
      </Box>  
      <SuccessModal open={openSuccess} onClose={handleCloseSuccess} sx={{...CustomStyle.modalSmall}}>
      </SuccessModal>
      <ErrorModal open={openError} onClose={() => {setOpenError(false)}} sx={{...CustomStyle.modalSmall}}>
      </ErrorModal>
    </Box>
    
  )
}

export default AlertNotificationForm