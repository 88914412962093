import { Box, Typography } from '@mui/material'
import React from 'react'

const TextRow = ({text1, text2, sx={mt: 4}, text1sx={mr: 1}, text2sx={fontWeight: 'bold'}}) => {
  return (
    <Box display='flex' flexDirection='row' sx={{...sx}} >
        <Typography variant='h6' sx={{...text1sx}} fontFamily={'Segoe UI'} fontSize={{xs: '2.75rem', lg: '0.9rem'}}>{text1}</Typography>
        <Typography variant='h6' sx={{...text2sx}} fontFamily={'Segoe UI'} fontSize={{xs: '2.75rem', lg: '0.9rem'}}>{text2}</Typography>
    </Box>
  )
}

export default TextRow