import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import * as CustomStyle from '../components/CustomStyle';
import { green, grey } from '@mui/material/colors';

const BoxedTimer = ({ hours, minutes, seconds }) => {
    return (
        <Box sx={{ ...CustomStyle.row, justifyContent: 'center' }}>
            <Box sx={{ ...CustomStyle.column, width: { xs: 120, lg: 50 } }}>
                <Typography variant="h6" sx={{ ...CustomStyle.boxedText, bgcolor:grey[800] }}>{hours.toString().padStart(2, '0')}</Typography>
                <Typography sx={{ ...CustomStyle.timerLabel }}>Hours</Typography>
            </Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: { xs: 90, lg: 30 }, pl: 0.5, pr: 0.5, color: grey[800] }}>:</Typography>
            <Box sx={{ ...CustomStyle.column, width: { xs: 120, lg: 50 } }}>
                <Typography variant="h6" sx={{ ...CustomStyle.boxedText, bgcolor:grey[800] }}>{minutes.toString().padStart(2, '0')}</Typography>
                <Typography sx={{ ...CustomStyle.timerLabel }}>Minutes</Typography>
            </Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: { xs: 90, lg: 30 }, pl: 0.5, pr: 0.5, color: grey[800] }}>:</Typography>
            <Box sx={{ ...CustomStyle.column, width: { xs: 120, lg: 50 } }}>
                <Typography variant="h6" sx={{ ...CustomStyle.boxedText, bgcolor:grey[800] }}>{seconds.toString().padStart(2, '0')}</Typography>
                <Typography sx={{ ...CustomStyle.timerLabel }}>Seconds</Typography>
            </Box>
        </Box>
    );
}

function CircularProgressWithBoxedTimer(props) {
    const { hours, minutes, seconds, size } = props;
    const markerSize = 25; // Size of the triangle marker
    const rotation = (props.value / 100) * 360;
    return (
        <Box sx={{ position: 'relative', alignItems: 'center',justifyContent: 'center', color: green['300'], width: {xs:'600px',lg:size}, height: {xs:'600px',lg:size} }}>
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <BoxedTimer hours={hours} minutes={minutes} seconds={seconds} />
                {/* <Box
                  sx={{
                      position: 'absolute',
                      left: '50%',
                      transform: `rotate(${rotation}deg) translateX(-50%) translateY(-${size / 2 + markerSize}px)`,
                      transformOrigin: 'center',
                  }}
                >
                    <svg width={markerSize} height={markerSize} viewBox="0 0 10 10">
                        <polygon points="5,10 0,0 10,0" fill="black" />
                    </svg>
                </Box> */}
            </Box>
            
        </Box>
    );
}

CircularProgressWithBoxedTimer.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
    hours: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
    size: PropTypes.number,
};

CircularProgressWithBoxedTimer.defaultProps = {
    size: 250, // Set a default size that can fit the timer
};

export default function CircularProgressTimer({progress, time}) {
    

    return (
        <CircularProgressWithBoxedTimer value={progress} hours={time.hours} minutes={time.minutes} seconds={time.seconds} />
    );
}
