import { grey } from '@mui/material/colors'
import React from 'react'


//text
export const title = {mb:3, mt: 3, fontSize: {xs: '4.4rem', lg: '2.2rem'}, textTransform: 'capitalize', fontWeight: 'bold', fontFamily: 'Arial Rounded MT, Segoe UI, sans-serif'}
export const subtitle = {fontSize: {xs: '2.75rem', lg: '1.25rem'}, fontWeight: 'bold', color: grey['A700'], fontFamily: 'Segoe UI'}
export const subtitleSmall = {fontSize: {xs: '2.25rem', lg: '1.1rem'}, fontWeight: 'bold', color: grey['A700']}
export const label = {fontWeight: 'bold', pb: 1, pt: {xs: 1, lg: 0}, fontSize: {xs: '2.1rem', lg: '0.9rem'}, fontFamily: 'Segoe UI'}
export const tableText = {fontWeight: 'bold', fontSize: {xs: '2rem', lg:'0.75rem'}, fontFamily: 'Segoe UI'}
export const tableHeader = {fontSize: {xs: '2.05rem', lg:'0.8rem'}, fontWeight: 'bold', fontFamily: 'Segoe UI', pt: {xs: '2rem', lg: '0.45rem'}, pb: {xs: '2rem', lg: '0.45rem'}, color: grey['A900'], lineHeight: {xs: '3rem', lg: '1.5rem'}}
export const tableCenteredText = {...tableText, textAlign: 'center'}
export const cardText = {fontWeight: 'bold', fontSize: {xs: '1.75rem', lg:'0.75rem'}}
export const boxedText = {color:'white', p:1, pb:0, pt:0, borderRadius:1, fontSize:{xs: '4.375rem', lg:'1.875rem'}, fontWeight: 'bold'}
export const timerLabel = {fontWeight: 'bold', fontSize: {xs: '1.5rem', lg: '0.75rem'}, color: grey[400], textAlign: 'center'}

// layout
export const container = {mr: {xs: 4, lg: 2}, ml: {xs: 4, lg: 2},  bgcolor: '', minHeight: '43.75rem', pb: 4}
export const bodyPadding = {p: {xs: 6, lg: 4}, pt: 2,}
export const row = {display: 'flex', flexDirection: 'row'}
export const rowCentered = {...row, alignItems: 'center', justifyContent: 'center'}
export const column = {display: 'flex', flexDirection: 'column', width:{xs:1, lg: 0.475}}
export const columnReverse = {display:'flex', flexDirection: { xs: "column-reverse", lg: "row" }, justifyContent:'space-between', gap: 4}
export const rowReverse = {display: 'flex', flexDirection: 'row-reverse'}
export const columnCenter = {p:2, alignItems:'center', mt:3, display: 'flex', flexDirection: 'column'}
export const columnRow = { display: 'flex', flexDirection: {xs: 'column', lg: 'row'}, gap: 2 }
export const rowColumn = { display: 'flex', flexDirection: {xs: 'row', lg: 'column'}, gap: 1, mt: {xs: 4, lg: 0}, mb: {xs: 2, lg: 0} }
export const maxSpaceBetween = { width: 1, justifyContent:'space-between' }
export const maxSpaceAround = { width: 1, justifyContent:'space-around' }
export const tableTextBox = {...tableText, p:0.5, borderRadius:1, textAlign:'center'}
export const tableCenteredTextBox = {...tableCenteredText, p:0.5, borderRadius:1, textAlign:'center'}
export const bodyContainer60px = { ...bodyPadding, width: {xs: 1, lg: 0.6} }
export const bodyContainer40px = { ...bodyPadding, width: {xs: 1, lg: 0.4} }


// button/icon
export const avatar = { width: {xs:250, lg:180}, height: {xs:250, lg:180} }
export const buttonIcon = { transform: {xs: 'scale(2.25)', lg:'scale(1.25)'}, mr: {xs: 3, lg: 0} }
export const subtitleButton = { transform: {xs: 'scale(2.5)', lg:'scale(1.5)'}, mr: {xs: 3, lg: 0} }
export const outlinedButton = { fontSize: {xs: '2rem', lg: '1rem'}, fontFamily: 'Segoe UI',  width: {xs: 0.5, lg: 0.9}, textTransform: 'none', border: '3px solid', fontWeight: 'bold', '&:hover': {border: '3px solid'} }
export const containedButton = { fontSize: {xs: '2rem', lg: '1rem'}, fontFamily: 'Segoe UI', width: {xs: 0.5, lg: 0.9}, textTransform: 'none' }
export const tableIcon = {fontSize: {xs: '2.625rem', lg:'1.125rem'}, color: grey[500], mr: {xs: 1, lg: 0.25}}
export const iosSwitch = { m:{xs: 4, lg: 2}, mt:{xs: 1, lg: 2}, transform: {xs: 'scale(2)', lg: 'scale(1)'} }
export const iosSwitchTable = {'& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb:before': {top: '2px'}, '& .MuiSwitch-thumb:before': {top: '2px'}, transform: {xs: 'scale(2)', lg: 'scale(1)'}, m: {xs: 3, lg: 0} }
export const iosSwitchBig = {width: 70,  m:{xs: 1, lg: 3.5}, mt:{xs: 5, lg: 4}, transform: {xs: 'scale(3)', lg: 'scale(2)'}, 
'& .MuiSwitch-switchBase.Mui-checked': {transform: 'translateX(43px)' }, 
'& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb:before': {left: '-37.5px', transform: 'scale(1)'},
'& .MuiSwitch-thumb:before':  {left: '30px', transform: 'scale(1)'} 
}
export const iosLockSwitch = {width: 75, '& .MuiSwitch-switchBase.Mui-checked': {transform: 'translateX(48px)' }, 
    '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: '17.5px 17.5px', backgroundImage: 'url("images/unlock.png")'},
    '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb:before': {content: '"OPEN"', left: '-45px', },
    '.MuiSwitch-thumb': {backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: '17.5px 17.5px', backgroundImage: 'url("images/lock.png")'},
    '& .MuiSwitch-thumb:before': {content: '"CLOSE"', left: '20px'}
}

// modal
export const modalSmall = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs:  800, lg: 460},
    height: {xs: 600, lg: 360},
    bgcolor: 'background.paper',
    p:5,
    borderRadius: '10px',
    textAlign: 'center',
}

export  const modalMedium = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs:  800, lg: 460},
    height: {xs: 700, lg: 400},
    bgcolor: 'background.paper',
    border: '0px solid #000',
    pl: 10,
    pr: 10,
    pt: 5,
    pb: 5,
    borderRadius: '5px',
    textAlign: 'center',
  };



