import React from 'react';
import AuthenticateContainer from './AuthenticateContainer';
import './ForgotPasswordEmailSent.css';
import { useLocation } from 'react-router-dom';
const ForgotPasswordEmailSent = () => {
    const location = useLocation();
    const { email } = location.state || {};

    return (
        <AuthenticateContainer>
            <span className="text-center form-title">Email Sent</span>
            <div className="auth-form">
                <span className="material-symbols-rounded email-sent-icon">
                    check_circle
                </span>
                <div className="email-sent-annoucement">
                    <span className="w-100" htmlFor="username">We sent an email to {email} with a link to reset your password</span>
                </div>
            </div>
        </AuthenticateContainer>
    );
};

export default ForgotPasswordEmailSent;

