import React, { useState } from 'react'
import { Circle, Error, Info, Warning } from '@mui/icons-material'
import { blue, grey, orange, red } from '@mui/material/colors'
import { Box, Button, Typography } from '@mui/material'
import * as CustomStyle from '../../components/CustomStyle'
import axiosInstance from '../../components/auth/auth'
import SuccessModal from '../../components/SuccessModal'
import ErrorModal from '../../components/ErrorModal'
import NotificationHistoryModal from './NotificationHistoryModal'


const NotificationTable = ({notification, isDesktop, isTopbar=false, unresolvedCount, setUnresolvedCount, isAdmin}) => {

    const logLevel = notification.alert.alert_level.name;
    const alertType = notification.alert.alert_type.name;
    //recipients is a list, combine the names into a single string
    const recipients = notification.alert.recipients.map((recipient) => recipient.first_name).join(', ');
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);


  const resolveAlert = async () => {
      const apiUrl = `alert/api/log/update/${notification.id}/`;
      const data = {
            is_resolve: true
      }
      try{
        await axiosInstance.patch(global.config.server_url + apiUrl, data);
        setOpenSuccess(true);
        setUnresolvedCount(unresolvedCount - 1);
      }catch(error){
        setOpenError(true);
        console.log('Error resolving alert', error)
      }
  }

  const NotificationStatus = ({}) => {
    //calculate how long has it been since the notification was created
    const calculateTime = (created_at) => {
        const diff = Date.now() - new Date(created_at).getTime();
        const days = Math.floor(diff / (24 * 60 * 60 * 1000));
        const hours = Math.floor((diff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
        const minutes = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));
        if(days > 0){
            return `${days}d`;
        }else if(hours > 0){
            return `${hours}h`;
        }else{
            return `${minutes}m`;
        }
    }

    const circleColor = logLevel === 'High' ? red[500] : logLevel === 'Medium' ? orange[500] : blue[500]
    
    return(
        <Box sx={{display: 'flex', flexDirection: 'column', width: '2.25rem', bgcolor: '', alignItems: 'center'}}>
            <Typography sx={{...CustomStyle.tableText, fontSize: {xs: '1.75rem', lg: '0.75rem'}, color: grey[500], mb: isTopbar? -0.75 : 0}}>{calculateTime(notification.created_datetime)}</Typography>
            <Circle sx={{transform: {xs: 'scale(1)', lg: 'scale(0.5)'}, color: circleColor, display: notification.is_resolve ? 'none' : ''}}/>
        </Box>    
    )
  }

  const NotificationIcons = ({}) => {
      if(logLevel === 'High'){
          return (<Error sx={{...CustomStyle.buttonIcon, color: 'red', ml: {xs: 2, lg: 0}, transform: {xs: 'scale(3.5)', lg: isTopbar? 'scale(2.5)':'scale(1.25)'}}}/>)
      }else if(logLevel === 'Medium'){
          return (<Warning sx={{...CustomStyle.buttonIcon, color: 'orange', ml: {xs: 2, lg: 0}, transform: {xs: 'scale(3.5)', lg: isTopbar? 'scale(2.5)':'scale(1.25)'}}}/> ) 
      }else{
          return (<Info sx={{...CustomStyle.buttonIcon, color: 'blue', ml: {xs: 2, lg: 0}, transform: {xs: 'scale(3.5)', lg: isTopbar? 'scale(2.5)':'scale(1.25)'}}}/>)
      }
  }

  const NotificationContent = ({sx}) => {
      const textColor = logLevel === 'High' ? red[500] : logLevel === 'Medium' ? orange[500] : blue[500]
      return (
      <Box sx={{ ...CustomStyle.column, gap: 1, bgcolor: '', ...sx }}>
          <Box sx={{ ...CustomStyle.row, gap: 2, bgcolor: '', alignItems: 'center' }}>
              <Typography sx={{ ...CustomStyle.tableText, fontSize: {xs: '2rem', lg: '0.9rem'} }}>{alertType}</Typography>
              <Typography sx={{ ...CustomStyle.tableText, fontSize: {xs: '2rem', lg: '0.9rem'}, color: textColor }}>{notification.location}</Typography>
          </Box>
          <Typography sx={{ ...CustomStyle.tableText, fontWeight: 'none', fontSize: {xs: '1.95rem', lg: '0.85rem'} }}>{notification.message}</Typography>
      </Box>  
      )
  }

  const NotificationButtons = ({}) => {
      const statusResolved = {
          bgcolor: !isAdmin ? logLevel === 'High' ? red[100] : logLevel === 'Medium' ? orange[50] : '':'white',
          color: !isAdmin ? logLevel === 'High' ? red[100] : logLevel === 'Medium' ? orange[50] : '' : 'white',
          border: !isAdmin ? logLevel === 'High' ? red[100] : logLevel === 'Medium' ? orange[50] : '' : '3px solid white',
      }

    const resolveButton = {bgcolor: logLevel === 'High' ? red[500] : logLevel === 'Medium' ? orange[500] : blue[500],
        border: `3px solid ${logLevel === 'High' ? red[500] : logLevel === 'Medium' ? orange[500] : blue[500]}`,
        '&:hover': {
            bgcolor: logLevel === 'High' ? red[500] : logLevel === 'Medium' ? orange[500] : blue[500],
            border: `3px solid ${logLevel === 'High' ? red[500] : logLevel === 'Medium' ? orange[500] : blue[500]}`,
        },
        '&:disabled':{...statusResolved}
    }
    
    const historyButton = {
        bgcolor: 'white',
        color: logLevel === 'High' ? red[500] : logLevel === 'Medium' ? orange[500] : blue[500],
        border: `3px solid ${logLevel === 'High' ? red[500] : logLevel === 'Medium' ? orange[500] : blue[500]}`,
        '&:hover': {bgcolor: 'white',
            color: logLevel === 'High' ? red[500] : logLevel === 'Medium' ? orange[500] : blue[500],
            border: `3px solid ${logLevel === 'High' ? red[500] : logLevel === 'Medium' ? orange[500] : blue[500]}`,
        }, 
        '&:disabled':{...statusResolved}
    }

    return (
        <Box sx={{width: {xs: isTopbar? '25rem':'42.5rem', lg: "15rem"}, bgcolor: ''}}>
            <Box sx={{ ...CustomStyle.row, gap: 5, bgcolor: '' }}>

                <Button variant="contained" disableElevation disabled={notification.is_resolve || !isAdmin} 
                    sx={{...CustomStyle.containedButton, ...resolveButton}}
                    onClick={() => resolveAlert()}>
                        Resolve
                    </Button>
                <Button variant="contained" disableElevation disabled={notification.is_resolve} 
                    sx={{...CustomStyle.containedButton, ...historyButton}}
                    onClick={() => setOpenHistory(true)}>
                    History
                </Button>
            </Box>    
        </Box>
    )
  }

   return isTopbar || !isDesktop? 
   (
    <Box sx={{...CustomStyle.column, width: 1, gap: 1, mb: {xs: 2, lg: 1}, pb: 2, alignItems: 'center', borderRadius: isTopbar? 0 : 2,
        bgcolor: !notification.is_resolve? logLevel === 'High' ? red[100] : logLevel === 'Medium' ? orange[50] : '' : ''}}
    >
        <Box sx={{...CustomStyle.row, gap: 5, mt: 1, p: 1, pl:3, pr: 3, alignItems: 'center', width: 1, justifyContent: 'space-between', bgcolor: ''}}>
            <NotificationIcons/>
            <Box sx={{...CustomStyle.column, width: 1, bgcolor: ''}}>
                <NotificationContent sx={{width: 1}}/>
                <Typography sx={{...CustomStyle.tableText, fontWeight: '500', fontSize: {xs:'1.8rem' ,lg: '0.8rem'}, color: grey[600], bgcolor:'', width: "20rem", textTransform: 'capitalize'}}>To {recipients}</Typography>
                {notification.is_resolve?
                    <Typography sx={{...CustomStyle.tableText, fontWeight: '500', fontSize: {xs:'1.8rem' ,lg: '0.8rem'}, color: grey[600], bgcolor:'', width: "20rem", textTransform: 'capitalize'}}>Resolved by {notification.resolve_by.email || 'N/A'}</Typography>
                :
                    null
                }
            </Box>
            <NotificationStatus/>
        </Box>
        {!notification.is_resolve ? <NotificationButtons/> : null}
        <NotificationHistoryModal open={openHistory} onClose={() => {setOpenHistory(false)}} sx={{}} notification={notification} />
        <SuccessModal open={openSuccess} onClose={()=>{setOpenSuccess(false)}} sx={{...CustomStyle.modalSmall}}>
        </SuccessModal>
        <ErrorModal open={openError} onClose={() => {setOpenError(false)}} sx={{...CustomStyle.modalSmall}}>
        </ErrorModal>
    </Box>
   )
   :
    (
    <Box sx={{...CustomStyle.row, gap: 2, mt: 1, p: 1, pl:3, pr: 3, alignItems: 'center', borderRadius: 1, width: 1, justifyContent: 'space-between',
        bgcolor: !notification.is_resolve ? logLevel === 'High' ? red[100] : logLevel === 'Medium' ? orange[50] : '' : ''}}
    >
        <NotificationIcons/>
        <NotificationStatus/>
        <NotificationContent/>
               <Typography sx={{ ...CustomStyle.tableText, fontWeight: '500', fontSize: '0.8rem', color: grey[600], bgcolor: '', mt: 4, width: "20rem", textTransform: 'capitalize' }}>To {recipients||'N/A'}</Typography>
               {notification.is_resolve ? (
                   <Typography
                       sx={{
                           ...CustomStyle.tableText,
                           fontWeight: '500',
                           fontSize: '0.8rem',
                           color: grey[600],
                           bgcolor: '',
                           mt: 4,
                           width: '15rem',
                       }}
                   >
                       Resolved by {notification.resolve_by.email || 'N/A'}
                   </Typography>
               ) : <NotificationButtons />}
       
        <NotificationHistoryModal open={openHistory} onClose={() => {setOpenHistory(false)}} sx={{}} notification={notification}/>
        <SuccessModal open={openSuccess} onClose={()=>{setOpenSuccess(false);}} sx={{...CustomStyle.modalSmall}}>
        </SuccessModal>
        <ErrorModal open={openError} onClose={() => {setOpenError(false)}} sx={{...CustomStyle.modalSmall}}>
        </ErrorModal>
    </Box>
    
  )
}

export default NotificationTable