import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, IconButton, Paper, Switch, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as CustomStyle from '../../components/CustomStyle';
import { blue, green, grey, red } from '@mui/material/colors';
import { CalendarMonthOutlined, Download, OpenInNew, Schedule, WarningAmber } from '@mui/icons-material';
import DashboardTable from './DashboardTable'
import IOSSwitch from '../../components/IOSSwitch'
import DualText from '../../components/DualText';
import SearchBar from '../../components/searchbar';
import BasicDropdown from '../../components/BasicDropdown';
import CircularProgressTimer from '../../components/CircularProgressTimer';
import CustomPagination from '../../components/CustomPagination';
import axiosInstance, { checkAndRefreshToken,getAccessToken } from '../../components/auth/auth';
import SuccessModal from '../../components/SuccessModal';
import ErrorModal from '../../components/ErrorModal';
import {format,differenceInSeconds } from 'date-fns';
import ScreenLoader from '../../components/ScreenLoader';


const MixingRoomDashboard = () => {
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [fullScreen, setFullScreen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [webSocketConnected, setWebSocketConnected] = useState(true);
  const dataWsRef = useRef(null);
  const retryIntervalData = useRef(null);
  const mixerSensors = { 'IOT-S07': 'Mixer Machine On/Off', 'IOT-S08': 'Mixer Door On/Off', 'IOT-S09': 'Metal Detector On/Off'};

  //History Table
  const [historyTableData, setHistoryTableData] = useState([]); 
  const [searchHistoryQuery, setSearchHistoryQuery] = useState('');
  const logsHistoryFilter = ["Today", "This Week", "This Month", "This Year", "All Time"]
  const [historyFilter, setHistoryFilter] = useState('This Week');
  const [historyPage, setHistoryPage] = useState(1);
  const [historyCount, setHistoryCount] = useState(0);
  const totalHistoryPages = Math.ceil(historyCount / rowsPerPage);

  //Websocket Table
  const [webSocketTableData, setWebSocketTableData] = useState([]); 
  const [webSocketAllData, setWebSocketAllData] = useState([]); 
  const [webSocketPage, setWebSocketPage] = useState(1);
  const [webSocketCount, setWebSocketCount] = useState(0);
  const totalWebSocketPages = Math.ceil(webSocketCount / rowsPerPage);

  //PROGRESS RELATED
  const [mixerState, setMixerState] = useState(false)
  const [mixerDateTimeState, setMixerDateTimeState] = useState('');
  const [mixerStartDateTimeState, setMixerStartDateTimeState] = useState('');
  const [mixerEndDateTimeState, setMixerEndDateTimeState] = useState('');
  const [mixerGateState, setMixerGateState] = useState(false)
  const [mixerGateOpenDateTimeState, setMixerGateOpenDateTimeState] = useState('');
  const [mixerGateDateTimeState, setMixerGateDateTimeState] = useState('');
  const [metalDetectState, setMetalDetectState] = useState(false)
  const [metalDetectDateTimeState, setMetalDetectDateTimeState] = useState('');
  const [progressState, setProgressState] = useState('REST')
  const [progress, setProgress] = useState(0);
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [serialNumber, setSerialNumber] = useState(null); 

  //Alert 
  const [alertDoor, setAlertDoor] = useState(false);
  const [alertDetector, setAlertDetector] = useState(false);

  //Modal
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  //Get serial number of station
  useEffect(() => {
    getSerialNumber();
  }, []);

  useEffect(() => {
    fetchMixerLogs();
  }, [serialNumber,historyFilter, historyPage, searchHistoryQuery]);

  const getApiUrl = (isDownload = false) => {
    let apiUrl = global.config.server_url + 'station/api/sensor/data/' + serialNumber + '/?sensors=IOT-S07&sensors=IOT-S08&sensors=IOT-S09';

    switch (historyFilter) {
      case 'Today':
        apiUrl += '&datetime_filter=Today';
        break;
      case 'This Week':
        apiUrl += '&datetime_filter=This Week';
        break;
      case 'This Month':
        apiUrl += '&datetime_filter=This Month';
        break;
      case 'This Year':
        apiUrl += '&datetime_filter=This Year';
        break;
      case 'All Time':
        // No datetime_filter needed for 'All Time'
        break;
      default:
        break;
    }

    if (isDownload) {
      apiUrl += '&download_history_logs=True';
    }

    return apiUrl;
  };

  const getSerialNumber = async () => {
    try {
      const response = await axiosInstance.get(global.config.server_url + 'station/api/stations/data/?sensors=IOT-S07&sensors=IOT-S08&sensors=IOT-S09')
      setSerialNumber(response.data[0].serial_number);
    }catch(error) {
      console.error('There was an error fetching the data!', error);
    } ; 
  }

  const fetchMixerLogs = async () => {
    if (!serialNumber) return;
    const apiUrl = getApiUrl();
    try {
      const response = await axiosInstance.get(apiUrl);
      const data = response.data;
      if (data.sensor_data !== undefined){
        const combinedData = data.sensor_data.flatMap(cycle =>
          cycle.data.map(entry => ({
            cycle_id: cycle.cycle_id,
            datetime: entry.datetime,
            action: entry.action,
            sensor_type: entry.sensor_type,
            value: entry.value,
            alert: entry.alert
          }))
        );

        combinedData.sort((a, b) => {
          const dateA = a.cycle_id.substring(1, 9);
          const dateB = b.cycle_id.substring(1, 9);
          const countA = parseInt(a.cycle_id.substring(9), 10);
          const countB = parseInt(b.cycle_id.substring(9), 10);
          return dateA !== dateB ? dateA.localeCompare(dateB) : countA - countB;
        }).sort((a, b) => new Date(b.datetime) - new Date(a.datetime));

        const filteredHistoryData = combinedData.filter(entry =>
          entry.cycle_id.toLowerCase().includes(searchHistoryQuery.toLowerCase()) ||
          entry.datetime.toLowerCase().includes(searchHistoryQuery.toLowerCase()) ||
          entry.action.toString().toLowerCase().includes(searchHistoryQuery.toLowerCase())
        );
  
        const slicedHistoryData = filteredHistoryData.slice((historyPage - 1) * rowsPerPage, historyPage * rowsPerPage);
  
        setHistoryTableData(slicedHistoryData);
        setHistoryCount(filteredHistoryData.length);
        setWebSocketAllData(filteredHistoryData);
      }
    }catch (error) {
      console.log('Error fetching data', error);
    } finally {
        setLoading(false);
    }
  };

  // Download History Logs
  const downloadHistoryLogs = async () => {
    const apiUrl = getApiUrl(true);
    try {
      axiosInstance.get(apiUrl, { responseType: 'arraybuffer' })
        .then(response => {
          if (response.data) {
            const blob = new Blob([response.data], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'mixer_room_sensor_data.zip';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          } else {
            console.error('Unexpected response format', response);
            setOpenError(true);
          }
        })
        .catch(error => {
          console.error('There was an error when downloading sensor data!', error);
          setOpenError(true);
        });
    } catch (error) {
      setOpenError(true);
      console.log('Error fetching data', error);
    }
  };

  // WEBSOCKET CALL
  useEffect(() => {
    if (!serialNumber) return;
  
    const connectDataWebSocket = async () => {
      const accessToken = await checkAndRefreshToken();
      const ws = new WebSocket(global.config.websocket_url + 'station/websocket/' + serialNumber + `/?token=${accessToken}` );
      dataWsRef.current = ws;

      ws.onopen = () => {
        console.log('WebSocket connected');
        setWebSocketConnected(true);
        clearInterval(retryIntervalData.current);
        retryIntervalData.current = null;
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        const {value, sensor_type, datetime, alert} = message
        const sensor = mixerSensors[sensor_type];
        if (sensor) {
          const action = `${sensor} ${value === 1 || value === 1.0 ? 'ON' : 'OFF'}`;
          const formattedDateTime = format(datetime, 'yyyy-MM-dd\'T\'HH:mm:ss', { timeZone: 'Asia/KualaLumpur' });

          const newData = {
            action: action,
            datetime: formattedDateTime,
            sensor_type: sensor_type,
            value: value,
            alert: alert,
          };

          setWebSocketAllData(data => {
            if (!data.some(entry => entry.action === newData.action && entry.datetime === newData.datetime)) {
              return [newData, ...data];
            } else {
              return data;
            }
          });
        }
      };

      ws.onerror = (event) => {
        console.error('WebSocket error:', event);
        setWebSocketConnected(false);
        if (!retryIntervalData.current) {
          retryIntervalData.current = setInterval(() => {
            if (!dataWsRef.current || dataWsRef.current.readyState === WebSocket.CLOSED) {
              connectDataWebSocket();
            }
          }, 5000); // Retry every 5 seconds
        }
      };

      ws.onclose = () => {
        console.log('WebSocket closed, attempting to reconnect...');
        setWebSocketConnected(false);
        if (!retryIntervalData.current) {
          retryIntervalData.current = setInterval(() => {
            if (!dataWsRef.current || dataWsRef.current.readyState === WebSocket.CLOSED) {
              connectDataWebSocket();
            }
          }, 5000); // Retry every 5 seconds
        }
      };
    };

    connectDataWebSocket();

  }, [serialNumber]);

 
  //Update default data and websocket data in dashboard
  useEffect(() => {
  
    const latestData = webSocketAllData.reduce((acc, data) => {
      if (!acc[data.sensor_type] || new Date(data.datetime) > new Date(acc[data.sensor_type].datetime)) {
        acc[data.sensor_type] = data;
      }
      return acc;
    }, {});
    Object.keys(latestData).forEach(sensor_type => {
      const { datetime, value, alert } = latestData[sensor_type];
      
   
      switch (sensor_type) {
        case 'IOT-S07': 
          setMixerState(value === 1 || value === 1.0);
          setMixerDateTimeState(datetime);
          if (value === 1 || value === 1.0) {
            setMixerStartDateTimeState(datetime);
            setMixerEndDateTimeState('');

          } else {
            setMixerEndDateTimeState(datetime);
            if(!mixerState && !metalDetectState){
              setAlertDetector(false);
            }
          }
          break;
        case 'IOT-S08':
          setMixerGateState(value === 1 || value === 1.0);
          setMixerGateDateTimeState(datetime);
          if (value === 1 || value === 1.0) {
            setMixerGateOpenDateTimeState(datetime);
          } 
          setAlertDoor(alert === true);
          break;
        case 'IOT-S09':
          setMetalDetectState(value === 1 || value === 1.0);
          setMetalDetectDateTimeState(datetime);
          if(mixerState && !metalDetectState) {
            setAlertDetector(true);
          }else{
            setAlertDetector(alert);
          }
          break;
        default:
          break;
      }
    });
  
    const today = new Date();
    const todayStr = today.toISOString().split('T')[0]; // Get YYYY-MM-DD format
    const websocketTodayData = webSocketAllData.filter(entry => entry.datetime.startsWith(todayStr));
    const slicedWebSocketData = websocketTodayData.slice((webSocketPage - 1) * rowsPerPage, webSocketPage * rowsPerPage);
  
    setWebSocketTableData(slicedWebSocketData);
    setWebSocketCount(websocketTodayData.length); // Update count based on full data length
  }, [webSocketPage, webSocketAllData, mixerState, metalDetectState]);
  

  useEffect(() => {
    if (!serialNumber) return;
    let timer;
    setTime({ hours: 0, minutes: 0, seconds: 0 });
    let currentTotalSeconds = 0;
    const min_cook_time = 20 * 60; // 20 minutes
  
    const initializeTimer = () => {
      const mixerStartTime = mixerDateTimeState;
      const currentTime = new Date();
      currentTotalSeconds = differenceInSeconds(currentTime, mixerStartTime);
      setTime({
        hours: Math.floor(currentTotalSeconds / 3600),
        minutes: Math.floor((currentTotalSeconds % 3600) / 60),
        seconds: currentTotalSeconds % 60
      });
    };
  
    const updateTimer = () => {
      
      setTime((prevTime) => {
        let { hours, minutes, seconds } = prevTime;
        seconds += 1;
        if (seconds === 60) {
          minutes += 1;
          seconds = 0;
        }
        if (minutes === 60) {
          hours += 1;
          minutes = 0;
        }
        currentTotalSeconds = hours * 3600 + minutes * 60 + seconds;
          const newProgress = (currentTotalSeconds / min_cook_time) * 100;
  
        setProgress(newProgress);
  
        // Update progress state based on currentTotalSeconds
        if (!mixerGateState) {
          setProgressState('RUNNING');
        } else {
            if (currentTotalSeconds < min_cook_time) {
            setProgressState('UNDERTIME');
          } else {
            setProgressState('RUNNING'); 
          }
        }
        return { hours, minutes, seconds };
      });
    };
  
    if (mixerState) {
      initializeTimer();
      timer = setInterval(updateTimer, 1000);
    } else {
      setProgress(0);
      setTime({ hours: 0, minutes: 0, seconds: 0 });
      setProgressState('REST'); 
    }
  
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [mixerState, mixerGateState, mixerDateTimeState]);
  
  const handleHistoryTableSearchChange = (event) => {
    setSearchHistoryQuery(event.target.value);
    setHistoryPage(1);
  };
  
  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    setSearchHistoryQuery('');

  };

  const handleHistoryPageChange = (event, value) => {
    setHistoryPage(value);
  };

  const handleHistoryTableFilterChange = (event) => {
    setHistoryFilter(event.target.value);
    setHistoryPage(1);
  };

  const handleWebsocketPageChange = (event, value) => {
    setWebSocketPage(value);
  };

  const getDateAndTime = (formattedDateTime) => {
    const date = format(formattedDateTime, 'dd MMMM yyyy'); // e.g., "02 July 2024"
    const time = format(formattedDateTime, 'hh:mm a');
    return { date, time };
  };

  return (
    loading ? 
    <ScreenLoader /> 
    : 
    <Box sx={{...CustomStyle.container}}>
      <Typography sx={{...CustomStyle.title}}>Mixing Room Monitor</Typography>
      {!webSocketConnected && (
          <Paper elevation={3} sx={{ p: 2, mb: 2, backgroundColor: red[100] }}>
            <Typography variant="body1" color={red[900]} fontWeight="bold" sx={{ display: 'flex', alignItems: 'center',fontSize:{xs:'25px' , lg:'auto'} }}>
              <WarningAmber sx={{ mr: 1,fontSize:{xs:'25px' , lg:'auto'} }} />
              WebSocket connection lost. Some features may be unavailable.
            </Typography>
          </Paper>
        )}
      <Box sx={{...CustomStyle.columnReverse, gap: 3, height:'auto'}}>
        <Paper elevation={0} sx={{...CustomStyle.bodyPadding, width: {xs: 1, lg: fullScreen? 1 : 0.55}}}>
          <Box sx={{...CustomStyle.columnRow, bgcolor:'', alignItems: 'center', justifyContent: 'space-between', height:{xs: null, lg:'4rem'}}}>
            <Box sx={{...CustomStyle.row, width: {xs: 1, lg: fullScreen? 0.45: 0.6}, mb: {xs: 4, lg: 0}}}>
              <Typography sx={{...CustomStyle.subtitle, justifyContent: 'center', mr: 3, mt: 0.5}}>{fullScreen ? 'History Logs' : 'Event Logs (Today)'}</Typography>
              <IconButton onClick={handleFullScreen}> 
                  <OpenInNew sx={{...CustomStyle.subtitleButton, color: blue['500']}}></OpenInNew>
              </IconButton>
            </Box>
            <Box sx={{ ...CustomStyle.columnRow, width: { xs: 1, lg: fullScreen ? 0.5 : 0.32 }, height: '2.75rem', gap: 2 }}>
              {fullScreen && (
                <>
                  <SearchBar onChange={handleHistoryTableSearchChange} placeholder="Search logs..." value={searchHistoryQuery} />
                  <BasicDropdown width={{ xs: 1, lg: fullScreen ? 0.25 : 1 }} selectedItem={historyFilter} onChange={handleHistoryTableFilterChange} items={logsHistoryFilter} />
                  <Button
                    variant='contained'
                    disableElevation
                    onClick={downloadHistoryLogs}
                    startIcon={<Download sx={{ ...CustomStyle.buttonIcon }} />}
                    sx={{ ...CustomStyle.containedButton, width: { xs: 1, lg: fullScreen ? 0.25 : 0.5 }, bgcolor: blue[600], fontSize: { xs: 36, lg: 16 }, p: 1 }}
                  >
                    Download
                  </Button>
                </>
              )}
            </Box>
          </Box>
          {fullScreen ? (
            <>
              <DashboardTable datas={historyTableData} isHistory={true}  />
              <Box sx={{ ...CustomStyle.row, justifyContent: 'center', marginTop:{xs:0,lg:3} }}>
                <CustomPagination totalPages={totalHistoryPages} page={historyPage} handlePageChange={handleHistoryPageChange} />
              </Box>
            </>
          ) : 
          <>
              <DashboardTable datas={webSocketTableData} />
              <Box sx={{ ...CustomStyle.row, justifyContent: 'center', mt: 3 }}>
                <CustomPagination totalPages={totalWebSocketPages} page={webSocketPage} handlePageChange={handleWebsocketPageChange} />
              </Box>
            </>
          }
          
        </Paper>
        <Box sx={{...CustomStyle.columnRow, width: {xs: 1, lg: 0.45},height:'auto', display:fullScreen?'none':'flex'}}>
          <Paper
            elevation={0}
            sx={{
              width: { xs: 1, lg: 0.6 },
              p: 2,
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: 'auto',
                height: 'auto',
                backgroundColor: 'rgba(0, 0, 0, 0.3)', 
                backdropFilter: 'blur(1px)', 
                zIndex: 1,
                pointerEvents: 'none', 
                opacity: webSocketConnected ? 0 : 1, 
                transition: 'opacity 0.3s ease-in-out', 
              },
              pointerEvents: webSocketConnected ? 'auto' : 'none', 
            }}
          >
            <Typography sx={{...CustomStyle.subtitleSmall}}>Timer</Typography>
            <Box sx={{...CustomStyle.columnCenter}}>
              <CircularProgressTimer progress={progress} time={time}/>
              <Box sx={{...CustomStyle.rowCentered}}>
                {alertDoor ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',mb:{xs:10,lg:5} }}>
                    {progressState === 'UNDERTIME' && (
                      <WarningAmber
                        sx={{
                          color: red['A400'],
                          transform: { xs: 'scale(5)', lg: 'scale(2)' },
                          mr: {xs:10,lg:3}, // margin-right to provide space between the icon and the text
                        }}
                      />
                    )}
                    <Typography
                      sx={{
                        ...CustomStyle.boxedText,
                        textAlign: 'center',
                        bgcolor: progressState === 'RUNNING' ? green[50] : red[50],
                        color: progressState === 'RUNNING' ? green[300] : red[300],
                      }}
                    >
                      {progressState}
                    </Typography>
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      ...CustomStyle.boxedText,
                      textAlign: 'center',
                      m: 6,
                      bgcolor: progressState === 'REST' ? red[50] : green[50],
                      color: progressState === 'REST' ? red[300] : green[300],
                    }}
                  >
                    {progressState === 'REST' ? 'REST' : 'RUNNING'}
                  </Typography>
                )}
                
              </Box>
              
              <Box sx={{justifyContent: 'space-between', width: 0.9}}>
                <DualText text1={'Start at'} text2={mixerStartDateTimeState ? getDateAndTime(mixerStartDateTimeState).time : '-'} fontSize1={{xs: 32, lg: 16}} fontSize2={{xs: 32, lg: 16}}/>
                <DualText text1={'Door Open'} text2={mixerGateOpenDateTimeState ? getDateAndTime(mixerGateOpenDateTimeState).time : '-'} fontSize1={{xs: 32, lg: 16}} fontSize2={{xs: 32, lg: 16}}/>
                <DualText text1={'Ended at'} text2={mixerEndDateTimeState ? getDateAndTime(mixerEndDateTimeState).time : '-'} fontSize1={{xs: 32, lg: 16}} fontSize2={{xs: 32, lg: 16}}/>
              </Box>
            </Box>
          </Paper>
          <Box sx={{...CustomStyle.column, width: {xs: 1, lg: 0.4}}}>
          <Paper
            elevation={0}
            sx={{
              position: 'relative',
              width: 1,
              borderRadius: { xs: 3, lg: 1 },
              height: 'auto',
              mb: 1,
              p: 2,
              '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: 'auto',
                height: 'auto',
                backgroundColor: 'rgba(0, 0, 0, 0.3)', 
                backdropFilter: 'blur(1px)', 
                zIndex: 1,
                pointerEvents: 'none',
                opacity: webSocketConnected ? 0 : 1,
              },
              pointerEvents: webSocketConnected ? 'none' : 'auto', 
            }}
          >
              <Typography sx={{...CustomStyle.subtitleSmall}}>Current Mixer State</Typography>
              
              {!isDesktopScreen ? (
                <Box sx={{ ...CustomStyle.column, pt: 1, width: 1, height: 'auto' }}>
                  <Box sx={{ ...CustomStyle.rowCentered, justifyContent: 'right', mt: 1, mr:10 }}>
                    <IOSSwitch sx={{...CustomStyle.iosSwitchBig}} checked={mixerState}/>
                  </Box>
                  <Box sx={{ ...CustomStyle.row, justifyContent: 'flex-start', alignItems: 'center' }}>
                    <DualText colorText1={grey[500]} colorText2={grey[500]}
                        text1={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: 2}}>{mixerDateTimeState? getDateAndTime(mixerDateTimeState).date:'-'}<CalendarMonthOutlined sx={{...CustomStyle.tableIcon, mr: 1}}/></Typography>} 
                        text2={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: {xs: 2, lg: 0}}}>{mixerDateTimeState? getDateAndTime(mixerDateTimeState).time:'-'}<Schedule sx={{...CustomStyle.tableIcon, mr: 1}}/></Typography>}
                    />
                  </Box>
                </Box>
              ):(
                <Box sx={{...CustomStyle.column, pt: 1, width: 1, height: 'auto'}}>
                  <Box sx={{...CustomStyle.rowCentered, height: 'auto'}}>
                      <IOSSwitch sx={{...CustomStyle.iosSwitchBig}} checked={mixerState}/>
                  </Box>
                  
                  <Box sx={{...CustomStyle.rowColumn, ...CustomStyle.maxSpaceBetween,pt:'auto'}}>
                    <Typography 
                    sx={{...CustomStyle.cardText}}>
                        Captured at
                    </Typography>
                    <DualText colorText1={grey[500]} colorText2={grey[500]}
                        text1={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: 2}}>{mixerDateTimeState? getDateAndTime(mixerDateTimeState).date:'-'}<CalendarMonthOutlined sx={{...CustomStyle.tableIcon, mr: '0.2rem', mt: '-0.1rem'}}/></Typography>} 
                        text2={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: {xs: 2, lg: 0}}}>{mixerDateTimeState? getDateAndTime(mixerDateTimeState).time:'-'}<Schedule sx={{...CustomStyle.tableIcon, mr: '0.2rem'}}/></Typography>}
                    />
                  </Box>
                </Box>
              )}
            </Paper>

            <Paper
                elevation={0}
                sx={{
                  width: 1,
                  borderRadius: { xs: 3, lg: 1 },
                  height: 'auto',
                  p: 2,
                  mb: 1,
                  border:alertDoor ? '3px solid red' : 'none',
                  position: 'relative',
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    top: 0, 
                    left: 0,
                    width: 'auto',
                    height: 'auto',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)', 
                    backdropFilter: 'blur(1px)', 
                    zIndex: 1,
                    pointerEvents: 'none', 
                    opacity: webSocketConnected ? 0 : 1, 
                  },
                  pointerEvents: webSocketConnected ? 'auto' : 'none', 
                }}
              >
              <Typography sx={{...CustomStyle.subtitleSmall}}>Mixer Gate</Typography>
              {!isDesktopScreen ? (
                <Box sx={{ ...CustomStyle.column, pt: 1, width: 1, height: 'auto' }}>
                  <Box sx={{ ...CustomStyle.rowCentered, justifyContent: 'right', mt: 1, mr:10 }}>
                    {alertDoor ? <WarningAmber sx={{mr: {xs: 15 , lg: 3}, mt: 3,color: red['A400'], transform: {xs: 'scale(4.2)', lg: 'scale(2)'}}}/> : null}
                    <IOSSwitch sx={{ ...CustomStyle.iosSwitchBig, ...CustomStyle.iosLockSwitch}} checked={mixerGateState} />
                  </Box>
                  <Box sx={{ ...CustomStyle.row, justifyContent: 'flex-start', alignItems: 'center' }}>
                    <DualText
                      colorText1={grey[500]}
                      colorText2={grey[500]}
                      text1={
                        <Typography sx={{ ...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: 2 }}>
                          {mixerGateDateTimeState ? getDateAndTime(mixerGateDateTimeState).date : '-'}
                          <CalendarMonthOutlined sx={{ ...CustomStyle.tableIcon, mr: 1 }} />
                        </Typography>
                      }
                      text2={
                        <Typography sx={{ ...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: { xs: 2, lg: 0 } }}>
                          {mixerGateDateTimeState ? getDateAndTime(mixerGateDateTimeState).time : '-'}
                          <Schedule sx={{ ...CustomStyle.tableIcon, mr: 1 }} />
                        </Typography>
                      }
                    />
                  </Box>
                </Box>
                
              ) : (
                <Box sx={{ ...CustomStyle.column, pt: 1, width: 1, height: 'auto' }}>
                  <Box sx={{ ...CustomStyle.rowCentered, height: 'auto' }}>
                    
                    <IOSSwitch sx={{ ...CustomStyle.iosSwitchBig, ...CustomStyle.iosLockSwitch }} checked={mixerGateState} />
                  </Box>
                  <Box sx={{ ...CustomStyle.rowColumn, ...CustomStyle.maxSpaceBetween, pt: 'auto' }}>
                    <Typography sx={{ ...CustomStyle.cardText }}>Captured at</Typography>
                    <DualText
                      colorText1={grey[500]}
                      colorText2={grey[500]}
                      text1={
                        <Typography sx={{ ...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: 2 }}>
                          {mixerGateDateTimeState ? getDateAndTime(mixerGateDateTimeState).date : '-'}
                          <CalendarMonthOutlined sx={{ ...CustomStyle.tableIcon, mr: '0.2rem', mt: '-0.1rem' }} />
                        </Typography>
                      }
                      text2={
                        <Typography sx={{ ...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: { xs: 2, lg: 0 } }}>
                          {mixerGateDateTimeState ? getDateAndTime(mixerGateDateTimeState).time : '-'}
                          <Schedule sx={{ ...CustomStyle.tableIcon, mr: '0.2rem' }} />
                        </Typography>
                      }
                    />
                  </Box>
                </Box>
              )}
              
            </Paper>

            <Paper
              elevation={0}
              sx={{
                width: 1,
                borderRadius: { xs: 3, lg: 1 },
                height: 'auto',
                p: 2,
                border: alertDetector ? '3px solid red' : 'none',
                position: 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: 'auto',
                  height: 'auto',
                  backgroundColor: 'rgba(0, 0, 0, 0.3)', 
                  backdropFilter: 'blur(1px)', 
                  zIndex: 1,
                  pointerEvents: 'none',
                  opacity: webSocketConnected ? 0 : 1, 
                },
                pointerEvents: webSocketConnected ? 'auto' : 'none', 
              }}
            >
              <Typography sx={{...CustomStyle.subtitleSmall}}>Current Metal Det. State</Typography>
              {!isDesktopScreen ? (
                <Box sx={{ ...CustomStyle.column, pt: 1, width: 1, height: 'auto' }}>
                  <Box sx={{ ...CustomStyle.rowCentered, justifyContent: 'right', mt: 1, mr:10 }}>
                    {alertDetector ? <WarningAmber sx={{mr: {xs: 15 , lg: 3}, mt: 3,color: red['A400'], transform: {xs: 'scale(4.2)', lg: 'scale(2)'}}}/> : null}
                    <IOSSwitch sx={{...CustomStyle.iosSwitchBig}} checked={metalDetectState}/>
                  </Box>
                  <Box sx={{ ...CustomStyle.row, justifyContent: 'flex-start', alignItems: 'center' }}>
                    <DualText colorText1={grey[500]} colorText2={grey[500]}
                        text1={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: 2}}>{metalDetectDateTimeState? getDateAndTime(metalDetectDateTimeState).date:'-'}<CalendarMonthOutlined sx={{...CustomStyle.tableIcon, mr: 1}}/></Typography>} 
                        text2={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: {xs: 2, lg: 0}}}>{metalDetectDateTimeState? getDateAndTime(metalDetectDateTimeState).time:'-'}<Schedule sx={{...CustomStyle.tableIcon, mr: 1}}/></Typography>}
                    />
                  </Box>
                </Box>
              ):(
                <Box sx={{...CustomStyle.column, pt: 1, width: 1, height: 'auto'}}>
                  <Box sx={{...CustomStyle.rowCentered, height: 'auto'}}>
                    {alertDetector ? <WarningAmber sx={{mr: {xs: 20 , lg: 3}, mt: 1,color: red['A400'], transform: {xs: 'scale(5)', lg: 'scale(2)'}}}/> : null}
                    <IOSSwitch sx={{...CustomStyle.iosSwitchBig}} checked={metalDetectState}/>
                  </Box>
                  
                  <Box sx={{...CustomStyle.rowColumn, ...CustomStyle.maxSpaceBetween,pt:'auto'}}>
                    <Typography 
                    sx={{...CustomStyle.cardText}}>
                        Captured at
                    </Typography>
                    <DualText colorText1={grey[500]} colorText2={grey[500]}
                        text1={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: 2}}>{metalDetectDateTimeState? getDateAndTime(metalDetectDateTimeState).date:'-'}<CalendarMonthOutlined sx={{...CustomStyle.tableIcon, mr: '0.2rem', mt: '-0.1rem'}}/></Typography>} 
                        text2={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: {xs: 2, lg: 0}}}>{metalDetectDateTimeState? getDateAndTime(metalDetectDateTimeState).time:'-'}<Schedule sx={{...CustomStyle.tableIcon, mr: '0.2rem'}}/></Typography>}
                    />
                  </Box>
                </Box>
              )}
              
            </Paper>
          </Box>
        </Box>
        
      </Box>
      {/* MODAL DIALOG */}
      <SuccessModal open={openSuccess} onClose={()=>setOpenSuccess(false)} sx={{...CustomStyle.modalSmall}}>
      </SuccessModal>

      <ErrorModal open={openError} onClose={()=>setOpenError(false)} sx={{...CustomStyle.modalSmall}}>
      </ErrorModal>
    </Box>
    
  )
}

export default MixingRoomDashboard