import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { clearJwtCookie, getIsAdmin } from './auth/auth';
import Swal from 'sweetalert2';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import WarningModal from './WarningModal';
import * as CustomStyle from '../components/CustomStyle'

const Sidebar = ({ isMenuOpen, setIsMenuOpen}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isExpanded, setIsExpanded] = useState(false);
    const [openWarning, setOpenWarning] = useState(false);
    const isAdmin = getIsAdmin();

    //handle sidebar toggle on mobile view
    useEffect(() => {
        const sidebar_expanded = document.querySelector('.expanded'); 
        if (sidebar_expanded){
            if(!isExpanded){
                sidebar_expanded.classList.remove('expanded');
                setIsMenuOpen(false);
            } 
        } 
    }, [location.pathname]);

    const handleSignOut = async (event) => {
        setOpenWarning(false);
        clearJwtCookie();

        Swal.fire({
            icon: 'success',
            title: 'Logged out',
            text: 'You have successfully logged out.',
            confirmButtonText: 'OK',
            confirmButtonColor: '#0088FF'
        }).then(() => {
            navigate('/login');
        });
    };

    const toggleSidebar = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`sidebar ${isExpanded ? 'expanded' : ''}`}>
            <div className="sidebar-header" onClick={toggleSidebar}>
                <img src={global.config.base_url + "logo/astramina_logo.png"} alt="Icon" className="sidebar-logo" />
                <span className="sidebar-logo-label">Seasonings Specialities</span>
                <hr className="rounded"></hr>
            </div>
            <hr className="mobile-hr"></hr>
            <div className="sidebar-ul">
                <ul>
                    <Link to="dashboard/ambient_temperature">
                        <li
                            className={location.pathname === "/dashboard/ambient_temperature" ? "active sidebar-li" : "sidebar-li"}
                            title={!isExpanded ? 'Ambient Temperature and Humidity' : ''}
                        >
                            <div className="sidebar-li-icon-container">
                                <span className="material-symbols-outlined left-icon">humidity_mid</span>
                                <span className="material-symbols-outlined right-icon">device_thermostat</span>
                            </div>
                            <div className="icon-label">Ambient Temperature and Humidity</div>
                        </li>
                    </Link>
                    <Link to="/animal_fat_cooker">
                        <li
                            className={location.pathname === "/animal_fat_cooker" ? "active sidebar-li" : "sidebar-li"}
                            title={!isExpanded ? 'Animal Fat Cooker' : ''}
                        >
                            <div className="sidebar-li-icon-container">
                                <span className="material-symbols-outlined sidebar-icon">multicooker</span>
                            </div>
                            <div className="icon-label">Animal Fat Cooker</div>
                        </li>
                    </Link>
                    <Link to="mixing_room">
                        <li
                            className={location.pathname === "/mixing_room" ? "active sidebar-li" : "sidebar-li"}
                            title={!isExpanded ? 'Mixing Room' : ''}
                        >
                            <div className="sidebar-li-icon-container">
                                <span className="material-symbols-outlined  sidebar-icon">oven</span>
                            </div>
                            <div className="icon-label">Mixing Room</div>
                        </li>
                    </Link>
                    <Link to="devices">
                        <li
                            className={location.pathname === "/devices" ? "active sidebar-li" : "sidebar-li"}
                            title={!isExpanded ? 'Devices' : ''}
                        >
                            <div className="sidebar-li-icon-container ">
                                <span className="material-symbols-outlined  sidebar-icon">devices</span>
                            </div>
                            <div className="icon-label">Devices</div>
                        </li>
                    </Link>
                    {isAdmin ? (<Link to="profile_list">
                        <li
                            className={location.pathname === "/profile_list" ? "active sidebar-li" : "sidebar-li"}
                            title={!isExpanded ? 'Account Profile' : ''}
                        >
                            <div className="sidebar-li-icon-container ">
                                <span className="material-symbols-outlined sidebar-icon">group</span>
                            </div>
                            <div className="icon-label">Account Profile</div>
                        </li>
                    </Link>):null }
                   
                    <Link to="notification">
                        <li
                            className={location.pathname === "/notification" ? "active sidebar-li" : "sidebar-li"}
                            title={!isExpanded ? 'Alert Notification' : ''}
                        >
                            <div className="sidebar-li-icon-container ">
                                <span className="material-symbols-outlined sidebar-icon">edit_notifications</span>
                            </div>
                            <div className="icon-label">Alert Notification</div>
                        </li>
                    </Link>
                </ul>
            </div>

            <div className="d-flex justify-content-center logout-position">
                <button className="logout-button" onClick={() => setOpenWarning(true)} title={!isExpanded ? 'Log out' : ''}>
                    <span className="material-symbols-outlined sidebar-li-icon">logout</span>
                    <div className="button-label">Log out</div>
                </button>
            </div>
            <WarningModal open={openWarning} onClick={handleSignOut} onClose={()=>setOpenWarning(false)} 
                sx={{ ...CustomStyle.modalMedium}}
                text={"Are you sure you want to log out?"} 
            />
        </div>
    );
};

export default Sidebar;
