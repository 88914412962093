import React from 'react';
import { Card, CardContent, CardHeader, Box, Typography } from '@mui/material';
import { Thermostat as ThermostatIcon, Opacity as OpacityIcon, AccessTime as AccessTimeIcon, CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import * as CustomStyle from '../../components/CustomStyle';
import { grey } from '@mui/material/colors';

const LocationCard = ({ location, onSelect, selected }) => {
    const formatDate = (datetime) => {
        const date = new Date(datetime);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; 
        const day = date.getDate();
        return `${year}-${month}-${day}`;
    };

    const formatTime = (datetime) => {
        const date = new Date(datetime);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    const checkIfLatestData = (datetime) => {
        // data is captured every 20 mins
        // if captured time is more than 21 mins ago, then it is not latest
        const date = new Date(datetime);
        const now = new Date();
        const diff = now - date;
        if (diff > 1260000) {
            return false;
        }
        return true;
    }

    return (
        <Card
            onClick={() => onSelect(location.code)}
            sx={{
                cursor: 'pointer',
                backgroundColor: selected ? '#333333' : 'white',
                color: selected ? 'white' : 'black'
            }}
        >
            <CardHeader
                title={location.name}
                titleTypographyProps={{ ...CustomStyle.subtitleSmall, align: 'left', fontWeight: '700', fontFamily: 'Segoe UI, sans-serif', color: selected ? 'white' : grey['A700'] }}
                sx={{ paddingBottom: 0 }}
            />
            <CardContent sx={{ paddingTop: 0, paddingBottom:0 }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                    <Box display="flex" alignItems="center" flexDirection="column">
                        <ThermostatIcon sx={{ fontSize: {xs: '3.5rem', lg: '2.5rem'}, color: selected? "white" : "#999999" }} />
                        <Typography variant="body2" ml={1} fontSize={{xs: '2rem', lg: '1.75rem'}} fontFamily="Segoe UI" fontWeight="700"
                            color={checkIfLatestData(location.module[0].last_sent) ? selected? 'white' : 'black' : 'red'}>
                            {location.module[0].latest_data['IOT-S01'].value}
                            <Typography component="span" variant="body2" fontSize={{xs: '1.75rem', lg: '1.25rem'}} sx={{ ml: 0.5 }} fontFamily="Segoe UI" fontWeight="700">
                                &deg;C
                            </Typography>
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" flexDirection="column">
                        <OpacityIcon sx={{ fontSize: {xs: '3.5rem', lg: '2.5rem'}, color: selected? "white" : "#999999" }} />
                        <Typography variant="body2" ml={1} fontSize={{xs: '2rem', lg: '1.75rem'}} fontFamily="Segoe UI" fontWeight="700"
                            color={checkIfLatestData(location.module[0].last_sent) ? selected? 'white' : 'black' : 'red'}>
                            {location.module[0].latest_data['IOT-S02'].value}
                            <Typography component="span" variant="body2" fontSize={{xs: '1.75rem', lg: '1.25rem'}} sx={{ ml: 0.5 }} fontFamily="Segoe UI" fontWeight="700">
                                %
                            </Typography>
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
                    <Typography variant="caption" fontWeight="700" fontFamily="Segoe UI" sx={{ ...CustomStyle.cardText }}>Captured at</Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                        <Box><CalendarMonthIcon sx={{ fontSize: {xs: '2.5rem', lg:'1.125rem'}, color: grey[500], mr: {xs: '0.1rem',lg: '0.2rem'}, mt: {xs: -1.5, lg: 0}, color: selected? 'white' : grey[500] }} /><Typography variant="caption" sx={{ ...CustomStyle.cardText, color: selected? 'white' : grey[500] }}>{formatDate(location.module[0].last_sent)}</Typography></Box>
                        <Box><AccessTimeIcon sx={{ fontSize: {xs: '2.5rem', lg:'1.125rem'}, color: grey[500], mr: {xs: '0.1rem',lg: '0.2rem'}, mt: {xs: -1, lg: 0}, color: selected? 'white' : grey[500] }} /><Typography variant="caption" sx={{ ...CustomStyle.cardText, color: selected? 'white' : grey[500] }}>{formatTime(location.module[0].last_sent)}</Typography></Box>
                    </Box>
                   
                </Box>
            </CardContent>
        </Card>
    );
};

export default LocationCard;
