import { Pagination } from '@mui/material'
import { red } from '@mui/material/colors'
import React from 'react'

const CustomPagination = ({totalPages, page, handlePageChange}) => {
  return (
    <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        sx={{
            pt: {xs: 4, lg: 0},
            pb: {xs: 4, lg: 0},
            transform: {xs: 'scale(2)', lg: 'scale(1)'},
            '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                    bgcolor: red[500],
                    color: 'white',
                    '&:hover': {
                        bgcolor: red[700], // Optional: Change color on hover
                    },
                },
            },
        }}
        showFirstButton
        showLastButton
    />
  )
}

export default CustomPagination