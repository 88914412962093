import { KeyboardArrowDown } from '@mui/icons-material'
import { Box, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'

const BasicDropdown = ({label, width, items, selectedItem, onChange, sx}) => {
  return (
    <Box sx={{ width: width }}>
        <FormControl sx={{ ...sx, width: 1, bgcolor: grey[100], height: 1 }}>
          <InputLabel sx={{fontSize: {xs: 36, lg: 16}}}>{label}</InputLabel>
          <Select
            value={selectedItem}
            input={<OutlinedInput label={label} sx={{fontSize: {xs: 36, lg: 16}, height: 1}}/>}
            onChange={onChange}
            IconComponent={(props)=><KeyboardArrowDown sx={{width: {xs: 0.1, lg: 0.3}, transform: {xs: 'scale(3)', lg: 'scale(1.5)'}, color: 'pink'}} {...props} />}
          >
            {items.map((name) => (
              <MenuItem key={name} value={name} sx={{fontSize: {xs: 36, lg: 16}}}>
                <ListItemText primary={name} primaryTypographyProps={{fontSize: {xs: 36, lg: 16}, color: grey[500], fontWeight: 'bold', ml: 1}} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
  )
}

export default BasicDropdown
