import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import * as CustomStyle from '../../components/CustomStyle'
import SearchBar from '../../components/searchbar'
import CheckboxDropdown from '../../components/CheckboxDropdown'
import { Settings } from '@mui/icons-material'
import { grey, red } from '@mui/material/colors'
import CustomPagination from '../../components/CustomPagination'
import useScreenType from 'react-screentype-hook'
import NotificationTable from './NotificationTable'
import axiosInstance from '../../components/auth/auth'
import ScreenLoader from '../../components/ScreenLoader'

const AllNotifications = ({settingsCallback, alertsSettings, isDesktop, isAdmin}) => {
    //data related
    const [notifications, setNotifications] = useState([]);
    const filter = alertsSettings.map((alert) => alert.name);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [search, setSearch] = useState('');

    //layout
    const [loading, setLoading] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [unresolvedCount, setUnresolvedCount] = useState(0);
    const totalPages = Math.ceil(count / rowsPerPage);

    //Stylings
    const statusButton = {...CustomStyle.containedButton, width: {xs: 0.7,  lg:0.1}, color: grey[800], borderColor: grey[400], fontWeight: 'bold',
    '&:hover': {bgcolor: red[400], color: 'white', borderColor: red[400]}};


    //Functions
    useEffect(() => {
        fetchNotifications();
    }, [page, rowsPerPage, selectedStatus, search, selectedFilter, unresolvedCount]);

    const fetchNotifications = async () => {
        const apiUrl = constructApiUrl(); // Use current state values here
        try{
            const response =  await axiosInstance.get(global.config.server_url + apiUrl);
            const data = response.data;
            setNotifications(data.results);
            setUnresolvedCount(data.results.filter(notification => notification.is_resolve === false).length);
            setCount(data.count);
        }catch(error){
            console.log('Error fetching data', error);
        }finally{
            setLoading(false);
        }
    }

    const constructApiUrl = () => {
        let apiUrl = `alert/api/log/list/?page=${page}&page_size=${rowsPerPage}`; // Use current state values here
        const is_resolved = selectedStatus === 'Resolved' ? true : false;
        if (selectedStatus !== 'All') apiUrl += `&is_resolve=${is_resolved}`;
        if (search) {
            apiUrl = `alert/api/log/list/?page=1&page_size=${rowsPerPage}`;
            setPage(1);
            apiUrl += `&search=${search}`;
        }
        
        if(selectedFilter.length > 0){
            //get alert settings id based on selected filter and create a list of ids
            //eg: [1,2,3]
            const alertIds = alertsSettings.filter((alert) => selectedFilter.includes(alert.name)).map((alert) => alert.id);
            apiUrl += `&alert_level=${alertIds.map((id) => id).join(',')}`
        }
        return apiUrl;
    }

    const onSelectFilter = async (e) => {
        const {
            target: { value },
        } = e;
        
        if(!value.includes(undefined)){
            setSelectedFilter(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );  
        }
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1); // Reset to first page when changing rows per page
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

        
    return (
        loading ? 
        <ScreenLoader /> 
        : 
        <Paper elevation={0} sx={{ ...CustomStyle.bodyPadding, width: 1, flex: 1 }}>
            <Box sx={{...CustomStyle.columnRow, gap: 4, mb: {xs: 5, lg: 3}, bgcolor: '', justifyContent: 'space-between', alignItems: {xs: null, lg: 'center'}}}>
                <Typography sx={{ ...CustomStyle.subtitle }}>All Notifications</Typography>
                <Box sx={{...CustomStyle.columnRow, gap: 3, width: {xs: 1, lg: 0.75}, bgcolor: '', justifyContent: 'end', height: {xs: null, lg: '2.75rem'}}}>
                    <SearchBar onClick={fetchNotifications} onChange={(e) => setSearch(e.target.value)}/>
                    <Box sx={{...CustomStyle.row, bgcolor: '', width: {xs: 1, lg: 0.25}, gap: {xs: 0, lg: 3}}}>
                        <CheckboxDropdown label='Alert Level' items={filter} selectedItems={selectedFilter} 
                            onClick={()=>setSelectedFilter([])} onChange={onSelectFilter} 
                            iconWidth={{xs: 0.15, lg: 0.3}} width={{xs: 0.85, lg: 1}} height={{xs: '6rem', lg: '2.75rem'}}
                        />
                        {isAdmin ? (
                            <Button variant='contained' disableElevation onClick={() => settingsCallback(true)}
                                sx={{ width: { xs: 0.15, lg: null } }}>
                                <Settings sx={{ ...CustomStyle.buttonIcon, mr: 0 }} />
                                </Button>) : null
                            }
                            
                    </Box>
                        
                </Box>
            </Box>
            
            <Box sx={{...CustomStyle.row, gap: 3, bgcolor: '', mb: 4}}>
                <Button  variant='outlined' disableElevation onClick={()=>setSelectedStatus('All')}
                    sx={{...statusButton, bgcolor: selectedStatus === 'All' ? red[400] : '', 
                        color: selectedStatus === 'All' ? 'white' : grey[800], 
                        borderColor: selectedStatus === 'All' ? red[400] : grey[400]}} >
                    All
                </Button>
                <Button  variant='outlined' disableElevation onClick={()=>setSelectedStatus('Ongoing')}
                    sx={{...statusButton, bgcolor: selectedStatus === 'Ongoing' ? red[400] : '', 
                        color: selectedStatus === 'Ongoing' ? 'white' : grey[800], 
                        borderColor: selectedStatus === 'Ongoing' ? red[400] : grey[400]}} >
                    Ongoing
                </Button>
                <Button  variant='outlined' disableElevation onClick={()=>setSelectedStatus('Resolved')}
                    sx={{...statusButton, bgcolor: selectedStatus === 'Resolved' ? red[400] : '', 
                        color: selectedStatus === 'Resolved' ? 'white' : grey[800], 
                        borderColor: selectedStatus === 'Resolved' ? red[400] : grey[400]}} >
                    Resolved
                </Button>
            </Box>

                {notifications.map(notification => (
                    <NotificationTable notification={notification} isDesktop={isDesktop} unresolvedCount={unresolvedCount} setUnresolvedCount={setUnresolvedCount} isAdmin={isAdmin} />
            ))}    

            <Box sx={{ ...CustomStyle.row, justifyContent: {xs:'center', lg:'space-between'}, mt: 5 }}>
                    {isDesktop? 
                    <FormControl variant="outlined" sx={{ position: 'relative', bottom: 0 }}>
                        <InputLabel>Show</InputLabel>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            label="Show"
                            sx={{ minWidth: 80, fontSize: '0.95rem', height: "2rem" }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                    : <></>}
                    <CustomPagination totalPages={totalPages} page={page} handlePageChange={handlePageChange}/>
                    <Box></Box>
                </Box>
        </Paper>
  )
}

export default AllNotifications