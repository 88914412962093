import React from 'react'
import { Box, Modal, Typography, Button } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { red } from '@mui/material/colors'

const ErrorModal = ({open, onClose, BackdropProps={style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }}, sx,  }) => {
    
  return (
    <Modal open={open} onClose={onClose} BackdropProps={{ ...BackdropProps }}>
      <Box sx={{ ...sx }}>
        <Cancel sx={{color: red['A400'], width: 0.5, height: 0.5, mb: 3}}/>
        <Typography sx={{color: red['A400'], fontFamily: 'Segoe UI', fontWeight: 'bold', fontSize: {xs: 52, lg: 26}}}>
          Something went wrong!
        </Typography>
        <Box sx={{display:'flex', flexDirection: "column", mt: {xs: 5, lg: 3}, ml: {xs: 15, lg: 10}, mr: {xs: 15, lg: 10}, bgcolor:red['A400'], borderRadius: 2}}>
          <Button onClick={onClose} 
            sx={{textTransform: 'none', color: 'white', fontFamily: 'Segoe UI', fontWeight: 'bold', fontSize: {xs: 42, lg: 16}}}>
              Try Again
          </Button>  
        </Box>
      </Box>
    </Modal>
  )
}

export default ErrorModal