import React, { useState } from 'react';
import AuthenticateContainer from './AuthenticateContainer';
import './ForgotPassword.css';
import loginAxiosInstance from '../auth/auth';
import { useNavigate } from 'react-router-dom';
const ForgotPassword = () => {
    const navigate = useNavigate(); 
    const [formData, setFormData] = useState({
        email: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const handleForgotPassword = async (event) => {
        event.preventDefault();
        try {
            console.log(formData);
            const response = await loginAxiosInstance.post(global.config.server_url + 'account/api/reset/password/', formData);
            navigate('/reset/password/sent/', { state: { email: formData.email } });
        } catch (error) {
            setErrorMessage(error.response.data.error);
            // Handle error response (e.g., display error message, redirect, etc.)
            console.error('Error occurred while logging in:', error);
        }
    };

    return (
        <AuthenticateContainer>
            <span className="text-center form-title">Forgot Password</span>
            <form className="auth-form" onSubmit={handleForgotPassword}>
                <div className="form-group pt-0">
                    <label className="w-100" htmlFor="username">Enter you email and we'll send you a link to reset your password</label>
                 
                </div>
                <div className="form-group">
                    <label className="w-100" htmlFor="email"> Email Address</label>
                    <input type="text" id="email" className="form-control" placeholder="myname@email.com" onChange={handleInputChange} required />
                </div>
                <div className="forgot-password-btn-container">
                    <div className="form-group"><button type="submit" className="btn btn-primary mt-4">Submit</button></div>
                    <div className="form-group pt-0"><span className="back-to-login-span" >Back to </span><a className="login-link" href="/login">Log In</a></div>
                </div>
                {errorMessage && <div className="error-message text-danger">{errorMessage}</div>}
            </form>
        </AuthenticateContainer>
    );
};

export default ForgotPassword;

