import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import './Base.css';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getAccessToken, getRefreshToken } from './auth/auth';
import { useLocation } from 'react-router-dom';

const Base = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    useEffect(() => {
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();

        if (!accessToken || !refreshToken) {
            navigate('/login');
        } else {
            if (location.pathname === "/") {
                navigate('/dashboard/ambient_temperature');
            }
        }
    }, [navigate]);

    return (
        <div className="base">
            <Sidebar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
            <div className="main-content">
                <Topbar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
                <div className="content">
                     <Outlet />    
                </div>
            </div>
        </div>
    );
};

export default Base;