import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, Paper, Avatar, Button, TextField, MenuItem } from '@mui/material'
import { blue, grey, red } from '@mui/material/colors'
import { DeleteOutline, PhotoCameraOutlined, SaveOutlined, Upload } from '@mui/icons-material'
import IOSSwitch from '../../components/IOSSwitch'
import DualRowColTextInput from '../../components/DualRowColTextInput'
import axiosInstanceWithFile from '../../components/auth/auth'
import axiosInstance from '../../components/auth/auth'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import VisuallyHiddenInput from '../../components/VisuallyHiddenInput'
import SuccessModal from '../../components/SuccessModal'
import ErrorModal from '../../components/ErrorModal'
import useScreenType from 'react-screentype-hook'
import * as CustomStyle from '../../components/CustomStyle'
import TextRow from '../../components/TextRow'
import LabeledTextInput from '../../components/LabeledTextInput'
import LabeledDropdown from '../../components/LabeledDropdown'
import ScreenLoader from '../../components/ScreenLoader'

const AccountForm = ({method}) => {
  const screenType = useScreenType();
  const isDesktopScreen = screenType.isDesktop || screenType.isLargeDesktop ? true : false;
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const userType = [
    {value: 'staff',
      label: 'Staff'}, 
    {value: 'admin',
      label: 'Admin'},
  ]
  
  const [profile, setProfile] = useState({
    first_name: '', 
    last_name: '', 
    email: '', 
    phone: '', 
    profile_picture: '',
    is_active: false,
    username: ''
  });

  const [profile_picture_url, setProfilePictureUrl] = useState(null);

  const [errorMessage, setErrorMessage] = useState({
    first_name: null, 
    last_name: null, 
    email: null, 
    phone: null
  })

  //move formatDate to a util file  later
  const formatDate = (datetime) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const day = date.getDate();
    return `${day}-${month < 10 ? `0${month}` : `${month}`}-${year}`
  }
  
  useEffect(() => {
    const fetchProfile = async () => {
      try{
          const response = await axiosInstance.get(global.config.server_url + `account/api/update/${id}/`)
          const data = await response.data
          data.date_joined = formatDate(data.date_joined)
          data.last_login = formatDate(data.last_login)
          setProfile(data)
          setProfilePictureUrl(data.profile_picture);
        }catch(error){
          console.log('Error fetching data', error)
        }finally{
          setLoading(false)
        }
    }

    if(method === 'edit') fetchProfile() 
    else setLoading(false);
    
  }, [])

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleOpenSuccess = () => {
    setOpenSuccess(true);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate('/profile_list')
    if(method=='edit') window.location.reload(false)
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const deleteAccount = async () => {
    try{
      const response = await axiosInstance.delete(global.config.server_url + `account/api/update/${id}/`)
      handleOpenSuccess()
      
    }catch(error){
      console.log("deleteAccount Error:", error)
      if(error.response.status!=400) handleOpenError()
    }
    
  }

  const createAccount = async () => {
    try{
      const response = await axiosInstanceWithFile.post(global.config.server_url + `account/api/create/`, convertToFormData(profile))
      handleOpenSuccess()
      
    }catch(error){
      console.log("createAccount Error:", error)
      setErrorMessage(error.response.data)
      if(error.response.status!=400) handleOpenError()
    }
    
  }

  const convertToFormData = (data, formData = new FormData(), parentKey = null) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            convertToFormData(
                data[key],
                formData,
                parentKey ? `${parentKey}[${key}]` : key
            );
        });
    } else {
        formData.append(parentKey, data);
    }
    return formData;
  };

  const updateAccount = async () => {
    try{
        if (!isFile(profile.profile_picture)) {
            if (profile.profile_picture!='') {
                delete profile.profile_picture
            }
        }
        const response = await axiosInstanceWithFile.patch(global.config.server_url + `account/api/update/${id}/`, convertToFormData(profile))
        handleOpenSuccess()
      
    }catch(error){
      console.log("updateAccount Error:", error)
      setErrorMessage(error.response.data)
      if(error.response.status!=400) handleOpenError()
    }
  }
    const isFile = (file) =>{
        return file instanceof File;
    }

  const uploadPhoto = async (e) => {
    const file = e.target.files[0]
    if(file) {
      setProfile({...profile, profile_picture: file})
        profile.profile_picture = file
        const objectUrl = URL.createObjectURL(file); // Create object URL for the new picture
        setProfilePictureUrl(objectUrl); 
      }
  }

  return (
    loading? <ScreenLoader /> :
    <>
    <Box sx={{...CustomStyle.container}}>
      <Typography sx={{...CustomStyle.title}}>
        {method} {method == 'create'? "New Account": "Account Profile"} 
      </Typography>
      <Box sx={{...CustomStyle.columnReverse}}>
        <Paper elevation={0} sx={{...CustomStyle.bodyPadding, width: {xs: 1, lg: '65%'}, flex: 1, mb: 10}}>
            {isDesktopScreen? <Typography sx={{...CustomStyle.subtitle}}>User Information</Typography> : null}
            <DualRowColTextInput mt={{xs:-4, lg: 3}} label1="First Name" label2="Last Name" 
              value1={profile?.first_name} value2={profile?.last_name}
              helperText1={errorMessage.first_name} helperText2={errorMessage.last_name}
              error1={errorMessage.first_name != null? true : false} error2={errorMessage.last_name != null? true : false}
              onChange1={(e) => setProfile({...profile, first_name: e.target.value})} 
              onChange2={(e) => setProfile({...profile, last_name: e.target.value})}
            />

            <DualRowColTextInput mt={{xs: 0, lg: 3}} label1="Email" label2="Contact Number" 
              value1={profile?.email} value2={profile?.phone}
              helperText1={errorMessage.email} helperText2={errorMessage.phone}
              error1={errorMessage.email != null? true : false}  error2={errorMessage.phone != null? true : false}
              onChange1={(e) => setProfile({...profile, email: e.target.value})} 
              onChange2={(e) => setProfile({...profile, phone: e.target.value})}
            />
            
            <Box sx={{...CustomStyle.columnRow, ...CustomStyle.maxSpaceBetween, mt:{xs: 4, lg: 3}}}>
              <LabeledDropdown label="Account Type" value={profile?.is_admin? "admin" : "staff"} dropdownItems={userType} sx={{width:{xs:1, lg: 0.475}}}
                onChange={(e) => setProfile({...profile, is_admin: e.target.value === 'admin' ? true : false})} 
              />
              {method == 'create'? 
              <Box sx={{...CustomStyle.column, mt:{xs: 2, lg: 0}}}>
                <Typography variant='h6' sx={{...CustomStyle.label}}>Activation</Typography>
                <IOSSwitch sx={{...CustomStyle.iosSwitch}} defaultChecked={profile?.is_active} 
                  onChange={(e) => setProfile({...profile, is_active: e.target.checked})}
                />
              </Box> : 
              <LabeledTextInput label='Date Joined' value={profile?.date_joined} mt={{xs: 2, lg: 0}}
                InputProps={{readOnly: true}}
              />
              }
              
            </Box>

            <Box sx={{...CustomStyle.columnRow, ...CustomStyle.maxSpaceBetween, mt:{xs: 0, lg: 3}}}>
              <Box sx={{...CustomStyle.column}} pb={{xs:2, lg:0}}>
                {/* first column */}
                {method == 'create' ? 
                  null : <LabeledTextInput label='Last Login' value={profile?.last_login} InputProps={{readOnly: true}} width={1}/>
                }
                
              </Box>
              {/* 2nd column */}
              {
                method == 'create' ? 
                null:
                <Box sx={{...CustomStyle.column}}>
                  <Typography variant='h6' sx={{...CustomStyle.label}}>Activation</Typography>
                  <IOSSwitch sx={{...CustomStyle.iosSwitch}} defaultChecked={profile?.is_active} onChange={(e) => 
                    setProfile({...profile, is_active: e.target.checked})}
                  />
                </Box>
              }
            </Box>
                
            <Box sx={{...CustomStyle.columnReverse, mt:{xs: 3, lg: 3}, justifyContent:'flex-end', gap: 0}}>
              {
                method=='create' ?
                <>
                  <Button variant='outlined' disableElevation 
                    sx={{...CustomStyle.outlinedButton,  mr: 2, color: grey[800], height: {xs: '6rem', lg: '3.25rem'},
                        width: {xs: 1, lg: 0.23}, p:1.25, mt: {xs: 2, lg: 0}, border: '1px solid', ':hover': {border: '1px solid'}}}  
                        onClick={(e) => {
                            navigate('/profile_list')
                    }}>
                    Cancel
                  </Button>
                  <Button variant='contained' disableElevation 
                    sx={{...CustomStyle.containedButton, width: {xs: 1, lg: 0.23}, p:1.25, bgcolor: blue[600], height: {xs: '6rem', lg: '3.25rem'}}}
                    startIcon={<SaveOutlined sx={{...CustomStyle.buttonIcon}}/>} onClick={createAccount}>
                    Save Account
                  </Button>
                </>
                :
                <>
                  <Button variant='outlined' disableElevation onClick={deleteAccount} 
                    startIcon={<DeleteOutline sx={{...CustomStyle.buttonIcon}}/>} 
                    sx={{...CustomStyle.outlinedButton, mr: 2, color: red[500], height: {xs: '6rem', lg: '3.25rem'},
                    width: {xs: 1, lg: 0.25}, p:1.25, mt: {xs: 2, lg: 0}}}>
                    Delete Account
                  </Button>
                  <Button variant='contained' disableElevation onClick={updateAccount} 
                    startIcon={<SaveOutlined sx={{...CustomStyle.buttonIcon}}/>} 
                    sx={{...CustomStyle.containedButton, width: {xs: 1, lg: 0.25}, p:1.25, bgcolor: blue[600], height: {xs: '6rem', lg: '3.25rem'}}}>
                    Update Account
                  </Button>
                </>
              }
            </Box>
        </Paper>
        <Paper elevation={0} sx={{p:3, width:{xs: 1, lg: '32.5%'}, mb: {xs: 1, lg: 10}}}>
          <Box>
            <Typography sx={{...CustomStyle.subtitle}}>{isDesktopScreen? 'Profile Picture' : "Account Information"}</Typography>
          </Box>
          <Box sx={{...CustomStyle.columnCenter}}>
            { method=='create' ? 
              <Avatar src={profile_picture_url} sx={{...CustomStyle.avatar}}>
                <PhotoCameraOutlined sx={{transform: 'scale(3)'}}/>
              </Avatar>
              :
              <>
              <Avatar src={profile_picture_url} sx={{...CustomStyle.avatar}}/>
              {isDesktopScreen?
              <TextRow text1="Joined since:" text2={profile?.date_joined}/>
              : 
              <></>
              } 
              </>
            }
            <Button variant="outlined" startIcon={<Upload sx={{ ...CustomStyle.buttonIcon }}/>} component="label" role={undefined} tabIndex={-1}
              sx={{...CustomStyle.outlinedButton, mt: method=='create' ? 10 : 4, color: blue[400]}}>
              Upload New Photo <VisuallyHiddenInput type="file" ref={fileInputRef} onChange={uploadPhoto}/>
            </Button>
            {
              method=='edit' ?
                <Button variant="outlined" startIcon={<DeleteOutline sx={{ ...CustomStyle.buttonIcon }}/>} 
                  sx={{...CustomStyle.outlinedButton, mt: 2, color: red[400]}} 
                  onClick={(e) => {
                      setProfile({ ...profile, profile_picture: '' }) 
                      setProfilePictureUrl('')
                  }}>
                  Delete Photo
                </Button>
              :
              <></>
            }
          </Box>
        </Paper>  
      </Box>
    </Box>

    {/* MODAL DIALOG */}
    <SuccessModal open={openSuccess} onClose={handleCloseSuccess} sx={{...CustomStyle.modalSmall}}>
    </SuccessModal>

    <ErrorModal open={openError} onClose={handleCloseError} sx={{...CustomStyle.modalSmall}}>
    </ErrorModal>
    
    
    </> 
  )
}

export default AccountForm