import React from 'react'
import * as CustomStyle from '../components/CustomStyle'
import { Box, Paper, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import DualText from './DualText'


const TimerCard = ({time, startTime, endTime, cycleId="A24001"}) => {
      
  return (
    <Paper elevation={0} sx={{width: {xs: 1, lg: 0.5}, borderRadius: {xs: 3, lg: 1}, height: 1, p: 2, pb: 0}}>
        <Typography sx={{...CustomStyle.subtitleSmall}}>Timer</Typography>
        <Box sx={{...CustomStyle.row, mt: 5, justifyContent: 'center'}}>
            <Box sx={{...CustomStyle.column, width: {xs: '7.5rem', lg: '3.125rem'}}}>
                <Box sx={{...CustomStyle.tableCenteredTextBox, fontSize: {xs: '5.625rem', lg:'1.875rem'}, pb: 0, pt: 0}}
                    color={'white'}
                    bgcolor={grey[800]} > 
                    {time.hours.toString().padStart(2, '0')}{/* hour */}
                </Box>    
                <Typography sx={{fontWeight: 'bold', fontSize: {xs: '1.75rem', lg: "0.75rem"}, color: grey[400], textAlign: 'center'}}>Hours</Typography>
            </Box>
            <Typography sx={{fontWeight: 'bold', fontSize: {xs: '5.625rem', lg:'1.875rem'}, pl: 0.5, pr: 0.5}}>:</Typography>
            <Box sx={{...CustomStyle.column, width: {xs: '7.5rem', lg: '3.125rem'}}}>
                <Box sx={{...CustomStyle.tableCenteredTextBox, fontSize: {xs: '5.625rem', lg:'1.875rem'}, pb: 0, pt: 0}}
                    color={'white'}
                    bgcolor={grey[800]} > 
                    {time.minutes.toString().padStart(2, '0')} {/* minute */}
                </Box>    
                <Typography sx={{fontWeight: 'bold', fontSize: {xs: '1.75rem', lg: "0.75rem"}, color: grey[400], textAlign: 'center'}}>Minutes</Typography>
            </Box>
            <Typography sx={{fontWeight: 'bold', fontSize: {xs: '5.625rem', lg:'1.875rem'}, pl: 0.5, pr: 0.5}}>:</Typography>
            <Box sx={{...CustomStyle.column, width: {xs: '7.5rem', lg: '3.125rem'}}}>
                <Box sx={{...CustomStyle.tableCenteredTextBox, fontSize: {xs: '5.625rem', lg:'1.875rem'}, pb: 0, pt: 0}}
                    color={'white'}
                    bgcolor={grey[800]} > 
                    {time.seconds.toString().padStart(2, '0')} {/* second */}
                </Box>    
                <Typography sx={{fontWeight: 'bold', fontSize: {xs: '1.75rem', lg: "0.75rem"}, color: grey[400], textAlign: 'center'}}>Seconds</Typography>
            </Box>
        </Box>
        <Box sx={{...CustomStyle.column, ...CustomStyle.maxSpaceBetween, mt: 5, mb: 3, pl: {xs: 3, lg: 0}, pr: {xs: 3, lg: 0}}}>
            <DualText text1={'Cycle ID'} text2={cycleId} text1sx={{...CustomStyle.cardText, color: 'black'}} text2sx={{...CustomStyle.cardText, color: grey[500]}} sx={{mb: 1}}/>
            <DualText text1={'Start at'} text2={startTime} text1sx={{...CustomStyle.cardText, color: 'black'}} text2sx={{...CustomStyle.cardText, color: grey[500]}} sx={{mb: 1}}/>
            <DualText text1={'Ended at'} text2={endTime} text1sx={{...CustomStyle.cardText, color: 'black'}} text2sx={{...CustomStyle.cardText, color: grey[500]}} sx={{mb: 1}}/>
        </Box>
    </Paper>
  )
}

export default TimerCard