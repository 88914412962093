import React from 'react'
import { Box, Typography, TextField } from '@mui/material'
import { grey } from '@mui/material/colors'
import LabeledTextInput from './LabeledTextInput'

const DualRowColTextInput = ({label1, label2, value1, value2, mt, onChange1, onChange2, 
  required1, required2, helperText1, helperText2, error1, error2,
  fontSize1, fontSize2, bgcolor1, bgcolor2}) => {
  return (
    <>
    <Box display='flex' flexDirection={{xs: 'column', lg: 'row'}} sx={{mt:mt, width: 1, justifyContent:'space-between'}}>
        <LabeledTextInput label={label1} value={value1} onChange={onChange1} 
          required={required1} fontSize={fontSize1} bgcolor={bgcolor1}
          helperText={helperText1} error={error1} 
        />
        <LabeledTextInput label={label2} value={value2} onChange={onChange2} 
          required={required2} fontSize={fontSize2} bgcolor={bgcolor2}
          helperText={helperText2} error={error2} 
        />
    </Box>
    </>
  )
}

export default DualRowColTextInput