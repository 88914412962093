import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import * as CustomStyle from '../../components/CustomStyle'
import AllNotifications from './AllNotifications'
import NotificationAdmin from './NotificationAdmin'
import axiosInstance from '../../components/auth/auth'
import useScreenType from 'react-screentype-hook'
import { getIsAdmin } from '../../components/auth/auth';

const AlertNotification = () => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [alertsSettings, setAlertsSettings] = useState([])
  const screenType = useScreenType();
  const isDesktopScreen = screenType.isDesktop || screenType.isLargeDesktop ? true : false;
   const isAdmin = getIsAdmin();
  useEffect(() => {
    fetchAlertSettings();
}, []);

  const fetchAlertSettings = async () => {
    const apiUrl = "alert/api/level/list/"; 
    try{
        const response = await axiosInstance.get(global.config.server_url + apiUrl);
        const data = response.data;
        setAlertsSettings(data);
    }catch(error){
        console.log('Error fetching settings data', error);
    }
  }

  return (
    <Box sx={{...CustomStyle.container}}>
        <Typography sx={{...CustomStyle.title}}>Alert Notification</Typography>
          {!isSettingsOpen ?
              <AllNotifications isDesktop={isDesktopScreen} settingsCallback={setIsSettingsOpen} alertsSettings={alertsSettings} isAdmin={isAdmin} />
        :
            <NotificationAdmin isDesktop={isDesktopScreen} settingsCallback={setIsSettingsOpen} alertsSettings={alertsSettings} setAlertsSettings={setAlertsSettings}/>
        }

    </Box>
  )
}

export default AlertNotification