import React from 'react'
import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

const DualText = ({
  text1, text1sx, text2, text2sx, colorText1={xs: grey[500], lg: 'black'}, colorText2={xs:'black', lg:grey[500]}, 
  fontWeight1='bold', fontWeight2='bold', fontSize1={xs: '2.1rem', lg: '0.9rem'}, fontSize2={xs: '2rem', lg: '0.9rem'},
  bgColor1, bgColor2, width1, width2, textAlign1, textAlign2, textTransform='none', justifyContent='space-between',
  sx={}
}) => {
  return (
    <Box display='flex' flexDirection='row' justifyContent={justifyContent} sx={{mb:{xs: 4, lg:2}, ...sx}}>
        <Typography textTransform={textTransform} variant='h6' color={colorText1} fontSize={fontSize1}
          fontWeight={fontWeight1} bgColor={bgColor1} width={width1} textAlign={textAlign1} fontFamily={'Segoe UI'} sx={{...text1sx}}>
            {text1}
        </Typography>
        <Typography variant='h6' textTransform={textTransform} color={colorText2} fontSize={fontSize2}
         fontWeight={fontWeight2} bgcolor={bgColor2} width={width2} textAlign={textAlign2} fontFamily={'Segoe UI'} sx={{...text2sx}}>
          {text2}
        </Typography>
    </Box>
  )
}

export default DualText