import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthenticateContainer from './AuthenticateContainer';
import './Login.css';
import './ConfirmPassword.css';
import loginAxiosInstance from '../auth/auth';

const ConfirmPassword = () => {
    const { uid, token } = useParams();
    const navigate = useNavigate();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    const [formData, setFormData] = useState({
        password: '',
        confirm_password: ''
    });

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    useEffect(() => {
        if (formData.confirm_password !== '' && formData.password !== formData.confirm_password) {
            setErrorMessage('Passwords do not match.');
        } else {
            setErrorMessage('');
        }
    }, [formData]);

    const handleConfirmPassword = async (event) => {
        event.preventDefault();

        if (formData.password !== formData.confirm_password) {
            setErrorMessage('Passwords do not match.');
            return;
        }

        try {
            const response = await loginAxiosInstance.post(global.config.server_url + 'account/api/confirm/password/', {
                uid,
                token,
                ...formData
            });
            const tokenResponse = await response.data;
            console.log('Password reset successful');
            navigate('/reset/password/success');
        } catch (error) {
            setErrorMessage(error.response.data.error);
            console.error('Error occurred while resetting the password:', error);
        }
    };

    return (
        <AuthenticateContainer>
            <span className="text-center form-title">Reset Password</span>
            <form className="auth-form" onSubmit={handleConfirmPassword}>
                <div className="form-group">
                    <label className="w-100" htmlFor="password">New Password</label>
                    <div className="password-group">
                        <input
                            type={passwordVisible ? "text" : "password"}
                            id="password"
                            className="form-control"
                            placeholder="Enter password"
                            onChange={handleInputChange}
                            value={formData.password}
                            required
                        />
                        <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                            {passwordVisible ? (
                                <span className="material-symbols-rounded">
                                    visibility_off
                                </span>
                            ) : (
                                <span className="material-symbols-rounded">
                                    visibility
                                </span>
                            )}
                        </span>
                    </div>
                </div>
                <div className="form-group">
                    <label className="w-100" htmlFor="confirm_password">Confirm Password</label>
                    <div className="password-group">
                        <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            id="confirm_password"
                            className="form-control"
                            placeholder="Enter password"
                            onChange={handleInputChange}
                            value={formData.confirm_password}
                            required
                        />
                        <span onClick={toggleConfirmPasswordVisibility} className="password-toggle-icon">
                            {confirmPasswordVisible ? (
                                <span className="material-symbols-rounded">
                                    visibility_off
                                </span>
                            ) : (
                                <span className="material-symbols-rounded">
                                    visibility
                                </span>
                            )}
                        </span>
                    </div>
                </div>

                <div className="form-group mt-5">
                    <button type="submit" className="btn btn-primary" disabled={formData.password !== formData.confirm_password || !formData.password || !formData.confirm_password}>Reset Password</button>
                </div>
                {errorMessage && <div className="error-message text-danger">{errorMessage}</div>}
            </form>
        </AuthenticateContainer>
    );
};

export default ConfirmPassword;
