import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import {grey} from '@mui/material/colors'

export default function SearchBar({width={ xs: 1, lg: 0.5 }, onChange, onClick, sx}) {
  
  return (
    <Paper component="form" elevation={0} onSubmit={e => e.preventDefault()}
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: width, bgcolor: grey[100], ...sx }}>
        <IconButton type="button" aria-label="search"
          sx={{ p: {xs: '1.875rem', lg: '0.625rem'}, transform: {xs: 'scale(3)', lg: 'scale(1)'} }}
          onClick={onClick}>
            <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 3, flex: 1, fontSize: {xs: '2.5rem', lg: '1rem'}, fontWeight: 'bold', fontFamily: 'Segoe UI' }}
          placeholder="Searching"
          inputProps={{ 'aria-label': 'search account' }}
          onChange={onChange}
        />
    </Paper>
  );
}
