import axios from "axios";
import universalCookie from "universal-cookie";
import { useNavigate } from 'react-router-dom';
axios.defaults.withCredentials = true

const cookie = new universalCookie('/');

export function setJwtCookie(token) {
    cookie.set("access_token", token.access, {
        httpOnly: false,
        secure: false,
        path: '/' 
    });
    cookie.set("refresh_token", token.refresh, {
        httpOnly: false,
        secure: false,
        path: '/' 
    });
    cookie.set("is_admin", token.is_admin, {
        httpOnly: false,
        secure: false,
        path: '/'
    });
    if (token.expiration) {
        const [hours, minutes, seconds] = token.expiration.split(":");
        const totalSeconds = parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
        const expirationTime = new Date(Date.now() + totalSeconds * 1000);

        cookie.set("access_token_expiration_time", expirationTime, {
            httpOnly: false,
            secure: false,
            path: '/' 
        });
    }
}

export function clearJwtCookie() {
    try {
        cookie.remove("access_token", { path: '/' });
        cookie.remove("refresh_token", { path: '/' });
        cookie.remove("access_token_expiration_time", { path: '/' });
        cookie.remove("is_admin", { path: '/' });
    }catch(error) {
        console.error(error);
    }
    
}

export function getAccessToken() {
    return cookie.get("access_token", { path: '/' });
}

export function getRefreshToken() {
    return cookie.get("refresh_token", { path: '/' });
}

export function getExpirationTime() {
    return cookie.get("access_token_expiration_time", { path: '/' });
}

export function setAccessToken(token) {
    cookie.set("access_token", token, {
        httpOnly: false,
        secure: true,
    });
}

export function getIsAdmin() {
    return cookie.get("is_admin", { path: '/' });
}

export const checkAndRefreshToken = async () => {
    const access_token = getAccessToken();
    const expirationTime = getExpirationTime();
    const refresh_token = getRefreshToken();

    if (access_token && new Date(Date.now()) > new Date(expirationTime)) {
      const response = await fetch(global.config.server_url + `account/api/token/refresh/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh: refresh_token,
        }),
      });

       if (response.ok) {
        clearJwtCookie();
        const token = await response.json();
        setJwtCookie(token);
        axiosInstance.defaults.headers['Authorization'] = `Bearer ${token.access}`;
        return token.access;
      } else {
        clearJwtCookie();
        throw new Error('Failed to refresh token');
      }
    }
    return access_token;
  };
  
const loginAxiosInstance = axios.create({
    withCredentials: false,
});

const axiosInstance = axios.create({
    headers: {
        Authorization: `Bearer ${getAccessToken()}`,
    },
});

axiosInstance.interceptors.request.use(
    async (config) => {
        try {
            const access_token = getAccessToken();
            const expirationTime = getExpirationTime();
            const refresh_token = getRefreshToken();

            if (access_token && new Date(Date.now()) > new Date(expirationTime)) {
                const response = await fetch(global.config.server_url + `account/api/token/refresh/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        refresh: refresh_token,
                    }),
                });
                clearJwtCookie();
                const token = await response.json();
                setJwtCookie(token);
                config.headers.Authorization = `Bearer ${token.access}`;
                axiosInstance.defaults.headers['Authorization'] = `Bearer ${token.access}`;
            }

            return config;
        } catch (error) {
            clearJwtCookie();
            console.log(error.message);
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        // If the response is successful, just return it
        return response;
    },
    (error) => {
        
        if (error.response) {
            const { status, data } = error.response;
            if (status != 401 && data.hasOwnProperty('detail')) {
                let redirectUrl = `/error?status=${status}`;

                // Only add the message parameter if it exists in the response
                if (data && data.detail) {
                    redirectUrl += `&message=${encodeURIComponent(data.detail)}`;
                }

                window.location.href = redirectUrl;

            }
           
        } else {
            // If there's no response, it's likely a network error
            window.location.href = '/error?status=500';
        }

        return Promise.reject(error);
    }
);

const axiosInstanceWithFile = axios.create({
    headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'multipart/form-data'
    },
});

axiosInstanceWithFile.interceptors.request.use(
    async (config) => {
        try {
            const access_token = getAccessToken();
            const expirationTime = getExpirationTime();
            const refresh_token = getRefreshToken();

            if (access_token && new Date(Date.now()) > new Date(expirationTime)) {
                const response = await fetch(global.config.server_url + `account/api/token/refresh/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        refresh: refresh_token,
                    }),
                });
                clearJwtCookie();
                const token = await response.json();
                setJwtCookie(token);
                config.headers.Authorization = `Bearer ${token.access}`;
            }

            return config;
        } catch (error) {
            clearJwtCookie();
            console.log(error.message);
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
