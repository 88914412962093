import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Box, Button } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { FilterList } from '@mui/icons-material';

export default function MultipleSelectCheckmarks({
    items, onChange, selectedItems, onClick, height={xs: "6rem", lg: "2.5rem"},
    width={xs: 0.4, lg: 0.2}, m=0, label="Filter By", buttonText="Apply", iconWidth=0.3
}) {

  const onCheckboxChange = (name) => {
    if(name === 'Select All') {
      onChange({ target: { value: selectedItems.includes('Select All') ? [] : items } });
    }
    else if(!selectedItems.includes(name)) {
      onChange({ target: { value: [...selectedItems, name] } });
    }
    else{
      if(selectedItems.includes('Select All') && selectedItems.includes(name)) {
        if(selectedItems !== items) {
          //remove select all from selectedItems
          onChange({ target: { value: selectedItems.filter(item => item !== 'Select All' && item !== name) } });
        }
      }  
    }
  };

  return (
    <Box sx={{width: width}}>
      <FormControl sx={{ m: m, width: {xs: 0.9, lg: 1}, bgcolor: grey[100] }}>
        <InputLabel id="demo-multiple-checkbox-label" sx={{fontFamily: 'Segoe UI', fontSize: {xs: '2.25rem', lg: '1rem'}, mt: {xs: 0.5, lg: -1}}}>{label}</InputLabel>
        <Select
          multiple
          value={selectedItems}
          onChange={onChange}
          input={<OutlinedInput label={label} sx={{fontFamily: 'Segoe UI', fontSize: {xs: '2rem', lg: '1rem'}, height: height}}/>}
          renderValue={(selected) => selected.join(', ')}
          IconComponent={(props)=><FilterList sx={{width: iconWidth, fontFamily: 'Segoe UI', fontSize: {xs: '3.25rem', lg: '1.5rem'}}} {...props} />} // Adjust the margin here
          // decrease the spacing between iconComponent and label 
        >
          {items.map((name) => (
            <MenuItem key={name} value={name} sx={{fontFamily: 'Segoe UI', fontSize: {xs: '2rem', lg: '1rem'}}}>
              <ListItemText primary={name} primaryTypographyProps={{fontFamily: 'Segoe UI', fontSize: {xs: '1.75rem', lg: '1rem'}}} />
              <Checkbox onChange={(e) => onCheckboxChange(name)} checked={selectedItems.indexOf(name) > -1} sx={{transform: {xs: 'scale(1.5)', lg: 'scale(1)'}}}/>
            </MenuItem>
          ))}
          {/* <Button variant='contained' onClick={onClick} sx={{width:0.9, ml: 1.5}}>{buttonText}</Button> */}
          <Box sx={{width: 1, bgcolor: '', justifyContent: 'center', pt: {xs: 2, lg: 1}, pl: {xs: 2, lg: 1}, pr: {xs: 2, lg: 1}, pb: {xs: 1, lg: 0}}}>
            <Button variant='contained' onClick={onClick} sx={{width:1, bgcolor: red['A400'], fontSize: {xs: '1.25rem', lg: '1rem'}, ':hover': {bgcolor: red['A700']}}}>Clear</Button>  
          </Box>
          
        </Select>
      </FormControl>
    </Box>
  );
}
