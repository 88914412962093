import React from 'react';
import Base from './components/Base';
import Login from './components/auth/Login';
import ForgotPassword from './components/auth/ForgotPassword';
import ConfirmPassword from './components/auth/ConfirmPassword';
import ResetPasswordSuccess from './components/auth/ResetPasswordSuccess';
import ForgotPasswordEmailSent from './components/auth/ForgotPasswordEmailSent';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Devices from './components/devices/Devices';
import ProfileAccount from './pages/Account/ProfileAccount';
import ProfileList from './pages/Account/ProfileList';
import AccountForm from './pages/Account/AccountForm';
import AmbientTemperature from './pages/AmbientDashboard/TempHumDashboard';
import MixingRoomDashboard from './pages/mixing_room/MixingRoomDashboard';
import AnimalFatCookerDashboard from './pages/animal_fat_cooker/AnimalFatCookerDashboard';
import AlertNotification from './pages/AlertNotification/AlertNotification';
import ErrorHandlerPage from './pages/ErrorPage/ErrorHandlerPage';
function App() {

    return (
        <Router>
           
            <Routes>
                <Route path="/" element={<Base />}>
                    <Route path="devices" element={<Devices />} />
                    <Route path="profile" element={<ProfileAccount />} />
                    <Route path="profile_list" element={<ProfileList />} />
                    <Route path="create_account" element={<AccountForm method='create'/>} />
                    <Route path="edit_account/:id" element={<AccountForm method='edit' />} />
                    <Route path="dashboard/ambient_temperature" element={<AmbientTemperature />} />
                    <Route path="mixing_room" element={<MixingRoomDashboard />} />
                    <Route path="animal_fat_cooker" element={<AnimalFatCookerDashboard />} />
                    <Route path="notification" element={<AlertNotification />} />
                </Route>
                <Route path="/login/" element={<Login />} />
                <Route path="/reset/password/" element={<ForgotPassword />} />
                <Route path="/reset/password/sent/" element={<ForgotPasswordEmailSent />} />
                <Route path="/confirm/password/:uid/:token/" element={<ConfirmPassword />} />
                <Route path="/reset/password/success/" element={<ResetPasswordSuccess />} />
                <Route path="/error/" element={<ErrorHandlerPage />} />
                <Route path="*" element={<ErrorHandlerPage />} />
                </Routes>
        </Router>
        
    );
}

export default App;
