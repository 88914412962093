import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, IconButton, Box, Typography, Menu } from '@mui/material';
import { EditOutlined, DeleteOutline, MoreVert } from '@mui/icons-material';
import { blue, green, red, deepOrange, grey } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axiosInstance from '../../components/auth/auth';
import DeleteModal from '../../components/DeleteModal';
import DualText from '../../components/DualText'
import * as CustomStyle from '../../components/CustomStyle'

export default function AccountTable({profiles, isDesktop}) {
  const [deleteID, setDeleteID] = useState(false);
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [hiddenID, setHiddenID] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [selectedProfileID, setSelectedProfileID] = useState(null);

  //move formatDate to a util file  later
  const formatDate = (datetime) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const day = date.getDate();
    return `${day}-${month < 10 ? `0${month}` : `${month}`}-${year}`
  }

  const handleMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedProfileID(id);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = (id) => {
    setOpen(true);
    setDeleteID(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleHidden = (id) => {
    setHidden(!hidden);
    if(hiddenID.includes(id)){
      setHiddenID(hiddenID.filter(item => item !== id));
    }else{
      hiddenID.push(id);
      setHiddenID(hiddenID);
    }
  };

  const deleteAccount = async () => {
    try{
      await axiosInstance.delete(global.config.server_url + `account/api/update/${deleteID}/`)
    }catch(error){
      console.log("deleteAccount Error:", error)
    }
    
    handleClose()
    window.location.reload(false)
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {
            isDesktop? 
            <>
            <TableCell sx={{...CustomStyle.tableHeader}}>No</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>Avatar</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>First</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>Last</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>Email</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>Phone Number</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>Date Joined</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>Type</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>Status</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>Action</TableCell>
            </>
            :
            <>
            <TableCell sx={{...CustomStyle.tableHeader}}>Name</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>Type</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>Status</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}></TableCell>
            </>
            }
            
          </TableRow>
        </TableHead>
        <TableBody>
          {profiles.map((profile, i) => (
            <>
            <TableRow onClick={()=>handleHidden(profile.id)}>
              {isDesktop?
              <>
              <TableCell sx={{...CustomStyle.tableText}}>{i+1}.</TableCell>
              <TableCell> <Avatar src={profile.profile_picture}/> </TableCell>
              <TableCell sx={{...CustomStyle.tableText}}>{profile.first_name}</TableCell>
              <TableCell sx={{...CustomStyle.tableText}}>{profile.last_name}</TableCell>
              <TableCell sx={{...CustomStyle.tableText, color: grey['600']}}>{profile.email}</TableCell>
              <TableCell sx={{...CustomStyle.tableText, color: grey['600']}}>{profile.phone?? 'N/A'}</TableCell>
              <TableCell sx={{...CustomStyle.tableText, color: grey['600']}}>{formatDate(profile.date_joined)}</TableCell>
              </>
              :
              <>
              <TableCell> 
                  <Box sx={{...CustomStyle.row}}>
                    <Avatar src={profile.profile_picture} sx={{width: '5rem', height: '5rem'}}/>
                    <Box sx={{display:'flex', flexDirection: "column", pl: 2}}>
                      <Typography sx={{...CustomStyle.tableText}}> {profile.first_name}  </Typography> 
                      <Typography sx={{...CustomStyle.tableText, color: grey['500']}}> {profile.last_name}  </Typography> 
                    </Box>
                  </Box>
                </TableCell>
              </>
              }
                <TableCell>
                    <Box sx={{...CustomStyle.tableTextBox}}
                      bgcolor={profile.is_admin ? green['A100'] : deepOrange['200']} > 
                        {profile.is_admin ? 'Admin' : 'Staff'}
                    </Box>
                </TableCell>
                <TableCell>
                    <Box sx={{...CustomStyle.tableTextBox}}
                      color={profile.is_active ? green[300] : red[300]}  
                      bgcolor={profile.is_active ? green[50] : red[50]} > 
                        {profile.is_active ? 'Active' : 'Inactive'}
                    </Box> 
                </TableCell>
                {isDesktop? 
                <TableCell>
                  <Box sx={{...CustomStyle.row, justifyContent:'space-between', width: 0.7}}>
                      <IconButton component={Link} to={`/edit_account/${profile.id}`} > 
                        <EditOutlined sx={{color: blue[400]}}/> 
                      </IconButton>
                      <IconButton onClick={()=>handleOpen(profile.id)}> 
                        <DeleteOutline sx={{color: red[400]}}/> 
                      </IconButton>
                    </Box>
                </TableCell>
                : 
                <TableCell width={0.5}>
                  <IconButton onClick={(e)=>handleMenu(e, profile.id)} size='large'>
                    <MoreVert transform='scale(2)'></MoreVert>
                  </IconButton>
                  <Menu elevation={0} anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu} transformOrigin={{horizontal: 'right'}} PaperProps={{style: {maxHeight: 100, width: '25ch',}}}>
                    <Box sx={{...CustomStyle.row, borderRadius: 2, width: 1, height:75, justifyContent:'space-around', bgcolor: grey[50]}}>
                      <IconButton component={Link} to={`/edit_account/${selectedProfileID}`} > 
                        <EditOutlined sx={{color: blue[400], transform: 'scale(2.25)'}}/> 
                      </IconButton>
                      <IconButton onClick={()=>handleOpen(selectedProfileID)}> 
                        <DeleteOutline sx={{color: red[400], transform: 'scale(2.25)'}}/> 
                      </IconButton>
                    </Box>
                  </Menu>
                </TableCell>
                }
                <DeleteModal open={open} onClick={deleteAccount} onClose={handleClose} 
                  sx={{ ...CustomStyle.modalMedium}}
                  text={"Are you sure you want to delete this user account?"} 
                  buttonText={"Delete Account"}
                />
            </TableRow>
            {isDesktop?
            <></>
            :
            <TableRow hidden={!hiddenID.includes(profile.id)? true:false}>
              <TableCell colSpan={4}>
                <DualText text1="Email" text2={profile.email} sx={{pr: 2, mb: 1.5}}/>
                <DualText text1="Phone Number" text2={profile.phone} sx={{pr: 2, mb: 1.5}}/>
                <DualText text1="Date Joined" text2={formatDate(profile.date_joined)} sx={{pr: 2, mb: 1.5}}/>
              </TableCell>
            </TableRow>
            }
            </>
          ))}
          <TableRow>
            
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
