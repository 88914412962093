import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button, Pagination, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';
import SearchBar from '../../components/searchbar';
import { Add, FilterList } from '@mui/icons-material';
import AccountTable from '../Account/AccountTable';
import CheckboxDropdown from '../../components/CheckboxDropdown';
import useScreenType from 'react-screentype-hook';
import { Link } from 'react-router-dom';
import axiosInstance from '../../components/auth/auth';
import { Spinner } from 'react-bootstrap';
import * as CustomStyle from '../../components/CustomStyle'
import CustomPagination from '../../components/CustomPagination'
import ScreenLoader from '../../components/ScreenLoader';

const ProfileList = () => {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [next, setNext] = useState(null);
    const [previous, setPrevious] = useState(null);
    const screenType = useScreenType();
    const isDesktopScreen = screenType.isDesktop || screenType.isLargeDesktop ? true : false;

    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const filter = ['Admin', 'Staff', 'Active', 'Inactive', 'Select All'];
    const [selectedFilter, setSelectedFilter] = useState([]);

    const fetchProfiles = async () => {
        const apiUrl = constructApiUrl(); // Use current state values here
        try {
            const response = await axiosInstance.get(global.config.server_url + apiUrl);
            const data = response.data;
            setProfiles(data.results);
            setCount(data.count);
            setNext(data.next);
            setPrevious(data.previous);
        } catch (error) {
            console.log('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfiles();
    }, [rowsPerPage, page, search, selectedFilter]);

    const constructApiUrl = () => {
        let apiUrl = `account/api/list/?page=${page}&page_size=${rowsPerPage}`;

        if (search) {
            apiUrl = `account/api/list/?page=1&page_size=${rowsPerPage}`;
            setPage(1);
            apiUrl += `&search=${search}`;
        }

        const selectAll = selectedFilter.includes('Select All');
        if (!selectAll) {
            selectedFilter.forEach((filter) => {
                
                if (filter === 'Admin') apiUrl += `&is_admin=true`;
                if (filter === 'Staff') apiUrl += `&is_admin=false`;
                if (selectedFilter.includes('Admin') && selectedFilter.includes('Staff')){
                    // apiUrl = `account/api/list/?page=${page}&page_size=${rowsPerPage}`;
                    apiUrl = apiUrl.replace('&is_admin=true', '');
                    apiUrl = apiUrl.replace('&is_admin=false', '');
                }
                if (filter === 'Active') apiUrl += `&is_active=true`;
                if (filter === 'Inactive') apiUrl += `&is_active=false`;
                if (selectedFilter.includes('Active') && selectedFilter.includes('Inactive')){
                    apiUrl = apiUrl.replace('&is_active=true', '');
                    apiUrl = apiUrl.replace('&is_active=false', '');
                }
                if (filter === 'Newest') apiUrl += `&ordering=date_joined`;
                if (filter === 'Oldest') apiUrl += `&ordering=-date_joined`;
            });
        }
        
        console.log("end", apiUrl)
        return apiUrl;
    };

    const searchAccount = async () => {
        fetchProfiles();
    };

    const onSelectFilterAccount = async (e) => {
        const {
            target: { value },
        } = e;
        
        if(!value.includes(undefined)){
            setSelectedFilter(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );  
        }
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1); // Reset to first page when changing rows per page
        fetchProfiles();
    };

    const handlePageChange = (event, value) => {
        setPage(value);
        fetchProfiles();
    };

    useEffect(() => {
        fetchProfiles();
    }, [rowsPerPage, page, search, selectedFilter]);

    const totalPages = Math.ceil(count / rowsPerPage);

    return (
        loading ? 
        <ScreenLoader /> 
        : 
        <>
        <Box sx={{ ...CustomStyle.container }}>
            <Typography sx={{...CustomStyle.title}}>All Accounts</Typography>
            <Paper elevation={0} sx={{ ...CustomStyle.bodyPadding, width: 1, flex: 1 }}>
                <Typography sx={{ ...CustomStyle.subtitle, mb: 3 }}>User Information</Typography>
                <Box sx={{ ...CustomStyle.columnRow, gap: 4, pb: {xs: 4, lg: 2}, height: {xs: null, lg: '3.5rem'}, bgcolor:'' }}>
                    <SearchBar onClick={searchAccount} onChange={(e) => setSearch(e.target.value)} />
                    <Box sx={{ ...CustomStyle.row, gap: {xs: 1, lg: 4}, width: 1 }}>
                        <CheckboxDropdown label='Filter By' items={filter} selectedItems={selectedFilter}
                            onClick={() => setSelectedFilter([])} onChange={onSelectFilterAccount} 
                        />
                        <Button  variant='contained' disableElevation component={Link} to={'/create_account'}
                            sx={{ ...CustomStyle.containedButton, fontSize: {xs: '2.25rem', lg: '1rem'}, width: {xs: 0.6,  lg:0.25}, bgcolor: blue[700], height:{xs: '6rem', lg: 1}}}
                            startIcon={<Add sx={{ ...CustomStyle.buttonIcon, transform: {xs: 'scale(3)', lg:'scale(1.25)'} }} />}>
                            Create Account
                        </Button>
                    </Box>
                </Box>
                <AccountTable profiles={profiles} isDesktop={isDesktopScreen}/> 
                <Box sx={{ ...CustomStyle.row, justifyContent: {xs:'center', lg:'space-between'}, mt: 5 }}>
                    {isDesktopScreen? 
                    <FormControl variant="outlined" sx={{ position: 'relative', bottom: 0 }}>
                        <InputLabel>Show</InputLabel>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            label="Show"
                            sx={{ minWidth: 80, fontSize: '0.95rem', height: "2rem" }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                    : <></>}
                    <CustomPagination totalPages={totalPages} page={page} handlePageChange={handlePageChange}/>
                    <Box></Box>
                </Box>
            </Paper>
        </Box>
        </>
    );
};

export default ProfileList;
