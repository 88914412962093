import React, { useEffect, useState } from 'react'
import * as CustomStyle from '../../components/CustomStyle'
import { Box, Button, IconButton, Paper, Typography } from '@mui/material'
import { Add, NotificationsOutlined } from '@mui/icons-material'
import { blue, red } from '@mui/material/colors'
import NotificationConfig from './NotificationConfig'
import NotificationSettings from './NotificationSettings'
import AlertNotificationForm from './AlertNotificationForm'
import axiosInstance from '../../components/auth/auth'
import ScreenLoader from '../../components/ScreenLoader'

const NotificationAdmin = ({settingsCallback, alertsSettings, setAlertsSettings, isDesktop}) => {
    const [loading, setLoading] = useState(true);
    const [adminView, setAdminView] = useState("Notification Configuration")
    const configurationType = ["Notification Configuration", "Create", "Edit"]
    const [alertsConfig, setAlertsConfig] = useState([]);
    const [selectedAlertConfig, setSelectedAlertConfig] = useState(null);
    const [formData, setFormData] = useState({})

    useEffect(() => {
        fetchAlertsConfig();
        fetchFormData();
    }, [adminView]);

    const fetchAlertsConfig = async () => {
        const apiUrl = 'alert/api/list/';
        try{
            const response =  await axiosInstance.get(global.config.server_url + apiUrl);
            const data = response.data;
            setAlertsConfig(data);
        }catch (error) {
            console.log('Error fetching alert config data', error);
        }
    }


    const fetchFormData = async () => {
        const triggerApiUrl = 'alert/api/trigger/list/';
        const alertTypeApiUrl = 'alert/api/type/list/';
        const recipientsUrl = 'account/api/simple/list/';
        try{
            const triggerResponse =  await axiosInstance.get(global.config.server_url + triggerApiUrl);
            const alertTypeResponse =  await axiosInstance.get(global.config.server_url + alertTypeApiUrl);
            const recipientsResponse =  await axiosInstance.get(global.config.server_url + recipientsUrl);
            const triggerData = triggerResponse.data;
            const alertTypeData = alertTypeResponse.data;
            const recipientsData = recipientsResponse.data;
            const level = alertsSettings.map((alert) => ({id: alert.id, name: alert.name}));
            setFormData({trigger: triggerData, alertType: alertTypeData, level: level, recipients: recipientsData});
        }catch (error) {
            console.log('Error fetching form data', error);
        }finally{
            setLoading(false);
        }
    }

    const activeButton = {
        ...CustomStyle.containedButton,
        bgcolor: red[500],
        border: `1px solid ${red[500]}`,
        '&:hover': {
            bgcolor: red[500],
            border: `1px solid ${red[500]}`
        },
        fontWeight: 'bold',
        textTransform: 'none',
        width: {xs: 1, lg: "20rem"}
    }
    
    const inactiveButton = {
        ...CustomStyle.containedButton,
        bgcolor: 'white',
        color: 'black',
        border: `1px solid`,
        '&:hover': {
            bgcolor: 'white',
            color: 'black',
            border: `1px solid`,
        },
        fontWeight: 'bold',
        textTransform: 'none',
        width: {xs: 1, lg: "20rem"}
    }
    
  return (
    loading ? 
    <ScreenLoader /> 
    : 
    <Paper elevation={0} sx={{ ...CustomStyle.bodyPadding, width: 1, flex: 1 }}>
        <Box sx={{...CustomStyle.row, gap: 4, mb: 3, bgcolor: '', justifyContent: 'space-between'}}>
            <Box sx={{...CustomStyle.row, gap: 3, bgcolor: '', width: 1}}>
                <Button variant='contained' disableElevation sx={configurationType.includes(adminView) ? activeButton : inactiveButton} onClick={()=>{setAdminView("Notification Configuration")}}>{isDesktop? "Notification Configuration" : "Configuration"}</Button>
                <Button variant='contained' disableElevation sx={adminView === "Notification Settings" ? activeButton : inactiveButton} onClick={()=>{setAdminView("Notification Settings")}}>{isDesktop? "Notification Settings" : "Settings"}</Button>
            </Box>
            {isDesktop &&
            <Button variant='contained' disableElevation sx={{}} onClick={() => {settingsCallback(false)}}>
                <NotificationsOutlined sx={{...CustomStyle.buttonIcon}}/>
            </Button> 
            }   
        </Box>
        {isDesktop? 
        <Typography sx={{...CustomStyle.subtitle, mb: 2}}>
            {adminView === "Create" || adminView === "Edit" ? adminView + " Alert Notification" : adminView}
        </Typography>
        : 
        <Box sx={{...CustomStyle.row, justifyContent: 'space-between', mb: 3, alignItems: 'center', bgcolor: ''}}>
            <Typography sx={{...CustomStyle.subtitle}}>
                {adminView === "Create" || adminView === "Edit" ? adminView + " Alert Notification" : adminView}
            </Typography>
            <Button variant='contained' disableElevation sx={{}} onClick={() => {settingsCallback(false)}}>
                <NotificationsOutlined sx={{...CustomStyle.subtitleButton, m: 3}}/>
            </Button> 
        </Box>
        }
        {adminView === "Notification Configuration" ? 
            <Box sx={{...CustomStyle.column, width: 1, alignItems: 'end'}}>
                <NotificationConfig isDesktop={isDesktop} adminViewCallback={setAdminView} alertsConfig={alertsConfig} setAlertsConfig={setAlertsConfig} selectedAlertConfigCallback={setSelectedAlertConfig}/>   
                <Button  variant='contained' disableElevation onClick={() => {setAdminView("Create")}}
                    sx={{ ...CustomStyle.containedButton, width: {xs: '25rem',  lg:'15rem'}, bgcolor: blue[600], mt: {xs: 5, lg: 3}, height:{xs: '5rem', lg: '2.5rem'}}}
                    startIcon={<Add sx={{ ...CustomStyle.buttonIcon }} />}>
                    Create Notification
                </Button>  
            </Box>
        : adminView === "Notification Settings" ?
            <NotificationSettings alertsSettings={alertsSettings} setAlertsSettings={setAlertsSettings}/>
        : 
            <AlertNotificationForm isDesktop={isDesktop} method={adminView} adminViewCallback={setAdminView} formData={formData} selectedAlertConfig={adminView === "Edit" ? selectedAlertConfig : null }/>
        }
    </Paper>
  )
}

export default NotificationAdmin