import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ff0000', // Red color for the status code
        },
        secondary: {
            main: '#0088ff', // Blue color for the button
        },
    },
    typography: {
        h1: {
            fontSize: '15rem',
            fontWeight: 'bold',
            position: 'relative',
            zIndex: 1,
            fontFamily: 'Arial Rounded MT, sans-serif'
        },
        h5: {
            fontSize: '1.5rem',
            fontFamily: 'Arial Rounded MT, sans-serif'
        },
        logoText: {
            fontSize: '1.8rem',
            fontWeight: 'bold',
            color: '#fff',
            fontFamily: 'Arial Rounded MT, sans-serif'
        },
    },
});

const defaultMessages = {
    400: "Bad Request: The server cannot process the request due to a client error.",
    401: "Unauthorized: Authentication is required to access this resource.",
    403: "Forbidden: You don't have permission to access this resource.",
    404: "Page Not Found: The page you are looking for does not exist.",
    500: "Internal Server Error: Something went wrong on our end.",
};

const ErrorHandlerPage = () => {
    const logo_url = global.config.base_url + "logo/astramina_logo.png"
    const [searchParams] = useSearchParams();
    const statusCode = searchParams.get('status') || '404';
    const message = searchParams.get('message') || defaultMessages[statusCode] || "An error occurred.";

    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ height: '100%', }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        paddingBottom: '1rem',
                        mt:"2rem"
                    }}
                >
                    <img src={logo_url} alt="Icon" className="auth-logo" />
                    <Typography variant="logoText">
                        Seasonings Specialities
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: '#fff', // Dark background
                        color: '#333', // White text
                        padding: '2rem',
                        borderRadius: '8px',
                        height: '80%'
                    }}
                >
                    <Box sx={{ position: 'relative', display: 'inline-block'}}>
                        <Typography
                            variant="h1"
                            color="primary"
                            sx={{
                                position: 'absolute',
                                top: -6,
                                left: -6,
                                color: '#000000',
                                fontFamily: 'Arial Rounded MT, sans-serif'
                            }}
                        >
                            {statusCode}
                        </Typography>
                        <Typography variant="h1" color="primary" sx={{ fontFamily: 'Arial Rounded MT, sans-serif' }}>
                            {statusCode}
                        </Typography>
                    </Box>
                    <Typography variant="h5" gutterBottom>
                        {message}
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        href="/"
                        sx={{ mt:8, fontFamily: 'Arial Rounded MT, sans-serif' }}
                    >
                        Return to Homepage
                    </Button>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default ErrorHandlerPage;
