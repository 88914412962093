import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Typography, ToggleButton, ToggleButtonGroup, Card, Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, CircularProgress } from '@mui/material';
import LocationCard from '../../components/ambient_temperature_and_humidity/LocationCard';
import Chart from '../../components/Chart';
import { Download as DownloadIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import axiosInstance from '../../components/auth/auth';
import * as CustomStyle from '../../components/CustomStyle';
import ScreenLoader from '../../components/ScreenLoader';

const AmbientTemperature = () => {
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [locations, setLocations] = useState([]);
    const [serialNumber, setSerialNumber] = useState('');
    const [timeRange, setTimeRange] = useState('1');
    const [chartDataTemperature, setChartDataTemperature] = useState({ labels: [], datasets: [] });
    const [chartDataHumidity, setChartDataHumidity] = useState({ labels: [], datasets: [] });
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);

    const anchorRef = useRef(null);
    const prevOpen = useRef(open);

    useEffect(() => {
        axiosInstance.get(global.config.server_url + 'station/api/stations/data/?sensors=IOT-S01&sensors=IOT-S02')
            .then(response => {
                setLocations(response.data[0].locations);
                setSerialNumber(response.data[0].serial_number);
                setInitialDataLoaded(true);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
                setInitialDataLoaded(false);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!initialDataLoaded) return;

        const fetchChartData = () => {
            axiosInstance.get(global.config.server_url + 'station/api/chart/data/?sensors=IOT-S01&sensors=IOT-S02', {
                params: { serial_number: serialNumber, range: timeRange }
            })
                .then(response => {
                    const datasets = (selectedLocations.length ? selectedLocations : locations.map(d => d.code)).reduce((acc, code) => {
                        const locData = locations.find((d) => d.code === code);
                        if (locData) {
                            acc.temperature.push({
                                label: locData.name,
                                data: response.data[locData.code]?.['IOT-S01'].map(data => data.value) || [],
                                borderColor: response.data[locData.code]?.['color']['IOT-S01'],
                                fill: true,
                                scaleType: 'time',
                                labels: response.data[locData.code]?.['labels']['IOT-S01'].map(data => data) || [],
                            });
                            acc.humidity.push({
                                label: locData.name,
                                data: response.data[locData.code]?.['IOT-S02'].map(data => data.value) || [],
                                borderColor: response.data[locData.code]?.['color']['IOT-S02'],
                                fill: true,
                                scaleType: 'time',
                                labels: response.data[locData.code]?.['labels']['IOT-S02'].map(data => data) || [],
                            });
                            acc.temperature_unit = response.data[locData.code]?.['unit']['IOT-S01'];
                            acc.humidity_unit = response.data[locData.code]?.['unit']['IOT-S02'];
                        }
                        return acc;
                    }, { temperature: [], humidity: [] });
                    setChartDataTemperature({ datasets: datasets.temperature, unit: datasets.temperature_unit });
                    setChartDataHumidity({ datasets: datasets.humidity, unit: datasets.humidity_unit });
                    setLoading(false);
                })
                .catch(error => {
                    console.error('There was an error fetching the chart data!', error);
                    setLoading(false);
                });
        };

        fetchChartData();
    }, [initialDataLoaded, serialNumber, timeRange, selectedLocations, locations]);

    const handleCardSelect = (location) => {
        setSelectedLocations(prev => prev.includes(location) ? prev.filter(loc => loc !== location) : [...prev, location]);
    };

    const handleTimeRangeChange = (event, newTimeRange) => {
        if (newTimeRange !== null) {
            setTimeRange(newTimeRange);
        }
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleMenuItemClick = (type) => {
        switch (type) {
            case 'Temperature':
                handleDownload(['IOT-S01']);
                break;
            case 'Humidity':
                handleDownload(['IOT-S02']);
                break;
            case 'All':
                handleDownload(['IOT-S01', 'IOT-S02']);
                break;
            default:
                handleDownload([]);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) return;
        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    };

    const handleDownload = async (sensors) => {
        const data = {
            sensors: sensors,
            locations: selectedLocations.length ? selectedLocations : locations.map(location => location.code),
            slave_ids: Array.from(new Set(locations.flatMap(location => location.module.map(module => module.slaveID)))),
            range: timeRange
        };

        axiosInstance.post(global.config.server_url + 'station/api/sensor/data/download/', data, { responseType: 'arraybuffer' })
            .then(response => {
                if (response.data) {
                    const blob = new Blob([response.data], { type: 'application/zip' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'sensor_data.zip';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                } else {
                    console.error('Unexpected response format', response);
                }
            })
            .catch(error => {
                console.error('There was an error when downloading sensor data!', error);
            });
    };

    if (loading) return (
        <ScreenLoader />
    );

    return (
        <Box sx={{ ...CustomStyle.container, minHeight: '100%' }}>
            <Typography sx={{ ...CustomStyle.title }}>
                Ambient Temperature and Humidity
            </Typography>
            {locations.length > 0 ? (
                <Grid container spacing={3} sx={{ }}>
                    {locations.map((data) => (
                        <Grid item xs={12} sm={8} md={6} lg={2.4} key={data.code}>
                            <LocationCard
                                location={data}
                                onSelect={handleCardSelect}
                                selected={selectedLocations.includes(data.code)}
                            />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Typography variant="h6" align="center" color="textSecondary" sx={{ mt: 2 }}>
                    No locations available.
                </Typography>
            )}

            <Card elevation={0} sx={{ marginTop: "2.5rem", padding: "1.5rem", paddingLeft: "1rem", paddingRight: "1rem", }}>
                <Box sx={{ marginBottom: "1rem", display: "flex" }} justifyContent="space-between">
                    <ToggleButtonGroup
                        value={timeRange}
                        exclusive
                        onChange={handleTimeRangeChange}
                        aria-label="time range"
                    >
                        {['1', '3', '7'].map(range => (
                            <ToggleButton key={range} value={range} sx={{
                                '&.Mui-selected': { backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'darkred' } },
                                '&:hover': { backgroundColor: 'red', color: 'white' },
                                padding: '0.4rem 1rem',
                                fontSize: {xs: '1.25rem', lg: '0.75rem'}
                            }}>
                                {range === '1' ? '24 hours' : `${range} days`}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                    <Button
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        startIcon={<DownloadIcon sx={{ transform: {xs: 'scale(1.25)', lg: 'scale(1)'} }} />}
                        endIcon={<KeyboardArrowDownIcon sx={{ transform: {xs: 'scale(1.25)', lg: 'scale(1)'} }} />}
                        sx={{
                            backgroundColor: '#007bff', color: '#fff', borderRadius: '4px', '&:hover': { backgroundColor: '#0056b3' },
                            padding: '0 1rem', fontSize: {xs: '1.25rem', lg: '0.75rem'}
                        }}
                    >
                        Download
                    </Button>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal sx={{ zIndex: 999 }}>
                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom' }}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                                            <MenuItem sx={{ fontSize: {xs: '1.5rem', lg: '1rem'} }} onClick={() => handleMenuItemClick('Temperature')}>Temperature</MenuItem>
                                            <MenuItem sx={{ fontSize: {xs: '1.5rem', lg: '1rem'} }} onClick={() => handleMenuItemClick('Humidity')}>Humidity</MenuItem>
                                            <MenuItem sx={{ fontSize: {xs: '1.5rem', lg: '1rem'} }} onClick={() => handleMenuItemClick('All')}>All</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <Chart title="Temperature History" data={chartDataTemperature} unit="℃"/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Chart title="Humidity History" data={chartDataHumidity} unit="%"/>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

export default AmbientTemperature;
