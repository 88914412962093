import React from 'react'
import { Box, Modal, Typography, Button } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import { green } from '@mui/material/colors'

const SuccessModal = ({open, onClose, BackdropProps={style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }}, sx}) => {
    
  return (
    <Modal open={open} onClose={onClose} BackdropProps={{ ...BackdropProps }}>
      <Box sx={{ ...sx }}>
        <CheckCircle sx={{color: green['A700'], width: 0.5, height: 0.5, mb: 3}}/>
        <Typography sx={{color: green['A700'], fontFamily: 'Segoe UI', fontWeight: 'bold', fontSize: {xs: 52, lg: 26}}}>
          Success!
        </Typography>
        <Box sx={{display:'flex', flexDirection: "column", mt: {xs: 5, lg: 3}, ml: {xs: 15, lg: 10}, mr: {xs: 15, lg: 10}, bgcolor:green['A700'], borderRadius: 2}}>
          <Button onClick={onClose} 
            sx={{textTransform: 'none', color: 'white', fontFamily: 'Segoe UI', fontWeight: 'bold', fontSize: {xs: 42, lg: 16}}}>
              OK
          </Button>  
        </Box>
      </Box>
    </Modal>
  )
}

export default SuccessModal