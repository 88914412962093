import { Box, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'

const LabeledTextInput = ({label, value, onChange, required, helperText, error, mt={xs: 4, lg: 0},
    fontSize={xs: '2.1rem', lg: '0.9rem'}, width={xs: 1, lg:0.475}, bgcolor=grey[100], inputProps}) => {
  return (
    <Box display='flex' flexDirection='column' width={width} pb={{xs:2, lg:0}} height={{xs: null, lg: '4.5rem'}} bgcolor={''} mt={mt}>
        <Typography variant='h6' sx={{fontWeight: 'bold', pb: 1, fontSize: fontSize, fontFamily: 'Segoe UI'}}>{label}</Typography>
        <TextField inputProps={inputProps} required={required} value={value} sx={{height: {xs: null, lg: '4rem'}, bgcolor: bgcolor, '.MuiInputBase-input': {fontSize: fontSize, fontFamily: 'Segoe UI', height: {xs: null, lg: '0.5rem'}}}} 
            onChange={onChange} label={helperText} error={error} 
        />  
    </Box>
  )
}

export default LabeledTextInput