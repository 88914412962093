import React, { useState, useRef, useEffect } from 'react';
import Clipboard from 'clipboard';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
    IconButton,
    CircularProgress,
    Collapse,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
    useTheme
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WifiIcon from '@mui/icons-material/Wifi';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import axiosInstance from '../auth/auth';
import * as CustomStyle from '../../components/CustomStyle';
import { grey } from '@mui/material/colors';
import { format } from 'date-fns';
import ScreenLoader from '../ScreenLoader';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { green, red } from '@mui/material/colors';

function Device({ device }) {
    const copyButtonRef = useRef(null);

    useEffect(() => {
        const clipboard = new Clipboard(copyButtonRef.current, {
            text: () => device.serial_number,
        });

        clipboard.on('error', () => {
            console.log('Failed to copy');
        });

        return () => {
            clipboard.destroy();
        };
    }, [device.serial_number]);

    return (
        <Card elevation={0} sx={{ marginRight: { xs: 0, lg: 2 }, height: '100%' }}>
            <CardHeader sx={{ padding: '10px' }}
                title={
                    <Typography
                        sx={{
                            fontFamily: 'Arial Rounded MT, Segoe UI, sans-serif',
                            ...CustomStyle.subtitle,
                            marginLeft: 2
                        }}
                    >
                        {device.name}
                    </Typography>
                }
                subheader={
                    <Box display="flex" alignItems="center" sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" color="textPrimary" component="span" sx={{ fontWeight: 'bold', color: 'grey', fontSize: { xs: 38, lg: 18 } }}>
                            Serial Number {' '}
                        </Typography>
                        <Typography variant="body1" component="span" sx={{ ml: 1, color: '#FF000A', fontFamily: 'Arial Rounded MT, Segoe UI, sans-serif', fontSize: { xs: 38, lg: 18 } }}>
                            {device.serial_number}
                        </Typography>
                        <IconButton ref={copyButtonRef}>
                            <ContentCopyIcon sx={{ color: 'dodgerblue', fontSize: { xs: 38, lg: 18 } }} />
                        </IconButton>
                    </Box>
                }
            />
            <CardContent sx={{ padding: 0 }}>
                <Box display="flex" justifyContent="center">
                    <Box
                        component="img"
                        src="images/device.png"
                        alt="Icon"
                        sx={{
                            marginTop: '1',
                            width: '100%',
                            maxWidth: {
                                xs: '30rem',
                                lg: '15rem',
                            },
                            height: 'auto'
                        }}
                    />
                </Box>
                <Box display="flex" flexDirection="column" sx={{ marginLeft: 5, marginTop: { xs: 5, lg: 10 }, marginBottom: { xs: 10, lg: 10 } }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'grey', fontSize: { xs: 28, lg: 18 } }}>
                            Connection
                        </Typography>
                        {
                            device.is_online ? (
                                <Box display="flex" alignItems="center" marginRight={5}>
                                    <Box sx={{ backgroundColor: green[50], color: green[300], padding: '0.3rem', width: { xs: '8rem', lg: '5rem' }, borderRadius: { xs: '10rem', lg: '1rem' }, fontSize: { xs: 25, lg: 13 }, display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Arial Rounded MT, Segoe UI, sans-serif', }}>
                                        <WifiIcon sx={{ color: green[300], marginRight: '4px', fontSize: 20 }} />
                                        Online
                                    </Box>
                                </Box>
                            ) :
                                (
                                    <Box display="flex" alignItems="center" marginRight={5}>
                                        <Box sx={{ backgroundColor: red[50], color: red[300], padding: '0.3rem', width: { xs: '8rem', lg: '5rem' }, borderRadius: { xs: '10rem', lg: '1rem' }, fontSize: { xs: 25, lg: 13 }, display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Arial Rounded MT, Segoe UI, sans-serif', }}>
                                            <WifiOffIcon sx={{ color: red[300], marginRight: '4px', fontSize: 20 }} />
                                            Offline
                                        </Box>
                                    </Box>
                                )
                        }
                       
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginTop: { xs: 3, lg: 2, } }}>
                        <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'grey', fontSize: { xs: 28, lg: 18 } }}>
                            Status
                        </Typography>
                        {
                            device.status ? (
                                <Box display="flex" alignItems="center" marginRight={5}>
                                    <Box sx={{ backgroundColor: green[50], color: green[300], padding: '0.3rem', width: { xs: '8rem', lg: '5rem' }, borderRadius: { xs: '10rem', lg: '1rem' }, fontSize: { xs: 25, lg: 13 }, display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Arial Rounded MT, Segoe UI, sans-serif', }}>
                                        Healthy
                                    </Box>
                                </Box>
                            ):
                            (
                                <Box display="flex" alignItems="center" marginRight={5}>
                                    <Box sx={{ backgroundColor: red[50], color: red[300], padding: '0.3rem', width: { xs: '8rem', lg: '5rem' }, borderRadius: { xs: '10rem', lg: '1rem' }, fontSize: { xs: 25, lg: 13 }, display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Arial Rounded MT, Segoe UI, sans-serif', }}>
                                        Unhealthy
                                    </Box>
                                </Box>
                            )
                        }
                        
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

function Location({ location, index }) {
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const toggleContent = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Box sx={{ mr: 1, height: '100%' }}>
            <Card elevation={0} onClick={toggleContent} sx={{ height: '100%', padding: { xs: 3, lg: 0 } }}>
                <CardHeader
                    title={
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Segoe UI',
                                color: grey['A700'],
                                fontSize: { xs: '2.5rem', lg: '1.5rem' },
                                marginTop: { xs: 2, lg: 0 },
                                marginBottom: { xs: 2, lg: 0 }
                            }}

                        >
                            Location {index + 1}
                        </Typography>

                    }
                    action={

                        !isDesktopScreen ? (
                            <IconButton sx={{ transform: { xs: 'scale(5)', lg: 0 }, ml: 1, mr: 8, mt: 3 }}>
                                {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        ) : (
                            <Box>

                            </Box>
                        )
                    }
                />

                <Collapse in={isOpen || isDesktopScreen} timeout="auto" unmountOnExit >
                    <CardContent>
                        <Box display="flex" flexDirection="column" gap={2}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="body2" color="textSecondary" sx={{ color: 'grey', fontSize: { xs: 25, lg: 15 } }}>
                                    ID
                                </Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'grey', fontSize: { xs: 25, lg: 15 }, mr: { xs: 5, lg: 0 } }}>
                                    {location.code}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="body2" color="textSecondary" sx={{ color: 'grey', fontSize: { xs: 25, lg: 15 } }}>
                                    Name
                                </Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', color: 'grey', fontSize: { xs: 25, lg: 15 }, mr: { xs: 5, lg: 0 } }}>
                                    {location.name}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2" color="textSecondary" sx={{ color: 'grey', fontSize: { xs: 25, lg: 15 } }}>
                                    Sensor
                                </Typography>
                                <List sx={{ ml: -1 }}>
                                    {location.module.map((module, index) => (
                                        <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', paddingRight: "0" }}>
                                            <ListItemText
                                                primary={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Typography sx={{
                                                            fontWeight: 'bold',
                                                            color: 'grey',
                                                            fontSize: { xs: 25, lg: 15 },
                                                            width: {xs: '36rem', lg: '12rem'},
                                                        }}>
                                                            &#8226; {module.name}
                                                        </Typography>
                                                        {module.status ? (
                                                            <Box sx={{
                                                                backgroundColor: green[50],
                                                                color: green[300],
                                                                padding: '0.3rem',
                                                                width: { xs: '8rem', lg: '6rem' },
                                                                borderRadius: { xs: '10rem', lg: '1rem' },
                                                                fontSize: 13,
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                fontFamily: 'Arial Rounded MT, Segoe UI, sans-serif',
                                                                ml: 2 // Adjust the margin-left to your preference
                                                            }}>
                                                                Normal
                                                            </Box>
                                                        ) : (
                                                            <Box sx={{
                                                                backgroundColor: red[50],
                                                                color: red[300],
                                                                padding: '0.3rem',
                                                                width: { xs: '8rem', lg: '6rem' },
                                                                borderRadius: { xs: '10rem', lg: '1rem' },
                                                                fontSize: 13,
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                fontFamily: 'Arial Rounded MT, Segoe UI, sans-serif',
                                                                ml: 2 // Adjust the margin-left to your preference
                                                            }}>
                                                                Abnormal
                                                            </Box>
                                                        )}
                                                    </Box>}

                                                secondary={<Typography sx={{ fontStyle: 'italic', color: 'grey', fontSize: { xs: 25, lg: 15 } }}>{module.last_sent ? `Last sent data at ${format(new Date(module.last_sent), 'yyyy-MM-dd HH:mm')}` : 'No data'}</Typography>}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                    </CardContent>
                </Collapse>
            </Card>
        </Box>

    );
}

function Devices() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axiosInstance.get(global.config.server_url + 'station/api/stations/')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.log('There was an error fetching the data!', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <ScreenLoader />;
    }

    return (
        <Box sx={{ ...CustomStyle.container, pb: 0.1 }}>
            <Typography sx={{ ...CustomStyle.title }}>
                Devices
            </Typography>
            <Box sx={{ marginTop: 1 }}>
                {data.map((device) => (
                    <Grid container key={device.id} sx={{ marginBottom: { xs: 3 } }}>
                        <Grid item xs={12} lg={4} sx={{ height: 'auto', marginBottom: { xs: 1, lg: 1 } }}>
                            <Device device={device} />
                        </Grid>
                        <Grid item xs={12} lg={8} sx={{ marginTop: 0, padding: 0, height: 'auto', marginBottom: { xs: 0, lg: 0 } }}>
                            <Grid container spacing={2} sx={{ height: '100%', margin: '0px !important', width: 'auto !important' }}>
                                {device.locations.map((location, index) => (
                                    <Grid item xs={12} lg={4} key={location.id} sx={{ marginTop: { xs: 2.5, lg: 0 }, padding: '0 !important', marginBottom: { xs: 0, lg: 1 }, }}>
                                        <Location location={location} index={index} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </Box>
    );
}

export default Devices;