import { Box, Button, IconButton, Paper, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import * as CustomStyle from '../../components/CustomStyle'
import { CalendarMonthOutlined, Download, OpenInNew, Schedule, WarningAmber } from '@mui/icons-material'
import { blue, grey, red } from '@mui/material/colors'
import BasicDropdown from '../../components/BasicDropdown'
import DashboardTable from './DashboardTable'
import useScreenType from 'react-screentype-hook';
import TimerCard from '../../components/TimerCard'
import MeterCard from '../../components/MeterCard'
import SearchBar from '../../components/searchbar'
import DualText from '../../components/DualText'
import IOSSwitch from '../../components/IOSSwitch'
import CustomPagination from '../../components/CustomPagination'
import axiosInstance, { checkAndRefreshToken,getAccessToken }  from '../../components/auth/auth'
import { Spinner } from 'react-bootstrap'
import SuccessModal from '../../components/SuccessModal'
import ErrorModal from '../../components/ErrorModal'
import { format } from 'date-fns';
import ScreenLoader from '../../components/ScreenLoader'


const AnimalFatCookerDashboard = () => {
    //page layout related
    const [loading, setLoading] = useState(true);
    const screenType = useScreenType();
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [fullScreen, setFullScreen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const totalPages = Math.ceil(count / rowsPerPage);
    const isDesktopScreen = screenType.isDesktop || screenType.isLargeDesktop ? true : false;
    const [searchQuery, setSearchQuery] = useState('');

    //Data related
    const [eventLogs, setEventLogs] = useState([]);
    const logsFilter = ["Today", "This Week", "This Month", "This Year", "All Time"]
    const [filter, setFilter] = useState('This Week');
    const AFCsensors = ['IOT-S03', 'IOT-S04', 'IOT-S05']
    const [cookerState, setCookerState] = useState(false);
    const [cookerStartTime, setCookerStartTime] = useState(null);
    const [cookerEndTime, setCookerEndTime] = useState(null);
    const [cycleID, setCycleID] = useState(null);
    const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
    const [temperature, setTemperature] = useState(0);
    const [temperatureCapturedTime, setTemperatureCapturedTime] = useState(null);
    const [maxTemperature, setMaxTemperature] = useState(0);
    const [maxTemperatureCapturedTime, setMaxTemperatureCapturedTime] = useState(null);
    const [alertID, setAlertID] = useState('A24002');
    const [serialNumber, setSerialNumber] = useState(null);

    //Websocket related
    const [webSocketConnected, setWebSocketConnected] = useState(true);
    const wsRef = useRef(null);
    const retryInterval = useRef(null);
    const [alertCurrentTemp, setAlertCurrentTemp] = useState(false);
    const [alertMaxTemp, setAlertMaxTemp] = useState(false);
    const [abnormalHeartbeat, setAbnormalHeartbeat] = useState(false);
    let off_counter = 0;


    useEffect(() => {
        fetchAFCLogs();
        getSerialNumber();
    }, [filter, rowsPerPage, page, searchQuery, serialNumber, cycleID]);
    
    useEffect(() => {
        let timer;
        if (cookerState && cycleID) {
            // Reset timer when cookerState is set to true and cycleID is available
            // setTime({ hours: 0, minutes: 0, seconds: 0 });
            // setTemperature(0);
            // setMaxTemperature(0);
            timer = setInterval(updateTimer, 1000);
        } else {
            if (timer) {
                clearInterval(timer);
            }
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [cookerState, cycleID]);

    const updateTimer = () => {
        setTime((prevTime) => {
            let { hours, minutes, seconds } = prevTime;
            seconds += 1;
            if (seconds === 60) {
            minutes += 1;
            seconds = 0;
            }
            if (minutes === 60) {
            hours += 1;
            minutes = 0;
            }
            return { hours, minutes, seconds };
            }
        );
    };

    const getSerialNumber = async () => {
        try{
            let apiUrl = 'station/api/stations/data/';
            for(let i = 0; i < AFCsensors.length; i++){
                if(i<1) apiUrl += `?sensors=${AFCsensors[i]}`;
                else apiUrl += `&sensors=${AFCsensors[i]}`;
            }
            const response = await axiosInstance.get(global.config.server_url + apiUrl);
            const data = response.data;
            setSerialNumber(data[0].serial_number);
        }catch(error){
            console.log(error)
        }
    }

    const fetchAFCLogs = async () => {
        if(!serialNumber) return
        const apiUrl = constructApiUrl(); // Use current state values here
        try {
            const response = await axiosInstance.get(global.config.server_url + apiUrl);
            const data = response.data;
            console.log("ongoing_cycle",data.ongoing_cycle)
            if(data.data !== undefined) {
                let result = data.data;
                const filteredData = result.filter(entry => 
                    entry.cycle_id.toLowerCase().includes(searchQuery.toLowerCase())
                  );
                const tableData = filteredData.slice((page-1)*rowsPerPage, page*rowsPerPage)
                setEventLogs(tableData);
                setCount(result.length);
            }else{
                console.log('no data')
                setEventLogs([]);
            }

            if(data.ongoing_cycle !== undefined) {
                if(data.ongoing_cycle !== null){
                    const duration = data.ongoing_cycle.duration.split(":");
                    const hours = parseInt(duration[0]);
                    const minutes = parseInt(duration[1]);
                    const seconds = parseInt(duration[2]);
                    setCookerState(true)
                    setCycleID(data.ongoing_cycle.cycle_id);
                    setCookerStartTime(data.ongoing_cycle.cooker_on.datetime)
                    setTime({ hours, minutes, seconds });
                    setTemperature(data.ongoing_cycle.cooker_on.temp);
                    setMaxTemperature(data.ongoing_cycle.max_temp);   
                    setTemperatureCapturedTime(data.ongoing_cycle.latest_temp_time);
                    setMaxTemperatureCapturedTime(data.ongoing_cycle.latest_temp_time); 
                    setAlertCurrentTemp(data.ongoing_cycle.temp_alert);
                    setAlertMaxTemp(data.ongoing_cycle.max_temp_alert);
                    setAbnormalHeartbeat(data.ongoing_cycle.abnormal);
                }
            }
            
        } catch (error) {
            console.log('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    const downloadLogs = async () => {
        const apiUrl = constructApiUrl(true);
        try{
            axiosInstance.get(global.config.server_url + apiUrl, { responseType: 'arraybuffer' })
            .then(response => {
                if (response.data) {
                    const blob = new Blob([response.data], { type: 'application/zip' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'sensor_data.zip';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                } else {
                    console.error('Unexpected response format', response);
                    setOpenError(true);
                }
            })
            .catch(error => {
                console.error('There was an error when downloading sensor data!', error);
                setOpenError(true);
            });

        }catch(error){
            setOpenError(true);
            console.log('Error fetching data', error);
        }
    }

    const constructApiUrl = (download=false) => {
        const days = getFilterRange();
        let slave_id = 177;
        //if filter is first element, use today's date, etc
        let apiUrl = `station/api/stations/animal_fat_cooker/data/?serial_number=${serialNumber}&slave_id=${slave_id}&filter=${days}`;
        if(download) apiUrl += '&download=true';
        return apiUrl;
    }

    const getFilterRange = () => {
        let days = 0;
        if(filter === logsFilter[0]) days = 0;
        else if(filter === logsFilter[1]) days = 7;
        else if(filter === logsFilter[2]) days = 30;
        else if(filter === logsFilter[3]) days = 365;
        else if(filter === logsFilter[4]) days = null;
        return days;
    }

  // WEBSOCKET CALL
  useEffect(() => {
    const connectWebSocket = async () => {
      if(!serialNumber) return
      const accessToken = await checkAndRefreshToken();
      const ws = new WebSocket(global.config.websocket_url + 'station/websocket/' + serialNumber + `/?token=${accessToken}` );
      wsRef.current = ws;

      ws.onopen = () => {
        console.log('AFC WebSocket connected');
        setWebSocketConnected(true);
        clearInterval(retryInterval.current);
        retryInterval.current = null;
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        const sensor_type = message.sensor_type;
        if (AFCsensors.includes(sensor_type)) {
          const { value, datetime, alert, index } = message;
          const formattedDateTime = format(datetime, 'yyyy-MM-dd\'T\'HH:mm:ss', { timeZone: 'Asia/KualaLumpur' });
            if (sensor_type === 'IOT-S03') {
              if(value !== 65535){
                setAbnormalHeartbeat(alert && index === 8);
                setCookerState(value === 1 || value === 1.0);  
                if (value === 1 || value === 1.0) {
                    off_counter = 0;
                    setCookerStartTime(formattedDateTime);
                    if(cycleID === null){
                        // take latest cycle ID from eventLogs
                        const lastCycleID = eventLogs.length > 0 ? eventLogs[0].cycle_id : null;
                        if(lastCycleID !== null){
                            // remove the last 2 characters from lastCycleID 
                            const trimmedCycleID = lastCycleID?.slice(0, -2);
                            // take the last 2 characters from lastCycleID and convert it to an integer
                            const lastCycleIDInt = parseInt(lastCycleID?.slice(-2));
                            const newCycleID = trimmedCycleID + (lastCycleIDInt + 1).toString().padStart(2, '0');
                            setCycleID(newCycleID);
                        }else{
                            setCycleID('A24071101')
                        }
                    }else{
                    setCycleID(null)
                    setTime({ hours: 0, minutes: 0, seconds: 0 }) 
                    setCookerEndTime(null);
                    }

                } else {
                    off_counter += 1;
                    if(cookerState) setCookerEndTime(formattedDateTime);
                    // window.location.reload(false);
                }  
              } 
              else{
                setAbnormalHeartbeat(alert);
              }  
            } 
              
            else if (sensor_type === 'IOT-S04'){
                if(cookerState && off_counter <= 1){
                    setTemperature(value);
                    setTemperatureCapturedTime(formattedDateTime);
                    setAlertCurrentTemp(alert === true);    
                }
            }
            else if (sensor_type === 'IOT-S05'){
                if(cookerState && off_counter <= 1){
                    setMaxTemperature(value);
                    setMaxTemperatureCapturedTime(formattedDateTime);
                    setAlertMaxTemp(alert === true);    
                }
            }
        }
      };

      ws.onerror = (event) => {
        console.error('WebSocket error:', event);
        setWebSocketConnected(false);
        if (!retryInterval.current) {
          retryInterval.current = setInterval(() => {
            if (!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
              connectWebSocket();
            }
          }, 5000); // Retry every 5 seconds
        }
      };

      ws.onclose = () => {
        console.log('WebSocket closed, attempting to reconnect...');
        setWebSocketConnected(false);
        if (!retryInterval.current) {
          retryInterval.current = setInterval(() => {
            if (!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
              connectWebSocket();
            }
          }, 5000); // Retry every 5 seconds
        }
      };
    };

    connectWebSocket();

  }, [eventLogs]);

    const getDateAndTime = (formattedDateTime) => {
        const date = format(formattedDateTime, 'dd MMMM yyyy'); // e.g., "02 July 2024"
        const time = format(formattedDateTime, 'hh:mm a');
        return { date, time };
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value); 
        setPage(1);
    }

    const handleChange = (event) => {
      setFilter(event.target.value);
      setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleFullScreen = () => {
        setFullScreen(!fullScreen);
    }

  return (
    loading ? 
    <ScreenLoader /> 
    : 
    <Box sx={{...CustomStyle.container}}>
        <Typography sx={{...CustomStyle.title}}>Animal Fat Cooker Monitor</Typography>
        {!webSocketConnected  && (
          <Paper elevation={3} sx={{ p: 2, mb: 2, backgroundColor: red[100] }}>
            <Typography variant="body1" color={red[900]} fontWeight="bold" sx={{ display: 'flex', alignItems: 'center',fontSize:{xs:'25px' , lg:'auto'} }}>
              <WarningAmber sx={{ mr: 1,fontSize:{xs:'25px' , lg:'auto'} }} />
              WebSocket connection lost. Some features may be unavailable.
            </Typography>
          </Paper>
        )}
        <Box sx={{...CustomStyle.columnReverse, bgcolor: '', height: isDesktopScreen && {xs: 1500, lg:'36.875rem'}}}>
        <Paper elevation={0} sx={{...CustomStyle.bodyContainer60px, height: 1, width: fullScreen? 1: CustomStyle.bodyContainer60px.width}}>
            <Box sx={{...CustomStyle.columnRow, alignItems: 'center', justifyContent: 'space-between', height: {xs: null, lg:'4rem'}, pb: {xs: 3, lg: 0}}}>
                <Box sx={{...CustomStyle.row, width: {xs: 1, lg: 0.3}, mb: 0}}>
                    <Typography sx={{...CustomStyle.subtitle, mr: 3, mt: 0.5}}>Event Logs</Typography>
                    <IconButton onClick={handleFullScreen}> 
                        <OpenInNew sx={{...CustomStyle.subtitleButton, color: blue['500']}}></OpenInNew>
                    </IconButton>
                </Box>
                <Box sx={{...CustomStyle.columnRow, width: {xs: 1, lg: 0.5}, height: {xs: null, lg:'2.75rem'}}}>
                    <SearchBar onChange={handleSearchChange} sx={{display: fullScreen? 'flex':'none'}}/> {/*  onClick={handleSearchClick} */}
                    <BasicDropdown width={{xs: 1, lg: fullScreen? 0.25:1}} selectedItem={filter} onChange={handleChange} items={logsFilter}/>
                    <Button variant='contained' disableElevation onClick={downloadLogs}
                        startIcon={<Download sx={{...CustomStyle.buttonIcon}}/>} 
                        sx={{...CustomStyle.containedButton, height:{xs: '6rem',lg: 1}, width: {xs: 1, lg: fullScreen? 0.25:1}, bgcolor: blue[600], fontSize: {xs: '2.25rem', lg: '1rem'}, p: 1}}>
                        Download
                    </Button>   
                </Box>   
            </Box>
            <DashboardTable datas={eventLogs} isDesktop={isDesktopScreen} isFullScreen={fullScreen}/> 
            <Box sx={{ ...CustomStyle.row, justifyContent: 'center', mt: 3 }}>
                <CustomPagination totalPages={totalPages} page={page} handlePageChange={handlePageChange}/>
            </Box>
        </Paper>
        
        <Box sx={{...CustomStyle.bodyContainer40px, p: 0, height: 1, mb: {xs: 1, lg: 0}, display: fullScreen? 'none': 'block', pt: 0}}>
            {/* {
            isDesktopScreen ? null : 
            <Box
                sx={{
                    ...CustomStyle.column,
                    alignItems: 'center',
                    backgroundColor: red[500],
                    color: 'white',
                    borderRadius: 2,
                    p: 5,
                    mb: 3, 
                    display: alertID===null && 'none'
                }}
                >
                <Box sx={{...CustomStyle.row, justifyContent: 'space-between', width: 1, bgcolor:'' }}>
                    <Box sx={{ ...CustomStyle.row, width: 0.9, bgcolor: '' }}>
                        <WarningAmberRounded sx={{ m: 2, mr: 5, transform: 'scale(3.5)' }} />
                        <Typography sx={{ fontWeight: 'bold', fontSize: 42 }}>
                            ALERT NOTIFICATION!
                        </Typography>
                    </Box>
                    <IconButton sx={{ color: 'white' }}>
                        <Close sx={{...CustomStyle.buttonIcon}} onClick={() => setAlertID(null)}/>
                    </IconButton>
                </Box>
                
                <Box sx={{ mr: 5, mt: 3 }}> 
                    <Typography fontSize={38}>
                        Non-compliance event was detected in cycle {' '}
                        <Typography component="span" sx={{ fontWeight: 'bold', color: yellow[400], fontSize: 38 }}>
                           ID {alertID}
                        </Typography>
                        . Click to{' '}
                        <Typography component="span" sx={{ fontStyle: 'italic', fontSize: 38 }}>
                            learn more
                        </Typography>{' '}
                        and resolve it.
                    </Typography>
                </Box>
                
            </Box>
            } */} 
            <Box sx={{...CustomStyle.columnRow, ...CustomStyle.maxSpaceBetween, mb: {xs: 5,lg: 2}, height: {xs: '55rem', lg: '18rem'}}}>
                <TimerCard time={time} cycleId={cycleID} startTime={cookerStartTime && `${getDateAndTime(cookerStartTime).date} ${getDateAndTime(cookerStartTime).time}`} endTime={cookerEndTime &&`${getDateAndTime(cookerEndTime).date} ${getDateAndTime(cookerEndTime).time}`}/>
                <Paper elevation={0} sx={{width: {xs: 1, lg: 0.5}, borderRadius: {xs: 3, lg: 1}, height: 1, p: 2, pb: 0}}>
                    <Typography sx={{...CustomStyle.subtitleSmall}}>Current Cooker State</Typography>
                    <Box sx={{...CustomStyle.column, pt: 3, width: 1, height: 0.9}}>
                        <Box sx={{...CustomStyle.rowCentered, height: 0.3}}>
                            <IOSSwitch sx={{...CustomStyle.iosSwitchBig, width: 70}} checked={cookerState}/>
                        </Box>
                        
                        <Box sx={{...CustomStyle.rowColumn, ...CustomStyle.maxSpaceBetween, pt: 6.25, pl: {xs: 3, lg: 0}, pr: {xs: 3, lg: 0}, mb: 0, bgcolor: ''}}>
                            <Typography 
                            sx={{...CustomStyle.cardText, mb: 0.2}}>
                                Captured at
                            </Typography>
                            <DualText colorText1={grey[500]} colorText2={grey[500]}
                                text1={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: 2}}>{cookerStartTime && getDateAndTime(cookerStartTime).date}<CalendarMonthOutlined sx={{...CustomStyle.tableIcon, mr: '0.2rem', mt: '-0.1rem'}}/></Typography>} 
                                text2={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: {xs: 2, lg: 0}}}>{cookerStartTime && getDateAndTime(cookerStartTime).time}<Schedule sx={{...CustomStyle.tableIcon, mr: '0.2rem'}}/></Typography>}
                            />
                        </Box>
                    </Box>
                </Paper>
            </Box>
            <Box sx={{...CustomStyle.columnRow, ...CustomStyle.maxSpaceBetween, height: {xs: '62.5rem' , lg:'18rem'}}}>
                <MeterCard title={'Current State Temperature'}
                    meterValue={temperature} meterMax={130} meterMin={0} alert={alertCurrentTemp}
                    extras={
                        <Box sx={{...CustomStyle.rowColumn, ...CustomStyle.maxSpaceBetween, mb: 0, mt:{xs: 6, lg: 0}, pl: {xs: 3, lg: 0}, pr: {xs: 3, lg: 0}}}>
                            <Typography 
                            sx={{...CustomStyle.cardText, mb: 0.2}}>
                                Captured at
                            </Typography>
                            <DualText colorText1={grey[500]} colorText2={grey[500]}
                                text1={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: 2}}>{temperatureCapturedTime && getDateAndTime(temperatureCapturedTime).date}<CalendarMonthOutlined sx={{...CustomStyle.tableIcon, mr: '0.2rem', mt: '-0.1rem'}}/></Typography>} 
                                text2={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: {xs: 2, lg: 0}}}>{temperatureCapturedTime && getDateAndTime(temperatureCapturedTime).time}<Schedule sx={{...CustomStyle.tableIcon, mr: '0.2rem'}}/></Typography>}
                            />
                        </Box>
                        }
                />
                <MeterCard title={'Maximum Temperature'}
                    meterValue={maxTemperature} meterMax={130} meterMin={0} alert={alertMaxTemp || abnormalHeartbeat}
                    extras={
                        <Box sx={{...CustomStyle.rowColumn, ...CustomStyle.maxSpaceBetween, mb: 0, mt:{xs: 6, lg: 0}, pl: {xs: 3, lg: 0}, pr: {xs: 3, lg: 0}}}>
                            <Typography 
                            sx={{...CustomStyle.cardText, mb: 0.2}}>
                                Captured at
                            </Typography>
                            <DualText colorText1={grey[500]} colorText2={grey[500]}
                                text1={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: 2}}>{maxTemperatureCapturedTime && getDateAndTime(maxTemperatureCapturedTime).date}<CalendarMonthOutlined sx={{...CustomStyle.tableIcon, mr: '0.2rem', mt: '-0.1rem'}}/></Typography>} 
                                text2={<Typography sx={{...CustomStyle.cardText, ...CustomStyle.rowReverse, mr: {xs: 2, lg: 0}}}>{maxTemperatureCapturedTime && getDateAndTime(maxTemperatureCapturedTime).time}<Schedule sx={{...CustomStyle.tableIcon, mr: '0.2rem'}}/></Typography>}
                            />
                            
                        </Box>
                        }
                />
            </Box>
        </Box>
                
        </Box>
        {/* MODAL DIALOG */}
        <SuccessModal open={openSuccess} onClose={()=>setOpenSuccess(false)} sx={{...CustomStyle.modalSmall}}>
        </SuccessModal>

        <ErrorModal open={openError} onClose={()=>setOpenError(false)} sx={{...CustomStyle.modalSmall}}>
        </ErrorModal>
    </Box>
  )
}

export default AnimalFatCookerDashboard