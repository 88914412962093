import React, { useState } from 'react';
import AuthenticateContainer from './AuthenticateContainer';
import './Login.css';
import loginAxiosInstance, { setJwtCookie } from '../auth/auth';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../auth/auth';
const Login = () => {
    const navigate = useNavigate(); 
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value
        });
        console.log(formData);
    };

    const handleSignIn = async (event) => {
        event.preventDefault();
        try {
            const response = await loginAxiosInstance.post(global.config.server_url + 'account/api/get/token/', formData);
            const token = response.data;
            setJwtCookie(token);
            axiosInstance.defaults.headers['Authorization'] = `Bearer ${token.access}`
            console.log('Login successful');
            navigate('/');
        } catch (error) {
            // Handle error response (e.g., display error message, redirect, etc.)
            setErrorMessage(error.response.data.detail);
            console.error('Error occurred while logging in:', error.response.data.detail);
        }
    };

    return (
        <AuthenticateContainer>
            <span className="text-center form-title">Log In Account</span>
            <form className="auth-form login-form" onSubmit={handleSignIn}>
                <div className="form-group mt-4 ">
                    <label className="w-100" htmlFor="email">Username or Email Address</label>
                    <input type="text" id="email" className="form-control" placeholder="myname@email.com" onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="password">Password</label>
                        <a href="/reset/password" className="forgot-password-link">Forgot Password?</a>
                    </div>
                    <div className="password-group">
                        <input
                            type={passwordVisible ? "text" : "password"}
                            id="password"
                            className="form-control"
                            placeholder="Enter password"
                            onChange={handleInputChange}
                            required
                        />
                        <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                            {passwordVisible ? (
                                <span className="material-symbols-rounded">
                                    visibility_off
                                </span>
                            ) : (
                                    <span className="material-symbols-rounded">
                                    visibility
                                </span>
                            )}
                        </span>
                    </div>
                </div>
                <div className="form-group d-flex justify-content-start pt-0 remember-password">
                    <input type="checkbox" id="rememberMe" className="form-check-input" />
                    <label htmlFor="rememberMe" className="form-check-label">Remember Password</label>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">Sign In</button>
                </div>
                {errorMessage && <div className="error-message text-danger">{errorMessage}</div>}
            </form>
        </AuthenticateContainer>
    );
};

export default Login;

