import { Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, Box, Card } from '@mui/material'
import React, { useState } from 'react'
import * as CustomStyle from '../../components/CustomStyle'
import IOSSwitch from '../../components/IOSSwitch'
import WarningModal from '../../components/WarningModal'
import axiosInstance from '../../components/auth/auth'
import ErrorModal from '../../components/ErrorModal'

const NotificationSettings = ({alertsSettings, setAlertsSettings}) => {
    const [openWarning, setOpenWarning] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [selectedSetting, setSelectedSetting] = useState({});

    const editSettings = async () => {
        const apiUrl = `alert/api/level/update/${selectedSetting.id}/`;
        const data = {'is_repeat': !selectedSetting.is_repeat};
        try{
            const response = await axiosInstance.patch(global.config.server_url + apiUrl, data);
            selectedSetting.is_repeat = !selectedSetting.is_repeat
            setAlertsSettings([...alertsSettings]);
            setOpenWarning(false);
        }catch(error){
            setOpenWarning(false);
            setOpenError(true);
            console.log('Error editing data', error);
        }finally{
            setSelectedSetting(null)
        }
        
    }

  return (
    <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell  sx={{...CustomStyle.tableHeader,}}>Level of Attention</TableCell> 
                    <TableCell  sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>Color Code</TableCell> 
                    <TableCell  sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>Repetition Until Resolved</TableCell> 
                </TableRow>
            </TableHead>
            <TableBody>
                {alertsSettings.map((setting) => (
                    <TableRow>
                        <TableCell component="th" scope="row" sx={{...CustomStyle.tableText}}>
                            {setting.name}
                        </TableCell>
                        <TableCell sx={{}}>
                        <Box sx={{...CustomStyle.rowCentered}}>
                            <Card sx={{bgcolor: setting.name !== "Low"? setting.color : 'white', 
                                width: {xs: '4rem', lg: '2rem'}, height: {xs: '2rem', lg: '1rem'}}}
                            />
                        </Box>
                        </TableCell>
                        <TableCell>
                        <Box sx={{...CustomStyle.rowCentered}}>
                            <IOSSwitch checked={setting.is_repeat} onChange={()=>{setOpenWarning(true); setSelectedSetting(setting)}} sx={{...CustomStyle.iosSwitchTable}}/>
                        </Box>
                            <WarningModal open={openWarning} onClose={()=>setOpenWarning(false)}
                                onClick={() => {editSettings(setting)}} sx={{ ...CustomStyle.modalMedium}}
                                text={"Are you sure you want to change the settings for this alert?"} 
                            />
                        </TableCell>
                        
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        <ErrorModal open={openError} onClose={()=>{setOpenError(false)}} sx={{...CustomStyle.modalSmall}}>
        </ErrorModal>
    </TableContainer>
  )
}

export default NotificationSettings