import React from 'react'
import * as CustomStyle from '../components/CustomStyle'
import { Box, MenuItem, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

const LabeledDropdown = ({label, value, bgcolor=grey[100], fontSize={xs: '2.1rem', lg: '0.9rem'}, dropdownItems, onChange, placeholder, sx}) => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', ...sx}} pb={{xs:2, lg:0}}>
        <Typography variant='h6' sx={{fontWeight: 'bold', pb: 1, fontSize: fontSize, fontFamily: 'Segoe UI'}}>{label}</Typography>
        <TextField select label={placeholder} defaultValue={value} 
            sx={{bgcolor: bgcolor, 
            '.MuiInputBase-input ':{fontSize: fontSize, fontFamily: 'Segoe UI', height: {xs: null, lg: '0.1rem'}, alignContent: 'center'},
            '.MuiInputLabel-root ':{fontSize: fontSize, fontFamily: 'Segoe UI', '&.MuiInputLabel-shrink': { transform: {xs: 'translate(14px, -6px) scale(0.5)', lg: 'translate(14px, -9px) scale(0.75)'},}}
            }}
            onChange={onChange}>
            {dropdownItems.map((option) => (
            <MenuItem key={option.value || option.id} value={option.value || option.id} sx={{fontFamily: 'Segoe UI', fontSize: fontSize}}>
                {option.label || option.name}
            </MenuItem>
            ))}
        </TextField>
    </Box>
  )
}

export default LabeledDropdown