import React, { useEffect } from 'react'
import { Box, Modal, Typography, Button, Card } from '@mui/material'
import { green, grey } from '@mui/material/colors'
import * as CustomStyle from '../../components/CustomStyle'
import { format } from 'date-fns';

const NotificationHistoryModal = ({ notification,
  open, onClose, BackdropProps={style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }}, sx
}) => {

  const historyModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs:  '50rem', lg: '28.75rem'},
    height: {xs: '45rem', lg: '25rem'},
    bgcolor: '#f5f5f5',
    border: '0px solid #000',
    p: 3,
    borderRadius: '5px',
    textAlign: 'start',
  };

  const getDateAndTime = (datetime) => {
    const date = format(datetime, 'dd MMMM yyyy'); // e.g., "02 July 2024"
    const time = format(datetime, 'hh:mm a');
    return `${time} @ ${date} `;
  };
    
  return (
    <Modal open={open} onClose={onClose} BackdropProps={{ ...BackdropProps }}>
      <Box sx={{ ...sx, ...historyModal }}>
        <Typography sx={{...CustomStyle.subtitle, mb: 1, bgcolor:''}}>
          {notification.alert.alert_type.name}@{notification.location} History
        </Typography>
        <Box sx={{bgcolor: '', height: {xs: '27.5rem', lg: '15rem'}, overflowY: 'auto', '::-webkit-scrollbar': {display: 'none'}}}>
          {notification.sent_alert_log_records.map((record) => (
            <Card sx={{bgcolor:'', p: 1, mb: 1}}>
            <Box sx={{...CustomStyle.row, alignItems: 'baseline', justifyContent: 'space-between'}}>
              <Typography sx={{...CustomStyle.label, pb: 1, bgcolor:'', color: grey['A700'] }}>
                Alert via {record.method}
              </Typography>  
              <Typography sx={{...CustomStyle.label, color: grey['A700'], fontSize: {xs: '1.6rem', lg: '0.6rem'}, fontStyle:'italic', textAlign: '', bgcolor:''}}>
                {getDateAndTime(record.send_datetime)}  
              </Typography>
            </Box>       
            <Box sx={{...CustomStyle.row}}>
              <Typography sx={{...CustomStyle.cardText, pb: 1, pr: 1, bgcolor:'', }}>
                Sent To:
              </Typography> 
              <Typography sx={{...CustomStyle.cardText, fontWeight: '', textTransform: 'capitalize', pb: 1, bgcolor:'', }}>
                {record.record_recipients ?? '-'}
              </Typography> 
            </Box>     
          </Card>
          ))}
        </Box>
        
        {/* align button to bottom right corner */}
        <Button variant='outlined' onClick={onClose} 
          sx={{ ...CustomStyle.outlinedButton, width: null, position: 'absolute', right: "5%", mt: {xs: 2, lg: 1},
            color: 'black', borderColor: 'black', textTransform: 'none', border: {xs: '2px solid', lg: '1px solid'},
            '&:hover': { borderColor: 'black', color: 'black', border: {xs: '2px solid', lg: '1px solid'} } }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  )
}

export default NotificationHistoryModal