import React, { useState } from 'react'
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Box, Typography } from '@mui/material'
import { grey, red, green } from '@mui/material/colors'
import * as CustomStyle from '../../components/CustomStyle'
import { CalendarMonthOutlined, DeviceThermostat, KeyboardArrowDown, KeyboardArrowUp, Schedule } from '@mui/icons-material'

const DashboardTable = ({datas, isDesktop, isFullScreen}) => {
  const [hidden, setHidden] = useState(true);
  const [hiddenID, setHiddenID] = useState([]);
  const handleHidden = (id) => {
    setHidden(!hidden);
    if(hiddenID.includes(id)){
      setHiddenID(hiddenID.filter(item => item !== id));
    }else{
      hiddenID.push(id);
      setHiddenID(hiddenID);
    }
  };

  const formatDate = (datetime) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const day = date.getDate();
    return `${day}-${month < 10 ? `0${month}` : `${month}`}-${year}`
  }

  const formatTime = (datetime) => {
    const date = new Date(datetime);
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return `${hour < 10 ? `0${hour}` : `${hour}`}:${minute < 10 ? `0${minute}` : `${minute}`}:${second < 10 ? `0${second}` : `${second}`}`
  }

  const formatDuration = (duration) => {
    // example duration = 1.5 Hours / 0.5 hours
    duration = duration.split(':');
    const hours = duration[0];
    const minutes = duration[1];
    if(hours >= 1){
      return `${hours} Hour${hours > 1 ? 's' : ''}`
    } else{
      return `${minutes} Minutes`
    }
  }

  return (
    <TableContainer component={Box} sx={{border: 1, borderColor: grey[300], borderRadius: 1, mt: 2}}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {
            isDesktop? 
            <>
            <TableCell sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>No.</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>Cycle ID</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>Cooker ON</TableCell>
            {isFullScreen && <TableCell sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>Cooker OFF</TableCell>}
            <TableCell sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>Max Temp</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader, textAlign: 'center'}}>Duration</TableCell>
            </>
            :
            <>
            <TableCell sx={{...CustomStyle.tableHeader}}>No.</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>Cycle ID</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}>Duration</TableCell>
            <TableCell sx={{...CustomStyle.tableHeader}}></TableCell>
            </>
            }
            
          </TableRow>
        </TableHead>
        <TableBody>
          {datas.length > 0 && datas.map((data, i) => (
            <>
            <TableRow onClick={()=>handleHidden(data.cycle_id)}> {/**/}
              {isDesktop?
              <>
              <TableCell sx={{...CustomStyle.tableCenteredText, fontWeight: 'none', color: grey[600], padding: 1}}>{i+1}.</TableCell>
              <TableCell sx={{...CustomStyle.tableCenteredText, padding: 1}}> {data.cycle_id} </TableCell>
              <TableCell sx={{...CustomStyle.tableText, color: grey[600], padding: 1}}>
                <Box sx={{...CustomStyle.rowCentered}}>
                  <Box sx={{...CustomStyle.rowCentered}}>
                    <CalendarMonthOutlined sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableText}}>{formatDate(data.cooker_on.datetime)}</Typography>
                  </Box>
                  <Box sx={{...CustomStyle.rowCentered, ml:2}}>
                    <Schedule sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableText}}>{formatTime(data.cooker_on.datetime)}</Typography>
                  </Box>
                  <Box sx={{...CustomStyle.rowCentered, ml:2}}>
                    <DeviceThermostat sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableText}}>{data.cooker_on.temp.toFixed(1) + "°C"}</Typography>
                  </Box>
                </Box>
              </TableCell>
              {isFullScreen && 
              <TableCell sx={{...CustomStyle.tableText, color: grey[600], padding: 1}}>
                <Box sx={{...CustomStyle.rowCentered}}>
                  <Box sx={{...CustomStyle.rowCentered}}>
                    <CalendarMonthOutlined sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableText}}>{formatDate(data.cooker_off.datetime)}</Typography>
                  </Box>
                  <Box sx={{...CustomStyle.rowCentered, ml:2}}>
                    <Schedule sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableText}}>{formatTime(data.cooker_off.datetime)}</Typography>
                  </Box>
                  <Box sx={{...CustomStyle.rowCentered, ml:2}}>
                    <DeviceThermostat sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableText}}>{data.cooker_off.temp.toFixed(1) + "°C"}</Typography>
                  </Box>
                </Box>
              </TableCell>
              }
              <TableCell sx={{padding: 1}}>
                  <Box sx={{...CustomStyle.tableCenteredTextBox, ml: isFullScreen? 2 : 0, mr: isFullScreen? 2 : 0}}
                    color={!data.undercooked? green[300] : red[300]}  
                    bgcolor={!data.undercooked? green[50] : red[50]} > 
                      {data.max_temp.toFixed(1) + "°C" }
                  </Box> 
              </TableCell>
              {/* <TableCell sx={{...CustomStyle.tableText}}>{data.cooker_off}</TableCell> */}
              <TableCell sx={{...CustomStyle.tableCenteredText, padding: 1}}>{data.duration}</TableCell>
              </>
              :
              <>
              <TableCell sx={{...CustomStyle.tableText}}>{i+1}.</TableCell>
              <TableCell sx={{...CustomStyle.tableText, color: grey['600']}}> {data.cycle_id} </TableCell>
              <TableCell sx={{...CustomStyle.tableText, color: grey['600']}}>{data.duration}</TableCell>
              <TableCell sx={{...CustomStyle.tableCenteredText}}>
                {!hiddenID.includes(data.cycle_id)? <KeyboardArrowDown transform='scale(2.5)' sx={{color: grey['600']}}/> : <KeyboardArrowUp transform='scale(2.5)' sx={{color: grey['600']}}/>}
              </TableCell>
              </>
              }
              
              
            </TableRow>
            {isDesktop?
            <></>
            :
            <TableRow hidden={!hiddenID.includes(data.cycle_id)? true:false}> {/** */}
              <TableCell colSpan={isFullScreen? 2:4}>
                <Box sx={{...CustomStyle.column}}>
                  <Typography sx={{...CustomStyle.tableText, color: grey['600'], mb: 1}}>Cooker ON</Typography>
                  <Box sx={{...CustomStyle.row}}>
                    <CalendarMonthOutlined sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableText}}>{formatDate(data.cooker_on.datetime)}</Typography>
                  </Box>
                  <Box sx={{...CustomStyle.row}}>
                    <Schedule sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableText}}>{formatTime(data.cooker_on.datetime)}</Typography>
                  </Box>
                  <Box sx={{...CustomStyle.row}}>
                    <DeviceThermostat sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableText}}>{data.cooker_on.temp.toFixed(1) + "°C"}</Typography>
                  </Box>
                  
                  <Typography sx={{...CustomStyle.tableText, color: grey['600'], mt: 3}}>Max TMP</Typography>
                  <Box sx={{...CustomStyle.tableTextBox, width: isFullScreen? 0.6 : 0.3, borderRadius: 2, mt: 2}}
                    color={!data.undercooked? green[300] : red[300]}  
                    bgcolor={!data.undercooked? green[50] : red[50]} > 
                      {data.max_temp.toFixed(1) + "°C" }
                  </Box> 
                </Box>
              </TableCell>
              {isFullScreen && 
              <TableCell colSpan={2}>
                <Box sx={{...CustomStyle.column, mb: 18}}>
                  <Typography sx={{...CustomStyle.tableText, color: grey['600'], mb: 1}}>Cooker OFF</Typography>
                  <Box sx={{...CustomStyle.row}}>
                    <CalendarMonthOutlined sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableText}}>{formatDate(data.cooker_off.datetime)}</Typography>
                  </Box>
                  <Box sx={{...CustomStyle.row}}>
                    <Schedule sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableText}}>{formatTime(data.cooker_off.datetime)}</Typography>
                  </Box>
                  <Box sx={{...CustomStyle.row}}>
                    <DeviceThermostat sx={{...CustomStyle.tableIcon}}/>
                    <Typography sx={{...CustomStyle.tableText}}>{data.cooker_off.temp.toFixed(1) + "°C"}</Typography>
                  </Box>
                </Box>
              </TableCell>
              }
            </TableRow>
            }
            </>
          ))}
          <TableRow>
            
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DashboardTable