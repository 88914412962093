import React, { useEffect, useRef, useState } from 'react';
import './Topbar.css';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance, { checkAndRefreshToken } from '../components/auth/auth'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Circle, NotificationsOutlined } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import NotificationTable from '../pages/AlertNotification/NotificationTable';
import * as CustomStyle from '../components/CustomStyle'
import ScreenLoader from './ScreenLoader';

const Topbar = ({ isMenuOpen, setIsMenuOpen}) => {
    const [profile, setProfile] = useState({'first_name':'first name', 'last_name':'last name' , 'is_admin':false});
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const wsRef = useRef(null);
    const retryInterval = useRef(null);
    const [webSocketConnected, setWebSocketConnected] = useState(true);

    const navigate = useNavigate();
    
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axiosInstance.get(global.config.server_url + `account/api/profile/`)
                const data = await response.data
                setProfile(data)
                console.log(profile.name)
            } catch (error) {
                console.log('Topbar Error fetching profile data', error)
            } 
        }

        const fetchNotifications = async () => {
            const apiUrl = "alert/api/log/list/"; // Use current state values here
            try{
                const response =  await axiosInstance.get(global.config.server_url + apiUrl);
                const data = response.data;
                setNotifications(data.results);
                setNotificationsCount(data.results.filter(notification => notification.is_resolve === false).length);
            }catch(error){
                console.log('Topbar Error fetching notification data', error);
            }
        }

        fetchNotifications()
        fetchProfile()
    }, [notificationsCount])


    useEffect(() => {
        const connectWebsocket = async () => {
            const accessToken = await checkAndRefreshToken();
            const ws = new WebSocket(global.config.websocket_url + `alert/websocket/?token=${accessToken}`);
            wsRef.current = ws; 

            ws.onopen = () => {
                console.log('Topbar Notification Websocket connected');
                setWebSocketConnected(true);
                clearInterval(retryInterval.current);
                retryInterval.current = null;
            };

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log("Topbar Notification Websocket message received:", data);
                if(data.id !== undefined){
                    setNotificationsCount(prevCount => prevCount + 1);    
                }
            };

            ws.onclose = () => {
                console.log('Topbar Notification Websocket closed. Attempting to reconnect...');
                setWebSocketConnected(false);
                if (!retryInterval.current) {
                    retryInterval.current = setInterval(() => {
                        if(!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
                            connectWebsocket();
                        }
                    }, 5000);
                }
            };

            ws.onerror = (event) => {
                console.error('Topbar Notification WebSocket error:', event);
                setWebSocketConnected(false);
                if (!retryInterval.current) {
                    retryInterval.current = setInterval(() => {
                        if(!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
                            connectWebsocket();
                        }
                    }, 5000);
                }
            };
        }

        connectWebsocket();
        
    }, []);

    const openSidebar = () => {
        setIsMenuOpen(!isMenuOpen);
        const sidebar = document.querySelector('.sidebar');
        if (sidebar) {
            sidebar.classList.toggle('expanded');
        }
    };

    const openNotificiationBar = () => {
        setNotificationOpen(!notificationOpen);
    };

    const TopbarNotifications = ({notifications}) => {
        return <Box
            sx={{
                position: 'absolute',
                right: 35,
                top: {xs: '10rem', lg:'4.85rem'},
                width: {xs: 750, lg: 500},
                bgcolor: 'background.paper',
                boxShadow: 5,
                borderRadius: 1,
                zIndex: 1000,
                maxHeight: 400,
                overflowY: 'auto',
                // '::-webkit-scrollbar': {display: 'none'}
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, position: 'sticky', top: 0 , bgcolor: 'background.paper', zIndex: 1}}>
                <Typography variant="h6" sx={{ ...CustomStyle.subtitle }}>Notifications</Typography>
                <Button variant="text" onClick={() => { navigate('/notification'); } } sx={{ textTransform: 'none', fontWeight: 'bold', color: grey[500], fontSize: {xs: '2rem', lg: '0.9rem'} }}>
                    View All
                </Button>
            </Box>
            {notifications.map((notification) => (
                <NotificationTable notification={notification} isTopbar={true} unresolvedCount={notificationsCount} setUnresolvedCount={setNotificationsCount} isAdmin={profile.is_admin}/>
            ))}
        </Box>;
    }

    return (
        <div className="topbar">
            <button className="menu-btn" onClick={openSidebar}>
                <span className="material-symbols-outlined icon-menu">
                    {isMenuOpen ? 'close' : 'menu'}
                </span>
            </button>
            <span className="topbar-title">IoT Monitoring System</span>
            <div className="user-info-container">
                <IconButton onClick={openNotificiationBar} sx={{ mr: {xs: 4, lg: 2}, border: notificationOpen? 1:0}}>
                    <NotificationsOutlined sx={{color: grey[500], fontSize: {xs: '4rem', lg: '1.5rem'}}}/>
                    <Circle sx={{display: notificationsCount > 0 ? null : 'none', width: '0.6rem', height: '0.6rem', color: 'red', position: 'absolute', top: '37.5%', right: '9px', transform: 'translateY(-50%)'}}/>   
                </IconButton> 
                {notificationOpen && <TopbarNotifications notifications={notifications} />}

                <Link to="/profile">
                    <img src={profile.profile_picture ? profile.profile_picture : "images/default_user.jpg"} alt="Profile" className="profile-pic" />
                </Link>
                <div className="user-details">
                    <span className="user-name">{profile.first_name} {profile.last_name}</span>
                    <span className="user-role">{profile.is_admin ? 'Admin' : 'Staff'}</span>
                </div>
            </div>
          
        </div>
    );
};

export default Topbar;
